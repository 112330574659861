@import "project/_djv-project";
@import "project/_djv-vars";
//@import "project/_djv-fonts";
@import "project/djv-icon-font/css/comunes";
@import "project/_djv-extends";
@import "project/_djv-mixins";
@import "project/_djv-common";

// HEADER global y menu

@import "project/_header-grid";
@import "project/_header-sticky";
@import "project/_header-mobile";

@import "project/_main-menu";
@import "project/_main-menu-sticky";
@import "project/_main-menu-mobile";
//@import "project/_djv-temporal";

@import "project/_djv-carouselh";

@import "project/_home";
@import "project/_djv-carousel-marcas";
@import "project/_djv-cart-preview";
//@import "project/_djv-order";
@import "project/_cart";
@import "project/_vouchers";
@import "project/_djv-breadcrumbs";


@import "project/_product-sheet";
@import "project/_product-availabylity";
@import "project/_product-prices";
@import "project/_product-miniature";
@import "project/_product-accesories";
@import "project/_product-description-extended";
@import "project/_product-attachments";
@import "project/_products-list";
@import "project/_pagination";
@import "project/_sort-order";


@import "project/_product-quantity";
@import "project/_product-variants";
@import "project/_product-img";
@import "project/_product-breadcrumb";
@import "project/_product-suport";
@import "project/_product-share";
@import "project/_zoom-pan";
@import "project/_expandtext";

@import "project/_djv-product-list-home";
@import "project/_listas-productos";
@import "project/_djv-checkout";
@import "project/_djv-authentication";
@import "project/_djv-carousel";
@import "project/_djv-cms";
@import "project/_footer";
@import "project/_djv-navigation-left";
@import "project/_djv-navigation-left-filters";
@import "project/_djv-my-account";
@import "project/_djv-my-account-addresses";

@import "project/_djv-order-confirmation";
@import "project/_djv-identity";
@import "project/_djv-history";
//@import "project/_djv-manufacturers";
@import "project/_form-files";
@import "project/_djv-contact-form";
@import "project/_djv_pagenotfound";
@import "project/_djv-search";
@import "project/_djv-forms";
@import "project/_djv-troncales";
@import "project/_djv-ph-simpleblog";

//@import "project/shame";
@import "project/_layout";
@import "project/_user-info";
@import "project/_notifications";
@import "project/_modal";
@import "project/_loader";

.watchmework { font-size: 22.8}
