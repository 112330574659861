.products-sort-order{
    display: inline-block;
    position: relative;
    @media(max-width: $screen-md){
        display: block;
        padding: 0 0;
        margin-bottom: 5px;
    }
    label{
        font-size: $font-md;
    }
    ul{
        padding: 0;
        display: inline-block;
        margin-bottom: 0;
        li{
            list-style: none;
            display: inline-block;
            margin: 5px;
            font-size: $font-md;
            color: $primario3;
            @media(max-width: $screen-md){
                margin: 0;
            }

            &.sort-selected{
                font-size: $font-sm;
                background-color: $primario2;
                color:white;
                padding: 5px 10px;
                border-radius: 2px;
                display: block;
            }
            a,.separator{
                padding: 5px 0px 5px 3px;
                border-radius: 2px;
                display: inline-block;
                color: black;
            }
        }
    }
    select{
        font-size: $font-sm;
        cursor: pointer;
        border: 0;
        color:$primario3;
        option{
            font-size: $font-sm;
            @include fuente($font-first, $font-regular);
            color: black;
            &.sort-selected{
                font-size: $font-sm;
                padding: 5px 10px;
                border-radius: 2px;
                display: block;
            }
        }
    }
}
