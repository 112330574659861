#index{
    #djv-two-columns-layer{
        .djv-listas-productos{
            margin-bottom: 45px;
            h3{
                @media(max-width: $screen-md){
                    margin-top: 10px;
                }
            }
            .djv_carouselh{
                .products{
                    .product-miniature{
                        width: calc(25% - 15px);
                        min-height: 380px;
                        max-height: 390px;
                        padding: 0 15px;
                        grid-template-rows: 250px 130px;
                        @media(max-width: $screen-md){
                            scroll-snap-align: start;
                            width: 100%;
                            position: relative;
                            display: inline-block;
                            margin-right: -4px;
                            text-align: center;
                        }
                        .product-miniature-img-block{
                            max-width: 90vw;
                        }
                    }
                }
            }
            @media(max-width: $screen-md){
                padding: 5px;
            }
        }
    }
}
