#history{
    .page-content{
        @include djv-container();
        h1{
            font-size: $font-bmd;
        }
        h2{
            font-size: $font-smd;
            font-weight: 400;
            @include djv-border-bottom(dashed);
            text-align: left;
            margin-bottom: 20px;
        }
        .history-list{
            .history-list-header{
                display: grid;
                grid-template-columns: 15% 8% 13% 22% repeat(2, 14%) 14%;
                border-bottom: solid 1px black;
                padding-bottom: 10px;
                margin-bottom: 0px;
                @media(max-width: $screen-sm){
                    display: none;
                }
                >div{
                    @include fuente($font-first, $font-medium);
                    font-size: $font-sm;
                }
            }
            .history-list-body{
                .history-list-body-item{
                    display: grid;
                    grid-template-columns: 15% 8% 13% 22% repeat(2, 14%) 14%;
                    padding: 20px 0;
                    border-bottom: 1px solid $gris2;
                    @media(max-width: $screen-sm){
                        display:block;
                    }
                    .history-list-body-item-col{
                        align-content: center;
                        font-size: $font-xs - 1px;
                        @media(max-width: $screen-lg){
                            margin-bottom: 10px;
                            text-align: left;
                        }
                        .etiqueta{
                            display:none;
                            @media(max-width: $screen-lg){
                                display: inline-block;
                                font-weight: 600;
                                margin-right: 10px;
                            }
                        }
                        [data-link-action="view-order-details"]{
                            @include btn-hover-animated($primario2, white, 5px, 5px, 5px);
                            display: inline-block;
                            margin-bottom: 8px;
                            font-size: $font-xs - 2px;
                        }
                        .order-reorder-link{
                            @include btn-hover-animated($complementario1, white, 5px, 5px, 5px);
                            display: inline-block;
                            font-size: $font-xs - 2px;
                        }
                        .order-status-label {
                            font-size: $font-xs - 2px;
                            white-space: nowrap;
                            color: white;
                            padding: 5px;
                            display: inline-block;
                            width: 10px;
                            height: 10px;
                            border-radius: 50%;
                            text-align: center;
                        }
                    }
                }
            }
        }
        .page-footer{
            margin-top: 40px;
            padding-top: 20px;
            border-top: 1px dashed #999;
        }
    }
}
