.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10000;
    background-color: rgba(0,0,0,.5);
    display: block !important;
    overflow: hidden;
    outline: 0;
    @media(max-width: $screen-md){
        overflow-y: scroll;
    }
    .modal-open & {
        overflow-x: hidden;
        overflow-y: auto;
    }
    .modal-dialog{
        position: relative;
        background-color: white;
        margin: 50px auto;
        min-height: 50vh;
        box-shadow: 1px 1px 5px rgba(0,0,0,.5);
        width: 100%;
        max-width: 700px;
        @media(max-width: $screen-md){
            max-width: 100vw !important;
            margin-top: 0;
        }
        .modal-content{
            border-radius: 0;
            @media(max-width: $screen-md){
                display: block;
            }
            .modal-header{
                background-color: $primario5;
                padding: 16px;
                position: relative;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                .close{
                    position: absolute;
                    right: 16px;
                    top: 16px;
                    float: none;
                    font-size: 48px;
                    font-weight: 700;
                    line-height: 0;
                    color: #000;
                    text-shadow: 0 1px 0 #fff;
                    opacity: .5;
                    background-color: white;
                    height: 100%;
                }
                .modal-title{
                    color:white;
                    font-size: $font-md;
                    max-width: 80%;
                    @media(max-width: $screen-md){
                        font-size: 12px;
                    }
                    i{
                        margin-right: 16px;
                        @media(max-width: $screen-md){
                            display: none;
                        }
                    }
                }
            }
            .modal-body{
                padding: 5px;
                display: grid;
                grid-template-columns: repeat(2,calc(50% - 10px));
                grid-gap: 10px;
                @media(max-width: $screen-md){
                    display: block;
                    padding: 10px;
                }
                .modal-body-product-img{
                    padding: 10px 10px 0px;
                    @media(max-width: $screen-md){
                        border-right: none;
                    }
                    img{
                        display: block;
                        margin: auto;
                        width: 70%;
                        @media(max-width: $screen-md){
                            max-width: 100%;
                            margin-bottom: 20px;
                        }
                    }
                }
                .modal-body-product-data{
                    padding: 10px 10px 0px;
                    @media(max-width: $screen-md){
                        padding: 10px;
                        p{
                            margin-bottom: 5px;
                        }
                    }
                    .product-name{
                        font-size: $font-lg;
                        @include fuente($font-first, $font-semibold);
                    }
                    .modal-product-price{
                        font-size: $font-lg;
                    }
                }
                .modal-body-cart-content{
                    padding: 40px;
                    @media(max-width: $screen-md){
                        padding: 10px;
                    }
                    p{
                        margin-bottom: 5px;
                    }
                }
                .cart-content-btn{
                    float: right;
                    padding: 50px 30px 40px;
                    @media(max-width: $screen-md){
                        padding: 10px;
                    }
                    i{
                        margin-right: 10px;
                    }
                    .btn{
                        margin-top: 8px;
                        margin-bottom: 8px;
                        width: 100%;
                        text-align: center;
                        @media(max-width: $screen-md){
                            margin-top: 8px;
                            margin-bottom: 8px;

                        }
                    }
                }
            }
            .modal-footer{
                border-top: 0;
            }
        }
    }
    &#moreInformationFormModal{
        .modal-dialog{
            width: 480px;
            .modal-content{
                display: block;
                border: 0;
                .modal-header{

                    .close{

                    }
                    .modal-title{
                        font-size: $font-bmd;
                    }
                }
                .modal-body{
                    display: block;
                    padding: 16px;
                    .product{
                        display: flex;
                        padding: 0;
                        margin: 10px 0 20px;
                        h3{
                            font-size: $font-lg;
                        }
                    }
                }
                .modal-footer{

                }
            }
        }
    }
}
