#addresses{
    #main{
        @include djv-container();
        .page-addresses{
            display: grid;
            grid-template-columns: calc(33% - 16px) calc(33% - 16px) calc(33% - 16px);
            grid-gap: 16px;
            @media(max-width: $screen-lg){
                display:block;
            }
            article{
                border: 1px dashed #dadada;
                padding: 16px;
                position: relative;
                @media(max-width: $screen-lg){
                    padding: 8px;
                }
                h3{
                    font-weight: 600;
                    font-size: $font-smd;
                }
                .text-block{
                    display: block;
                    margin: 5px auto;
                    padding: 10px 0;
                    border-top: 1px solid $gris3;
                    border-bottom: 1px solid $gris3;
                    p{
                        margin: 5px;
                        line-height: normal;
                    }
                    .name{
                        font-weight: 600;
                    }
                }
                .action-list{
                    padding-left: 0;
                    margin-bottom: 0;
                    margin-top: 16px;
                    li{
                        display: inline-block;
                        @media(max-width: $screen-lg){
                            width: 49%;
                        }
                        a{
                        display: block;
                        padding: 3px;
                        text-align: center;
                        color: white;
                        font-size: $font-xxs;
                        width: 100%;
                            &[data-link-action="edit-address"]{
                                @include btn-hover-animated($primario3, white, 0px, 10px, 10px);
                            }
                            &[data-link-action="delete-address"]{
                                @include btn-hover-animated($complementario2, white, 0px, 10px, 10px);
                            }
                        }
                    }
                }
            }
        }
        .page-footer{
            @include djv-border-top(dashed);
            @media(max-width: $screen-lg){
                border: none;
                padding: 0 10px;
            }
        }
    }
}
#address{
    #main{
        @include djv-container();
        .form-fields{/*
            @media(max-width: $screen-lg){
                display: block;
            }
            label{
                display: block;
                @media(max-width: $screen-lg){
                    width: 100%;
                    display:block;
                    margin-bottom: 20px;
                }
                span{
                    display: inline-block;
                    width: 25%;
                    @media(max-width: $screen-lg){
                        display: block;
                        width: 100%;
                    }
                }
                input, select{
                    border: none;
                    background-color: #eee;
                    padding: 5px;
                    display: inline-block;
                    width: 70%;
                    @media(max-width: $screen-lg){
                        display: block;
                        width: 100%;
                    }
                }
                input[name="esempresa"]{
                    width: 25px;
                    @media(max-width: $screen-lg){
                        display: block;
                        width: 100%;
                    }
                }
            }
            &::after{
                content: "Los campos marcados con un asterisco son obligatorios";
            }*/
        }
        .form-footer{
            @include djv-border-top(solid);
            button{
                @include btn-hover-animated($complementario1, white, 50px, 10px, 10px);
                font-size: $font-sm;
            }
        }
        .page-footer{
            @include djv-border-top(solid);
        }
    }
}
