#checkout{
    background-color: #eee;
    @include fuente($font-first, $font-semibold);

    h1{
        font-size: $font-blg;
        border-bottom: 1px solid #aaa;
        padding-bottom: 10px;
        margin: 10px 0;
        color: black;
    }
    .-complete{
        h1{
            color:#999;
            &::after{
                font-size: $font-sm;
                color:$primario3;
                content:"editar";
                float: right;
                cursor: pointer;
                padding-top: 5px;
            }
        }
    }

    h2{
        text-align: left;
        font-size: $font-lg;
        margin-bottom: 10px;
    }

    h4{
        font-size: $font-bmd;
    }

    a{
        color: $primario3;
        text-decoration: underline;
    }

    #header{
        position: relative;
        top:-5px;
        min-height: 36px;
        box-shadow: none;
        .checkout-header-simplified{
            background-color: white;
            div{
                @include djv-container();
                .logo {
                    background-image: url("/themes/base/assets/img/logo.png");
                    width: 120px;
                    height: 60px;
                    display: block;
                    background-repeat: no-repeat;
                    background-size: contain;
                    margin: 5px;
                    background-position: center;
                    @media(max-width: $screen-sm){
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }
        }
    }
    #content{
        @include djv-container();
        display: grid;
        grid-template-columns: calc(65% - 16px) calc(35% - 16px);
        grid-column-gap: 16px;
        @media(max-width: $screen-sm){
            grid-template-columns:auto;
            padding: 0 10px;
        }
        >*{
            background-color: white;
            border:1px solid #dadada;
            padding: 15px;
            @media(max-width: $screen-sm){
                margin-bottom: 10px;
            }
        }
    }
    .checkout-process{
        background-color: white;
        padding: 22px;
        @media(max-width: $screen-sm){
            margin-bottom: 10px;
        }
        section{
            .content{
                display: none;
            }
            &.js-current-step {
                .content {
                    display: block;
                }
            }
        }
        .payment-option{
            img{
                display: block;
            }
        }
    }
    .djv-checkout-blocks{
        display: grid;
        grid-template-columns: repeat(2,50%);
        @media(max-width: $screen-lg){
            display: block;
        }
        .djv-checkout-block-login{
            padding: 10px 20px;
            border-right: 1px solid $gris7;
            @media(max-width: $screen-lg){
                border-right: none;
                border-bottom: 1px solid $gris7;
                margin-bottom: 20px;
                padding: 10px 10px;
            }
        }
        .djv-checkout-block-register{
            padding: 10px 20px;
            position: relative;
            @media(max-width: $screen-lg){
                border-bottom: 1px solid $gris7;
                margin-bottom: 20px;
                padding: 10px 10px;
            }
            #djv-checkout-button-register{
                @include btn-hover-animated($primario3, white, 50px, 5px, 10px);
                display: block;
                width: 200px;
                font-size: $font-smd;
            }
        }
    }
    #djv-checkout-form-register{
        display: none;
        #customer-form{
            .checkbox-field {
                position: relative;
                input[type="checkbox"]{
                    float: left;
                    margin-top: 5px;
                    margin-right: 5px;
                }
            }
        }
    }
    #delivery_message{
        width: 100%;
        padding: 16px;
        border:1px solid $gris8;
    }
    .js-address-form{
        .form-footer{
            @include djv-border-top(dashed);
            margin: 10px 0;
            a{
                @include btn-hover-animated($complementario4, white, 30px, 15px, 15px);
            }
            button{
                @include btn-hover-animated($complementario1, white, 30px, 15px, 15px);
            }
        }
    }
    .continue{
        @include btn-hover-animated($complementario1, white, 30px, 6px, 15px);
        width: 130px;
        margin: 10px 0;
        @media(max-width: $screen-lg){
            width: 100%;
            margin: 20px auto;
        }
    }
    #delivery-addresses, #invoice-addresses{
        display: grid;
        grid-template-columns: calc(33% - 16px) calc(33% - 16px) calc(33% - 16px);
        grid-gap: 16px;
        margin-bottom: 30px;
        @media(max-width: $screen-sm){
            grid-template-columns:auto;
        }
        .address-item{
            border: 1px dashed #dadada;
            padding: 8px;
            position: relative;
            &:last-of-type{
                border-bottom: 1px dashed #dadada;
            }
            &.selected{
                border: 4px solid $gris7;
            }
            label{
                width: 100%;
                display: block;
                padding-left: 26px;
                margin-bottom: 16px;
                padding-bottom: 40px;
                input{
                    margin-left: -26px;
                    margin-right: 10px;
                }
            }
            header{
                font-size: $font-blg;
                color: $gris9;
                border-bottom: 1px solid #dadada;
                padding-bottom: 6px;
            }
            footer{
                position: absolute;
                display: flex;
                margin-top: 40px;
                bottom: 10px;
                width: 92%;
                justify-content: space-between;
                a{
                    display: inline-block;
                    padding: 3px 16px;
                    text-align: center;
                    color:white;
                    font-size: $font-smd;
                }
                a[data-link-action="edit-address"]{
                    @include btn-hover-animated($primario3, white, 0px, 5px, 10px);
                }
                a[data-link-action="delete-address"]{
                    @include btn-hover-animated($complementario2, white, 0px, 5px, 10px);
                }
            }
        }
    }
    #delivery-addresses + a + h2{
        margin: 30px 0 0 0;
    }
    #checkout-addresses-step,
    #checkout-delivery-step,
    #checkout-payment-step{
        padding-bottom: 5px;
        margin-bottom: 5px;
        .warning{
            background-color: $complementario2;
            color: white;
            padding: 10px;
            font-size: $font-smd;
            &::before{
                content:"!";
                float: left;
                display: inline-block;
                margin-right: 10px;
                padding: 5px 12px;
                border-radius: 50%;
                font-size: $font-bmd;
                font-weight: 600;
                color: black;
                background-color: white;
            }
        }
    }
    #customer-form{
        margin: 10px 0;
        .addDoc{
            display: block;
            padding: 16px;
            background-color: $gris1;
            br{
                margin-bottom: 16px;
            }
        }
    }
    .delivery-options-list{
        .delivery-options{
            display: block;
            .delivery-option{
                display: grid;
                grid-template-columns: 30px calc(100% - 30px);
                align-items: start;
                input[type="radio"]{
                    margin-top: 5px;
                }
                img{
                    margin-bottom: 3px;
                    max-width: 30px;
                    margin-left: 10px;
                }
                .cms-block-example{
                    grid-column-start: 1;
                    grid-column-end: 3;
                    ul{
                        margin-left: 0;
                        padding-left: 5px;
                        li{
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }

    #payment-option-1-additional-information{
        section{
            p{
                @extend .cms-block-example;
            }
        }
    }
    #bankwire-modal{
        display: none;
        width: 1px;
        height: 1px;
    }
}
#js-checkout-summary{
    h5{
        a{
            @include fuente($font-second, $font-semibold);
            font-size: $font-lg;
            color: black;
        }
    }
    header{
        p{
            margin-bottom: 0;
            display: flex;
            justify-content: space-between;
            @include fuente($font-first, $font-medium);
            font-size: $font-smd;
            color: black;
        }
    }
    .products{
        @include djv-border-top(solid);
    }

    .show-details{
        animation-name: showdetails;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }
    @keyframes showdetails{
        from{
            height: 0;
            opacity: 0;
        }
        to{
            height: 100%;
            opacity: 1;
        }
    }

    .cart-summary-totals{
        .aviso-portes{
            display: block;
            font-size: $font-sm;
        }
        .cart-summary-totals{
            border-top: 1px solid $gris7;
            margin-top: 15px;
            padding-top: 15px;
            text-align: center;
            color: black;
            .label{
                font-size: $font-md;
                display: block;
            }
            .value{
                @include fuente($font-first, $font-medium);
                font-size: $font-xxxl;
            }
        }
    }

    .checkout-continuar-comprando{
        @include djv-border-top(solid);
        position: relative;
        font-size: $font-bmd;
        text-align: center;
        i{
            display: none;
        }
        a{
            display: block;
            font-size: $font-smd;
        }
    }
}
#cart-summary-ul{
    padding-left: 0;
    display: none;
    height: 0;
    opacity: 0;
    li{
        list-style: none;
        font-size: $font-smd;
        display: grid;
        grid-template-columns: 40px auto 100px;
        padding: 5px 0;
        border-bottom: 1px solid #ddd;
        &:last-of-type{
            border-bottom: 0;
        }
        .product-price{
            text-align: right;
        }
        .product-name{
            font-size: $font-smd;
        }
        .product-quantity{
            padding-top: 0;
        }
        span{
            &:first-of-type{
                text-align: center;
            }
            &:last-of-type{
                text-align: right;
            }
        }
    }
}
#cart-summary{
    .shipping{
        .shipping-tittle{

        }
        .shipping-link{
            display: block;
            a{
                color:$gris8;
                font-size: $font-xxs;
            }
        }

    }
    .tax{
        font-size: $font-smd;
        color: $gris8;
    }
    > div{
        display: grid;
        grid-template-columns: repeat(2,calc(50% - 5px));
        grid-gap: 10px;
        margin-bottom: 10px;
        > .value{
            text-align: right;
        }
    }
}
#cart-summary-product-list{
    margin-top: 10px;
}
#cart-summary-viewdetails{
    @include fuente($font-first, $font-regular);
    font-size: $font-sm;
    color: $primario3;
    cursor: pointer;
    &::before{
        margin-right: 5px;
        font-size: $font-xxs;
    }
}
#conditions-to-approve{
    margin-top: 20px;
    ul{
        margin-left: 0;
        padding-left: 0;
        li{
            list-style: none;
            display: flex;
            align-items: start;
            label{
                margin-bottom: 0;
                margin-left: 5px;
            }
        }
    }
}
#payment-confirmation{
    button{
        border:none;
        background-color: $gris2;
        color:$gris4;
        padding: 6px;
        margin: 10px;
        &:not([disabled]){
            @include btn-hover-animated($primario4, white, 30px, 15px, 15px);
            margin:10px;
        }
    }
}
.form-errors{
    margin-left: 0;
    padding-left: 0;
    .errorExist{
        background-color: red;
        color: white;
        padding: 16px;
    }
}
[class*='required']{
    > span{
        &::after{
            content: "*";
            display: inline-block;
            margin-left: 4px;
            color: red;
        }
    }
}
