#product{
    .product-accessories{
        @media(max-width: $screen-md){
            width: auto;
            float: none;
            padding: 0 15px;
            margin-top: 25px;
        }
        > h3{
            margin-bottom: 30px;
            @include fuente($font-second, $font-regular);
            font-size: $font-blg;
            text-align: center;
            color: black;
        }
        .product-accessories-container.djv_carouselh{
            @media(max-width: $screen-sm){
                padding: 0px;
            }
            .products{

                @media(max-width: $screen-md){
                    display: block;
                    width: 100%;
                    overflow-x: auto;
                    overflow-y: hidden;
                    white-space: nowrap;
                    -webkit-overflow-scrolling: touch;
                    -webkit-scroll-snap-type: mandatory;
                    scroll-snap-type: x mandatory;
                    -webkit-scroll-snap-points-x: repeat(100%);
                    scroll-snap-points-x: repeat(100%);
                }
                .product-miniature{
                    display: inline-block;
                    width: calc(20% - 10px);
                    padding: 0 5px;
                    float: none;
                    margin-bottom: 25px;
                    min-height: 350px;
                    @media(max-width: $screen-md){
                        scroll-snap-align: start;
                        width: 100%;
                        position: relative;
                        margin-right: -4px;
                        text-align: center;
                        grid-template-areas: "imagen name" "imagen priceshipping""actions actions";
                        grid-template-columns: 40% auto;
                    }
                    .product-miniature-img-block{
                        padding: 0 16px;
                    }
                    .product-miniature-data-block{
                        padding: 0 16px;
                    }
                }
            }
        }
    }
}
