.djv-pagination{
    position: relative;
    display: grid;
    grid-template-areas: "paginas numArticulos";
    grid-template-columns: 50% 50%;
    width: 100%;
    padding: 0;
    @media(max-width: $screen-md){
        display: block;
        padding: 0 0;
        margin-top: 0px;
        text-align: left;
    }
    .pagination-summary{
        grid-area: numArticulos;
        font-size: $font-md;
        padding-left: 0px;
        padding-left: 0px;
        align-self: center;
        justify-self: right;
        margin-right: 40px;
        @media(max-width: $screen-md){
            margin-right: 0px;
            justify-self: left;
            margin-top: 0px;
        }
    }
    .pagination-summary-movile{
        display: none;
        @media(max-width: $screen-md){
            display: inline-block;
            font-size: $font-xxs;
        }
    }
    ul{
        grid-area: paginas;
        display: inline-block;
        margin: 0;
        padding: 0;
        @media(max-width: $screen-sm){
            position: relative;
            margin-top: 5px;
            margin-bottom: 5px;
            padding-left: 0;
            float: right;
        }
        li{
            display: inline-block;
            margin: 0 3px;
            a{
                color: $gris6;
                padding: 4px;
                &[rel="prev"]{
                    margin-right: 0px;
                    &::before{
                        display: inline-block;
                        font-family: "comunes";
                        font-style: normal;
                        font-weight: normal;
                        line-height: 1;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        content:'\004b';
                        display: block;
                        float: left;
                        margin-top: 2px;
                    }
                }
                &[rel="nofollow"]{
                    min-width: 26px;
                    display: block;
                    text-align: center;
                    border: 1px solid $gris6;
                    border-radius: 4px;
                    font-size: $font-xs;
                }
                &[rel="next"]{
                    margin-left: 0px;
                    &::after{
                        display: inline-block;
                        font-family: "comunes";
                        font-style: normal;
                        font-weight: normal;
                        line-height: 1;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        content:'\004c';
                        display: block;
                        float: right;
                        margin-top: 2px;
                    }
                }
            }
            &.current{
                a[rel="nofollow"]{
                    color: $gris2;
                    border: 1px solid $gris2;
                }
            }
        }
    }
}
