.product-quantity{
    padding-top: 16px;
    p{
        font-size: $font-xs;
        display: block;
        margin-bottom: 0;
    }
    label{
        display: block;
        @include fuente($font-first, $font-medium);
        font-size: $font-md;
        text-align: center;
        margin-bottom: 5px;
    }
    .product-add-to-cart-quantity{
        display: flex;
        margin: 0 auto;
        font-size: $font-xs;
        @media(max-width: $screen-md){
            width: 100% !important;
            display: flex;
            justify-content: center;
        }
    }
    #quantity_wanted{
        text-align: center;
        background-color: white;
        border: 1px solid $gris4;
        padding-top: 4px;
        width: 40px;
        height: 30px;
    }
    .djv-product-add-to-cart-quantity-btn-less,.djv-product-add-to-cart-quantity-btn-plus{
        background-color: white;
        border: 1px solid $gris4;
        color:black;
        cursor: pointer;
        font-size: $font-smd;
        width: 30px;
        height: 30px;
        padding: 9px 5px;
        text-align: center;
    }
    .djv-product-add-to-cart-quantity-btn-plus{
        border-left: none;
    }
    .djv-product-add-to-cart-quantity-btn-less{
        border-right: none;
    }
}
#product{
    .product-add-to-cart-quantity{
        @media(max-width: $screen-md){
            height: 42px;
            margin: 20px 0px;
            width: 211px;
        }
        #quantity_wanted{
            @media(max-width: $screen-md){
                height: 42px;
                width: 132px;
            }
        }
        .djv-product-add-to-cart-quantity-btn-less{
            @media(max-width: $screen-md){
                height: 42px;
                padding: 5px 15px;
                width: 39px;
            }
        }
        .djv-product-add-to-cart-quantity-btn-plus{
            @media(max-width: $screen-md){
                height: 42px;
                padding: 9px 15px;
                width: 39px;
            }
        }
    }
}
