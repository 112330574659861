.user-info{
    display: none;
    position: absolute;
    right: 41px;
    top: 42px;
    background-color: white;
    padding: 10px;
    width: 300px;
    text-align: center;
    z-index: 5;
    box-shadow: 0px 14px 12px rgba(0,0,0,.4);
    @media(max-width:$screen-md){
        padding-top: 9px;
        width: 100vw;
        right: 0px;
    }
    > *{
        font-size: 0.8rem;
    }
    a, a:visited, a:hover{
        text-decoration: none;
        color: inherit;
        display: inline-block;
        span{
            display: none;
        }
        i{
            color: $primario5;
            display: inline-block;
        }
    }
    .account,
    .login,
    .logout {
        display: inline-block;
        font-size: $font-sm;
        margin: 0 10px;
        background-color: white;
        padding: 4px 15px;
        border-radius: 2px;
        @media(max-width:$screen-md){
            margin: 0 10px;
        }
    }
    > span {
        display: inline-block;
        font-size: $font-sm;
        margin: 0 10px;
        padding: 4px 15px;
        border-radius: 2px;
        color: $primario5;
        @media(max-width: $screen-md){
            display:none;
        }
    }
    .logout {
        &::before{
            color: $primario5;
        }
        @media(max-width:$screen-md){

        }
    }
}

@media (min-width: $screen-md){
  .user-info{
    a, a:visited, a:hover{
      display: inline-block;
      span{
       display: inline-block;
      }
      i{
        display: inline-block;
      }
    }
  }
}
