#modal-zoom{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(255,255,255,1);
    z-index: 5000;
    @media(max-width: 768px){
        overflow: hidden;
    }
    #consolaDebug{
        @media(max-width: $screen-md){
            position: fixed;
            top: 60px;
            width: 80px;
            padding: 10px;
        }
    }
    #modal-zoom-close{
        margin-bottom: 50px;
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 10;
        font-size: 32px;
        @media(max-width: $screen-md){
            top: 16px;
            z-index: 11;
            font-size: 26px;
            color: white;
        }
    }
    .controls{
        position: absolute;
        left: 50%;
        bottom: 97px;
        z-index: 10;
        transform: translateX(-50%);
        background-color: black;
        padding: 18px 10px 10px;
        display: block;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        @media(max-width: 768px){
            bottom: auto;
            top: 0;
            right: auto;
            left: 0;
            transform: none;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 3px;
            width: 100%;
            display: flex;
            justify-content: center;
        }
        > i{
            font-style: normal;
            font-weight: 800;
            font-size: 18px;
            color: white;
            margin: 5px 10px 1px;
            cursor: pointer;
            @media(max-width: 768px){

            }
            &::before{

            }
        }

        #modal-zoom-in{
        }
        #modal-zoom-out{
        }
    }
    #image-inside-modal{
        position: fixed;
        display: block;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        @media(max-width: 768px){
            overflow: scroll;
        }
        #consola{
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1000;
        }
        .product-cover{
            text-align: center;
            display: block;
            width: 100vw;
            height: 100vh;
            @media(max-width: 768px){
                overflow: auto;
            }
            img{
                position: absolute;
                transform-origin: center center 0;
                left: 50%;
                top: 50%;
                transform:translate(-50%, -50%) scale(.3);
                cursor: move;
                @media(max-width: 768px){
                    touch-action: pinch-zoom;
                    position: relative;
                    left: auto;
                    top: auto;
                    transform: none;
                    display: block;
                    margin: 5% auto;
                    //max-width: 100vw;
                    height: auto;
                    display: block;
                    border: 1px solid black;
                }
            }
        }
    }
    .thumbs-inside-modal{
        position: absolute;
        bottom: 0px;
        left: 0;
        width: 100%;
        background-color: white;
        border: 1px solid #ddd;
        border-radius: 0px;
        padding: 5px;
        margin: 0 auto;
        .djv_carouselh{
            width: 85%;
            display: block;
            margin: 0 auto;
        }
        #thumbs-modal{
            display: flex;
            justify-content: space-around;
            list-style: none;
            margin-bottom: 0;
            margin-left: 0;
            padding-left: 0;
            li{
                display: inline-block;
                margin: 0 4px;
                flex: 1;
                max-width: 85px;
                max-height: 85px;
                padding: 0;
                scroll-snap-align: start;
                width: 100%;
                position: relative;
                display: inline-block;
                text-align: center;
                img{
                    background-color: white;
                    margin-top: 0;
                    transition: all 500ms ease-out;
                    cursor: pointer;
                    box-shadow: 0 0 0 rgba(10,10,10,0);
                    width: 100%;
                    max-width: 85px;
                    max-height: 85px;
                    min-width: 85px;
                    min-height: 85px;
                    border: 0px;
                    border-color: $primario1;
                    &:hover{
                        border: 3px;
                        border-color: $primario1;
                        box-shadow: 1px 1px 5px rgba(10,10,10,0.85);
                    }
                }
            }
        }
    }
}
