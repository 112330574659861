#contact {
    #content {
        @include djv-container();
        h1 {
            @extend .djv-titulos-left-linea-discontinua;
            font-size: $font-lg;
        }

        .login-form {
            @include fuente($font-first, $font-regular);
            font-size: $font-md;
            h2 {
                font-size: $font-lg;
                font-weight: 600;
            }

            .form-fields {
                display: grid;
                grid-template-columns: 50%;
                padding-top: 16px;
                label {
                    margin-bottom: 20px;
                    span {
                        display: block;
                        margin-bottom: 5px;
                    }
                    &:first-of-type{
                        display: none;
                    }
                }
            }

            button {
                @include btn-hover-animated($primario7, white, 30px, 16px, 16px);
            }

            i.material-icons {
                display: none;
            }
            .form-footer{
                @include djv-border-top(dashed);
            }
        }
    }
}
