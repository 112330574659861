.cart-preview.cart-overview {
    width: 100%;
    position: inherit;
}

.cart-preview.cart-overview .body {
    display: block;
    position: inherit;
    width: 100%;
    color: $gris9;
}

.cart-preview {
    float: right;
    position: relative;
    .header {
        display: block;
        font-weight: bold;
        cursor: pointer;
        text-align: center;
        height: 35px;
        padding: 0 5px 0 5px;
        i {
            font-size: $font-blg;
            display: block;
            line-height: 13px;
            padding-top: 10px;
            color: $primario3;
            @media(min-width: $screen-md + 1){
                &::before{
                    font-family: "comunes";
                    content: '\0074';
                }
            }
        }
        span {
            position: absolute;
            top: 5px;
            right: -6px;
            display: block;
            width: 20px;
            height: 20px;
            padding: 3px 6px 3px 3px;
            border-radius: 40px;
            background-color: $primario1;
            @include fuente($font-first, $font-bold)
            font-size: $font-xs;
            color: white;
            text-align: center;
        }
    }
    .body {
        display: none;
        right: 0;
        top: 38px;
        width: calc(100vw - 1.1rem);
        padding: 15px;
        background-color: white;
        box-shadow: 1px 12px 9px rgba(0,0,0,.4);
        color: black;
        &:hover {
            display: block;
            position: absolute;
        }
        > ul {
            padding-left: 0;
            li {
                list-style: none;
                padding: 4px 7px;
                display: grid;
                grid-template-columns: 8% 60% 26% 6%;
                border-bottom: 1px solid $gris1;
                padding: 10px 0;
                &:last-of-type{
                    border-bottom: 0px;
                }
                &:hover {
                    background-color:lighten($gris3, 25%) ;
                }
                .product-quantity {
                    color: $primario3;
                    padding-top: 0px;
                    border-bottom: 0 !important;
                }
                .product-quantity::after {
                    content: 'x';
                }
                .product-name {
                    text-align: left;
                    padding-left: 5px;
                }
                .product-price{
                    text-align: right;
                    margin-right: 5px;
                }
                .remove-from-cart{
                    text-align: right;
                    color: $primario3;
                    font-size: $font-xxs;
                }
            }
        }
        .cart-subtotals {
            @include djv-border-top(dashed);
            text-align: right;
            padding-right: 10px;
            > * {
                display: grid;
                grid-template-columns: 72% 28%;
                text-align: right;
            }
            .value{
                @include fuente($font-first, $font-bold);
            }
            .products {
                text-align: right;
            }
            small{
                font-size: $font-xxs;
                color: $gris5;
                margin-left: 4px;
            }
        }
        @media(min-width: $screen-sm) {
            width: 400px;
        }
        .blockcart-finalizar{
            @include btn-hover-animated($primario1, white, 0px, 10px, 20px);
            margin: 20px auto 0px;
            display: block;
            width: 100%;
            text-transform: uppercase;
        }
    }
    &:hover .body {
        display: block;
        position: absolute;
        z-index: 999;
    }
}



/* this is to make the containers take up vertical space */
.cart-preview .cart-total > div::after,
.cart-preview .header::after {
    clear: both;
    content: "\00a0";
    font-family: "comunes";
}

.cart-preview .cart-total .label::after {
    content: ": ";
}

.cart-preview .cart-total > div {
    clear: both;
    border-bottom: 1px solid #ccc;
}

.cart-preview .cart-total > div:not(:last-child) {
    margin-bottom: 5px;
}

.cart-preview .cart-total {
    font-weight: bold;
    @include djv-border-top(solid);
    text-align: right;
    padding-right: 10px;
    span {
        font-size: $font-lg;
    }
}
/*
.recogido{
    .cart-preview{
        .header{
            background-color: transparent;
            span{
                color: black;
            }
            &::after{
                color: black;
            }
            i{
                color: black;
            }
        }
    }
}*/
