#product{
    .product-attachments{
        @include djv-container();
        .product-attachments-heading{
            background-color: $gris1;
            padding: 10px;
            @include fuente($font-second, $font-bold);
            font-size: $font-lg;
            font-weight: normal;
            text-align: left;
            margin-top: 35px;
            color: black;
            @media(max-width: $screen-md){
                font-size: $font-smd;
            }
            &::before{
                content: "";
                display: inline-block;
                margin-right: 10px;
                background-color: $primario2;
                width: 18px;
                height: 18px;
                border-radius: 50%;
            }
        }
        .attachment{
            display: inline-grid;
            margin-right: 15px;
            h4{
                a{
                    @include fuente($font-second, $font-semibold);
                    font-size: $font-md;
                }
            }
            >a{
                color: $primario2;
                margin-top: 5px;
            }
        }
    }
}
