@media(max-width: $screen-md){
    #header{
        min-height: 36px;
        position: fixed;
        padding-top: 0px;
        box-shadow: 1px 1px 6px rgba(0,0,0,.5);
        max-width: 100vw;
        #header-topbanner{
            .djv-container{
                width: 100%;
                max-width: 100vw;
                margin-left: 0;
                margin-right: 0;
                max-height: 40px;
                #close-banner-top{
                    right: 2px;
                }
                img{
                    max-width: 180%;
                    max-height: 25px;
                }
            }
        }
        .headerGrid{
            min-height: 36px;
            background-image: none;
            .djv-container{
                grid-template-columns: 100%;
                grid-template-areas:
                "topheadermobile"
                "bottomheadermobile"
                "headersectionleft";
                grid-template-rows: 36px;
                min-height: 36px;
                max-width: 100vw;
                width: 100vh;
                margin-left: 0;
                margin-right: 0;

                .nav-icons{
                    display: flex;
                    align-items: center;
                    #menu-icon-responsive{
                        color: $primario3;
                        font-size: $font-slg;
                        padding: 0 5px;
                        margin-left: 5px;
                        height: 21px;
                    }
                    .logo{
                        width: 150px;
                        height: 36px;
                        background-image: url("#{$urlimagenes}logo.png");
                        margin-top: 12px;
                        margin-left: 12px;
                        padding: 0;
                    }
                }
                .header-topbar{
                    font-size: 12px;
                    padding: 7px;
                    p{
                        color: black;
                        white-space: normal;
                        img{
                            display: none;
                        }
                    }
                }
                .topheadermobile{
                    grid-area: topheadermobile;
                    display: flex;
                    justify-content: space-between;
                    background-color: white;
                }
                .bottomheadermobile{
                    grid-area: bottomheadermobile;
                    display: flex;
                    height: 33px;
                    border-top: 1px solid $gris4;
                    overflow: hidden;
                    opacity: 1;
                    transition: all 500ms;
                    z-index: 0;
                }
                .header-section-center{
                    border-top: 1px solid $gris4;
                    display: none;
                }
                .header-section-right{
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: left;
                    align-items: center;
                    #search-icon-responsive, .djv-icon-lupa, .djv-icon-coment, #djv-icon-user, .djv-icon-carro{
                        font-size: $font-slg;
                        color: $primario3;
                        height: 21px;
                        padding-top: 0;
                    }

                    #blockcart-wrapper{
                        .cart-preview{
                            .header{
                                .djv-icon-carro{
                                    padding-top: 10px
                                }
                                span{
                                    left:9px;
                                    top:0;
                                    width: 16px;
                                    height: 16px;
                                    padding: 3px 4px;
                                    background-color: $primario2;
                                    color: $primario3;
                                }
                            }
                        }
                    }
                }
                .header-topbar-before{
                    font-size: 12px;
                    padding: 7px;
                    a{
                        color: black;
                    }
                    .whatsapp-block{
                        margin-right: 5px;
                    }
                    .azul{
                        display: none;
                    }
                    .djv-icon-mail{
                        display: none;
                    }

                }
                #h1Portada{
                    align-self: end;
                    margin: 8px 0;
                    font-size: $font-sm;
                    text-align: right;
                }
                .header-section-left{
                    border-top: 1px solid $gris5;
                    max-width: 100vw;
                }
                .header-section-left,
                .header-nav-top,
                .header-topbar-after,
                .login{
                    display: none;
                }
            }
        }
    }
}
