label{
    /*margin-bottom: 5px;
    position: relative;
    input{
        display:block;
        width: 100%;
        margin-top: 5px;
        padding: 10px;
        font-size: $font-xs;
    }
    &.required span{
        &::after{
            content: "*";
            color:red;
            position: absolute;
            padding-top: 0px;
            margin-left: 10px;
        }
        span{
            padding-left: 12px;
            padding-top: 10px;
        }
        input[type="checkbox"]{
            text-align: right;
        }
    }*/
}
