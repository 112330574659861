#my-account {
    .link-list {
        @include fuente($font-first, $font-regular);
        padding-left: 0;
        display: grid;
        grid-template-columns: calc(33% - 10px) calc(33% - 10px) calc(33% - 10px);
        grid-gap: 10px;
        @media(max-width: $screen-sm) {
            display: block;
        }

        li {
            list-style: none;
            padding: 10px;
            background-color: $primario2;
            transition: all 500ms;
            @media(max-width: $screen-sm) {
                margin-bottom: 10px;
            }

            a {
                color: $primario3;
            }

            &:hover {
                background-color: $primario3;
                a{
                    color: white;
                }
            }
        }
        .order-slips-link, .identity-link, .discounts-link{
            display:none;
        }
    }
    .page-my-account{
        header{
            margin-bottom: 16px;
            h1{
                font-size: $font-bmd;
            }
        }
    }
}

#order-detail{
    @media(max-width: $screen-lg){
        font-size: $font-xxs;
    }
    #main{
        @include fuente($font-first, $font-regular);
        @include djv-container();
        margin-top: 40px;
    }
    #order-infos{
        @extend .cms-block-example;
        color: $gris8;
        a{
            @include btn-hover-animated($complementario1, white, 50px, 20px, 5px);
            display: inline-block;
            margin-bottom: 15px;
            text-decoration: none;
        }
    }
    #order-history{
        padding: 16px;
        margin-bottom: 16px;
        @media(max-width: $screen-lg){
            padding: 16px 6px;
        }
        h1{
            @extend .djv-titulos-left-linea-discontinua;
            @media(max-width: $screen-lg){
                font-size: $font-md;
            }
        }
        table{
            thead{
                border-bottom: 1px solid #999;
            }
            td,th{
                padding: 8px;
                @media(max-width: $screen-lg){
                    font-size: $font-xxs;
                }
                .order-status-label {
                    padding:4px 8px;
                    font-size: 14px;
                    white-space: nowrap;
                    color: white;
                    @media(max-width: $screen-lg){
                        font-size: $font-xxs;
                    }
                }
            }
        }

    }
    #delivery-address,#invoice-address{
        width: calc(50% - 20px);
        margin: 8px;
        display: inline-block;
        border: 1px dashed #999;
        padding: 16px;
        @media(max-width: $screen-sm){
            width: calc(100% - 20px);
            h1{
                font-size: $font-md;
            }
        }
    }
    .box{
        ul{
            margin:10px 0;
            padding: 0;
            li{
                list-style: none;
            }
        }
    }
    #order-products{
        margin: 40px 0;
        th{
            white-space: nowrap;
            padding: 8px;
            background-color:$gris2;
            @media(max-width: $screen-sm){
                font-size: $font-xs - 2px;
                white-space: normal;
            }
        }
        td{
            padding: 8px;
            @media(max-width: $screen-sm){
                font-size: $font-xs - 2px;
            }
        }
        tfoot, .line-total{
            border-top: 2px solid $gris5;
        }
    }
    #order-shipping{
        display: block;
        width: 100%;
        tr{
            width: 100%;
            @media(max-width: $screen-sm){
                font-size: $font-xs - 2px;
            }
            td{
                padding: 8px;
                @media(max-width: $screen-sm){
                    font-size: $font-xs - 2px;
                }
            }
            th{
                white-space: nowrap;
                padding: 8px;
                background-color:$gris2;
                @media(max-width: $screen-sm){
                    white-space: normal;
                }
            }
        }
    }
    .order-message-form{
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid $gris6;
        @media(max-width: $screen-lg){
            padding: 10px;
            h1{
                font-size: $font-md;
            }
        }
        .form-fields{/*
            label{
                display: block;
                margin-bottom: 15px;
                span{
                    margin-right: 10px;
                }
                @media(max-width: $screen-lg){
                    textarea, select{
                        width: 100%;
                    }
                }
            }*/
        }
        .form-footer{
            button{
                @include btn-hover-animated($complementario1, white, 50px, 20px, 5px);
            }
        }
    }
}
.page-footer{
    padding: 0;
    margin-top: 14px;
    ul{
        padding-left: 0;
        margin-left: 0;
        margin-top: 20px;
    }
    li{
        display: inline-block;
        list-style: none;
        @media(max-width: $screen-lg){
            display: block;
            margin-bottom: 10px;
        }
        a{
            @include btn-hover-animated($primario3, white, 0px, 10px, 10px);
            font-size: $font-xs;
            margin-right: 10px;
            @media(max-width: $screen-lg){
                width: 100%;
                position: relative;
                display: block;
            }
        }
    }
}
