#saveStateScreener, .blockedWhileWaiting{
    background-image: url('#{$urlimagenes}loader.svg');
    background-position: center center;
    background-size: 120px;
    background-repeat: no-repeat;
    background-color: rgba(0,0,0,.8);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100000;
    @media(max-width: $screen-md){
        width: 100%;
        height: 100%;
    }
    .blockedMessage{
        position: absolute;
        top:10%;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        color: white;
        text-transform: uppercase;
    }
}
