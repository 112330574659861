#header.recogido{
    min-height: auto;
    position: fixed;
    top:0;
    background: white;
    .headerGrid{
        height: 45px;
        border-bottom: 1px solid $gris2;
        padding-bottom: 10px;
        background: white;
        .djv-container{
            grid-template-columns: 70px 250px auto 340px;
            grid-template-areas: "navicons headersectionleft headersectioncenter headersectionright";
            grid-template-rows: 38px;
            position: relative;
            align-items: center;
        }
        .nav-icons{
            grid-area: navicons;
            display: flex;
            align-items: center;
            padding-top: 10px;
            #menu-icon-responsive{
                flex: 1;
                font-size: $font-lg;
                color: $primario3;
                margin-left: 5px;
            }
            #search-icon-responsive
            {
                flex: 1;
                font-size: $font-lg;
                color: $primario3;
            }
        }
        .header-topbar-before,
        .header-topbar,
        .header-topbar-center,
        .header-topbar-after{
            display: none;
        }
        .header-section-left{
            display: headersectionleft;
            padding-top: 7px;
            #search_widget{
                display: none;
                margin: 0;
                margin-top: -2px;
                form{
                    width: 100%;
                    position: relative;
                    input{
                        height: 24px;
                        padding: 3px 10px;
                        border: 0;
                        width: 250px;
                        margin: 0;
                        border-radius: 0;
                        background-color: rgba(0, 0, 0, 0.2);
                        &::placeholder{
                            color: white;
                        }
                    }
                    button{
                        display: none;
                    }
                }
                &::placeholder {
                    color: white;
                    display: none;
                }
            }
        }
        .logo{
            display: block;
            width: 200px;
            height: 20px;
            margin: 2px auto;
            background: url("#{$urlimagenes}logo.png") no-repeat center;
            background-size: contain;
            padding-top: 50px;
            @media(max-width: $screen-lg){
                margin: 0;
            }
            .subTitleLogo{
                display: none;
            }
        }
        .header-section-center{
            grid-area: headersectioncenter;
        }
        .header-section-right{
            grid-area: headersectionright;
            color: black;
            align-items: center;
            .pedidos-telefono{
                display: none;
            }
            .djv-icon-linearuser{
                color: $primario3;
                cursor: pointer;
            }
            #blockcart-wrapper{
                float: none;
                .cart-preview .header{
                    i{
                        padding-top: 7px;
                    }
                }
            }
        }
        .header-nav-top{
            grid-area: mainMenu;
            display: none;
            position: absolute;
            left: 0;
            top: 36px;
            z-index: 10;
        }
        .header-nav-corporative{
            display:none;
        }

    }
}
