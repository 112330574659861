#identity{
    #main{
        @include djv-container();
        @include fuente($font-first, $font-regular);
        h3{
            @extend .djv-titulos-left-linea-discontinua;
            @include fuente($font-first, $font-regular);
        }
        #customer-form{
            .form-fields{/*
                width: auto;
                padding-left: 10px;

                @media(max-width: $screen-lg){
                    display: block;
                }
                label{
                    margin-bottom: 10px;
                    display: block;
                    width: 50%;
                    min-width: 320px;
                    @media(max-width: $screen-lg){
                        display: block;
                        width: 100%;
                        margin-bottom: 15px;
                    }
                    .title-label{
                        font-weight: 600;
                        display: block;
                        margin-bottom: 5px;
                    }
                    input:not([type=radio]),input:not([type=checkbox]), select{
                        border: none;
                        background-color: #eee;
                        padding: 5px;
                        width: 100%;
                    }
                    input[type="radio"],input[type="checkbox"]{
                        display: inline-block;
                        margin: 0 5px;
                        width: auto;
                        vertical-align: top;
                    }
                }
                .checkbox-field{
                    .title-label{
                        display: inline-block;
                        width: 90%;
                        font-weight: 400;
                    }
                }
                .id_gender{
                    display: none;
                }
                .newsletter{
                    width: 100%;
                    em{
                        font-style: normal;
                        font-size: $font-xs;
                    }
                }
                .psgdpr{
                    width: 100%;
                }*/
            }
        }
        .form-footer{
            [data-link-action="save-customer"]{
                @include btn-hover-animated($complementario1, white, 50px, 10px, 10px);
                font-size: $font-sm;
                @media(max-width: $screen-lg){
                    width: 100%;
                    display: block;
                    margin-top: 15px;
                }
            }
        }
        .page-footer{
            @include djv-border-top(solid);
        }
    }

}
