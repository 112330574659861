#notifications{
    @include djv-container();
    margin-top: 45px;
    margin-bottom:10px;
    font-size: $font-sm;
    ul{
        margin-left: 0;
        padding-left: 0;
    }
    li{
        list-style: none;
    }
}

.notification{
    padding: (3 * 10px);
}

.notification-danger{
    background: lighten($complementario2, 45%);
    border: 2px solid $complementario2;
}

.notification-warning{
    background: #fcf8e3;
    border:2px solid $complementario3;
}

.notification-success{
    background: #dff0d8;
    border:2px solid $complementario1;
}

.notification-info{
    background: #d9edf7;
    border:2px solid $complementario4;
}
