#product{
	.djv-product-detail-breadcrumb{
		border-bottom: 1px solid $gris1;
		text-align: center;
		font-size: $font-xs;
		color: $gris3;
		margin-bottom: 11px;
		ol{
			margin-bottom: 10px;
			li{
				display: inline;
				&::after{
					content: " > ";
				}
				&:last-of-type{
					&::after{
						content: "";
					}
				}
				a{
					color: inherit;
				}
			}
		}
	}
}
