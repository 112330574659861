 // CDN servers

$cdnserver1: "https://www.seleccionatolon.com/";
$cdnserver2: "https://www.seleccionatolon.com/";
$cdnserver3: "https://www.seleccionatolon.com/";

// IMAGES URL
$urlimagenes: "#{$cdnserver1}themes/base/assets/img/";
$urlicons:    "#{$cdnserver2}themes/base/assets/icons/";

//Colores primarios
$primario1:  #93ccfa;
$primario2:  #dbebf5;
$primario3:  #015f9b;
$primario4:  #0a3855;
$primario5:  #84b3d1;
$primario6:  #e1eef7;
$primario7:  #76a9cc;
$primario8:  #4CAF50;
$primario9:  #00ff00;
$primario10: #00ff00;
$primario11: #00ff00;
$primario12: #00ff00;



//Escala de grises
$gris1:  #EAEAEA;
$gris2:  #dddddd;
$gris3:  #cccccc;
$gris4:  #aaaaaa;
$gris5:  #999999;
$gris6:  #777777;
$gris7:  #666666;
$gris8:  #444444;
$gris9:  #333333;
$gris10: #111111;

//Colores complementarios
$complementario1:  #1a7e10;
$complementario2:  #D53128;
$complementario3:  #ff7926;
$complementario4:  #0083ff;
$complementario5:  #373737;
$complementario6:  #f1ba05;
$complementario7:  white;
$complementario8:  white;
$complementario9:  white;
$complementario10: white;

//Fonts
$font-first:     'Lato', sans-serif;
$font-second:    'Lato', sans-serif;
$font-light:     100;
$font-regular:   300;
$font-medium:    400;
$font-semibold:  700;
$font-bold:      900;
$font-extrabold: 800;
$font-black:     900;

// Font Sizes
$font-xxxs: 9px;
$font-xxs:  10px;
$font-xs:   11px;
$font-sm:   12px;
$font-smd:  14px;
$font-md:   16px;
$font-bmd:  18px;
$font-slg:  20px;
$font-lg:   22px;
$font-blg:  26px;
$font-xl:   28px;
$font-xxl:  40px;
$font-xxxl: 52px;

//Screen sizes
$screen-xs: 480px;
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 1024px;
$screen-xl: 1200px;
