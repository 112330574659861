#left-column {
    #left-responsive-switch{
        display: none;
        color:$primario1;
        font-size: $font-sm;
        @media(max-width: $screen-lg){
            display: block;
            padding: 6px;
            border-radius: 3px;
            background-color: $primario1;
            color:white;
            text-align: center;
            cursor: pointer;
            i{
                float: right;
                margin-right: 4px;
                margin-top: 3px;
            }
        }
    }
    .djv-navigator-responsive-filter-button{
        display: none;
        color:$primario1;
        font-size: $font-sm;
        @media(max-width: $screen-lg){
            display: block;
            position: relative;
            background-color: $primario1;
            color:white;
            text-align: center;
            padding: 10px;
            border-radius: 5px;
            cursor: pointer;
            i{
                position: absolute;
                right: 8px;
                top: 8px;
                background-color: white;
                padding: 5px;
                border-radius: 50%;
                width: 25px;
                height: 25px;
                color: $primario1;
                text-align: center;
            }
        }
    }
    #djv-navigation-left {
        @include fuente($font-first, $font-regular);
        font-size: $font-smd;
        @media(max-width: $screen-lg) {
            display: none;
            position: fixed;
            top: 0;
            background-color: white;
            z-index: 100;
            width: 100%;
            left: 0;
            padding: 10px;
            overflow-y: auto;
            height: 100vh;
        }
        h2{
            display: none;
            @media(max-width: $screen-md){
                display: block;
            }
        }

        > div {
            border: 1px solid $primario5;
            padding: 0px;
            margin-bottom: 0px;
            max-height: 350px;
            overflow-y: auto;
            scrollbar-color: $primario2 white;
            scrollbar-width: thin;
            &::-webkit-scrollbar {
              width: 10px;
              border-left: 1px solid $gris1;
            }

            &::-webkit-scrollbar-track {
              background: white;
              border-radius: 6px;
            }

            &::-webkit-scrollbar-thumb {
              background: $primario2;
              border-radius: 6px;
            }

            &::-webkit-scrollbar-thumb:hover {
              background: $primario2;
            }
            > div{
                padding: 10px;
            }
            h3 {
                @include fuente($font-second, $font-semibold);
                font-size: $font-bmd;
                background-color: $primario5;
                color: black;
                padding: 7px;
                margin-bottom: 5px;
                margin-top: 0px;
                span {
                    display: none;
                }
            }
            ul {
                margin-left: 0px;
                padding-left: 0;
                margin-bottom: 0;

                li {
                    list-style: none;
                    margin-bottom: 0;
                    font-size: $font-sm;
                    padding: 1px;

                    a {
                        padding: 2px;
                        color: $gris9;
                    }

                    span {
                        text-align: center;
                        padding: 1px;
                        font-size: $font-xxs;
                        color: $gris7;
                    }

                    &::before{
                        font-family: "comunes";
                        font-style: normal;
                        font-weight: normal;
                        line-height: 1;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        content:'\004c';
                        font-size: $font-xxxs;
                        padding-top: 6px;
                        color:$primario4;
                    }
                }
            }
            &.filter-type{
                border: 0px;
                background-color: $primario2;
                color: white;
                padding: 16px;
                h3{
                    color: white;
                    border-bottom: 1px solid white;
                }
                ul{
                    li{
                        a{
                            color: white
                        }
                        span{
                            color: white;
                        }
                    }
                }
            }
        }

        .djv_navigation-price{
            .djv_navigation-price-slider-range{
                margin-bottom: 15px;
                font-size: $font-smd;
                @media(max-width: $screen-md){
                    font-size:$font-md;
                }
            }
            .noUi-base, .noUi-connects {
                height: 2px;
                background:$gris1;
            }
            .noUi-target {
                background: white;
                border-radius: 0px;
                border: none;
                box-shadow: none;
                height: 2px;
            }
            .djv_navigation-price-slider{
                margin-bottom: 20px;
                width: 90%;
                margin-left: 5%;
                margin-bottom: 20px;
                .noUi-connect {
                    background: $primario1;
                }
                .noUi-handle{
                    border: none;
                    border-radius: 50px;
                    background: $primario1;
                    cursor: default;
                    box-shadow: none;
                    width: 14px;
                    height: 14px;
                }
                .noUi-handle::after, .noUi-handle::before {
                	content: "";
                	display: none;
                }
            }
            .djv_navigation-price-apply-button{
                @include btn-hover-animated($primario2, white, 50px, 20px, 5px);
                display: block;
                cursor: pointer;
                width: 60%;
                margin: 20px auto;
                padding: 10px;
                border-radius: 20px;
                font-size: $font-sm;
                color: white;
                &:hover{
                    background-color: white;
                }
            }
        }

        .djv-navigation-left-selected-item a::before {
            display: inline-block;
            font-style: normal;
            font-weight: normal;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            color: white;
            background-color: red;
            padding: 4px;
            border-radius: 50px;
            font-size: 8px;
            font-weight: bold;
            margin-right: 5px;
        }

        .djv-navigation-left-nofilter-selected {
            color: $gris3;
        }

        #djv-icon-close-filters {
            display: none;
            float: right;
            font-size: $font-sm;
            font-style: normal;
            @include fuente($font-first, $font-regular);
            cursor: pointer;
            background-color: $complementario2;
            color:white;
            padding: 5px;
            border-radius: 5px;
            &::before {
                display: inline-block;
                font-style: normal;
                font-weight: normal;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                color: $complementario2;
                background-color: white;
                padding: 4px;
                border-radius: 50px;
                font-size: $font-xs;
                font-weight: bold;
                margin-right: 0px;
            }
            @media(max-width: $screen-sm) {
                display: inline-block;
                padding: 7px;
                position: absolute;
                right: 11px;
                top: 11px;
            }
        }

    }

    .djv-information-delivery {
        padding: 15px 20px;
        border: 1px solid $gris4;
        margin-bottom: 20px;
        line-height: 22px;
        @media(max-width: $screen-lg){
            display: none;
        }
        i {
            font-size: $font-xl+2;
            color:$gris9;
            float: left;
            margin-right: 6px;
            margin-top: 2px;
        }
        strong{

        }
        p{
            font-size: $font-sm*0.9;
            color:$gris7;
            margin-bottom: auto;
            padding-left: 32px;
        }
    }

    .djv-need-help {
        background-color: $primario4;
        padding: 15px 10px;
        position: relative;
        margin-top: 20px;
        h3{
            text-transform: uppercase;
            color: $gris8;
            font-size: $font-smd;
            font-weight: 600;
        }
        .content-block{
            h4{
                font-size: $font-sm;
                margin-bottom: 0;
                margin-top: 15px;
                .icon{
                    width: 20px;
                    margin-top: -3px;
                }
                a{
                    color: $primario2;
                    font-weight: 600;
                }
            }
            p{
                padding-left: 23px;
            }
            ul{
                padding-left: 37px;
                margin-top: 7px;
                li{
                    margin-bottom: 7px;
                    strong,a{
                        color: $gris9;
                    }
                }
            }
        }
    }
}
