*{
  box-sizing: border-box;
}
body{
  @include fuente($font-first, $font-semibold);
  color: black;
  font-size: $font-md;
  line-height: inherit;
}
h2{
    @include fuente($font-first, $font-black);
    font-size:$font-xl;
    text-align: center;
    @media(max-width: $screen-md){
        font-size: $font-blg + 6px;
    }
}
h3{
    @include fuente($font-first, $font-regular);
    font-size: $font-blg;
}
a{
    color: black;
}
p{
    @include fuente($font-first, $font-medium);
    margin-bottom: auto;
}
.azul{
    color: $primario3;
}
.page-content{
    @include djv-container();
}

.full-width{
    width: 100vw;
    display: block;
    position: relative;
    margin-left: 50%;
    transform: translateX(-50%);
}
.djv-container{
    margin-right: auto;
    margin-left: auto;
    max-width: 1170px;
    min-width: 920px;
    @media(max-width:$screen-lg){
        max-width: 920px;
        min-width: 750px;
    }
    @media(max-width:$screen-md){
        max-width: 750px;
        min-width: 480px;
    }
    @media(max-width:$screen-xs){
        max-width: 500px;
        min-width: 300px;
    }
}
.ps-alert-success{
    li{
        list-style: none;
        border-bottom: 1px dashed darken($complementario1, 15%);
        i{
            float: left;
            margin-right: 20px;
            color:$complementario1;
            font-size: $font-xxl;
            padding-bottom: 20px;
        }
    }
}
.stars{
    i{
        color: $complementario6;
        font-size: $font-xxs;
    }
    span{
        font-size: $font-xxxs;
        display: inline-block;
        position: relative;
        width: 20px;
        height: 20px;
        top: -5px;
        a{
            position: absolute;
            top: 2px;
        }
    }
}
@media(max-width: $screen-md){
    html,body{
        overflow-x: hidden;
        max-width: 100vw;
    }
    .xs-hidden{
        display: none;
    }
}
.djv_debug{
    position: fixed;
    opacity: 0.1;
    top: 80px;
    left: 10px;
    background-color: black;
    border: 1px solid black;
    z-index: 1000;
    padding: 20px;
    width: 110px;
    height: 90px;
    transition: all 1s;
    overflow: hidden;
    &::before{
        content: "DEBUG";
        color: red;
        font-weight: bold;
        display: block;
    }
    &:hover{
        width: 55vw;
        opacity: .9;
        height: calc(100vh - 300px;);
        border: 5px solid black;
        background-color: white;
        overflow: auto;
    }
}
//Banner -- Esto quiza tengamos que moverlo a su propio SCSS
.noslider-banner{
    position: relative;
    .data-noslider-banner{
        position: absolute;
        top: 110px;
        width: 100%;
        text-align: center;
        color: white;
        h3{
            @include fuente($font-first, $font-black);
            text-transform: uppercase;
            font-size: $font-xl;
        }
        p{
            @include fuente($font-first, $font-light);
            font-size: $font-md;
        }
        .offer-banner{
            .antes{
                color: $gris5;
                text-decoration: line-through;
            }
            strong{
                @include fuente($font-first, $font-bold);
                font-size: $font-xl;
                color: $primario6;
                .decimales{
                    font-size: $font-bmd;
                }
            }

        }
    }
}
