#pagenotfound{
    #djv-two-columns-layer{
        @include djv-container();
        #search_widget{
            .djv-icon-lupa{
                display: none;
            }
            input[type="text"]{
                padding: 5px 3px 6px 3px;
                height: 33px;
            }
            button{
                @include btn-hover-animated($primario7, white, 10px, 20px, 5px);;
                &::after{
                    content: "Buscar";
                }
            }
        }
    }
}
