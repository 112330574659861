
#header_logo{
    .logo{
        .subTitleLogo{
            @include fuente($font-second, $font-light);
            font-size: $font-smd;
            color: black;
        }
    }
}
#header{
    min-height: 160px;
    position: relative;
    z-index: 99;
    width: 100%;
    top: 0;
    background-image: url("#{$urlimagenes}banda_menu.gif");
    background-repeat: repeat-x;
    background-size: 1px 31px;
    background-position-y: 99px;
    background-color: white;
    .icon{
        width: 17px;
        display: inline-block;
        margin-top: 2px;
    }
    #header-topbanner{
        position: relative;
        width: 100%;
        display: none;
        overflow: hidden;
        background-color: white;
        .djv-container{
            position: relative;
            #close-banner-top{
                position: absolute;
                top:5px;
                right: 10px;
                background-color: white;
                border-radius: 50%;
                height: 18px;
            }

            img{
                position: relative;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }


    .headerGrid{
        background-image: url("#{$urlimagenes}banda_menu.gif");
        background-repeat: repeat-x;
        background-size: 1px 31px;
        font-size: $font-smd;
        font-weight: 300;
        .djv-container{
            position: relative;
            display: grid;
            grid-template-columns: 39% auto 31%;
            grid-template-areas:
            "contacto enviofree envios"
            "headersectionleft headersectioncenter headersectionright"
            "mainMenu mainMenu mainMenu";
            grid-template-rows: 31px 98px 45px;
            align-items: center;
            min-height: 175px;
            /* Bloque de grid */
            .azul{
                color:$primario5;
                margin: 0 5px;
            }
            .nav-icons{
                grid-area: navicons;
                display: none;
            }
            .header-topbar-before{
                grid-area: contacto;
                font-size: $font-md;
                font-weight: 600;
                display: flex;

                .whatsapp-block{
                    display: flex;
                    margin-right: 6px;
                    img{
                        width: 16px;
                        height: 16px;
                        margin-right: 4px;
                    }
                }
                .djv-icon-mail{
                    margin-right: 6px;
                }
            }
            .header-topbar{
                grid-area: enviofree;
                text-align: center;
                font-size: $font-md;
                font-weight: 600;
                p{
                    color: $primario3;
                }
                img{
                    width: 14px;
                    height: 14px;
                    margin-right: 5px;
                    margin-top: -2px;
                }
            }
            .header-topbar-after{
                font-size: $font-md;
                font-weight: 600;
                grid-area: envios;
                text-align: right;
                align-content: center;
                display: flex;
                justify-content: end;
                padding-right: 10px;
                padding-top: 4px;
                i{
                    font-size: 18px;
                    margin: 0px 2px 0 0;
                }

            }
            .header-section-left{
                grid-area: headersectionleft;
                align-self: center;
            }
            .header-section-center{
                grid-area: headersectioncenter;
            }
            .header-section-right{
                grid-area: headersectionright;
                position: relative;
                display: grid;
                grid-template-areas: "contacto login resumecart";
                grid-template-columns: repeat(3,35px);
                grid-gap: 0px 22px;
                align-self: center;
                justify-content: end;
                padding-right: 13px;

                #blockcart-wrapper{
                    grid-area: resumecart;
                    position: relative;
                    z-index: 999;
                    display: inline-block;
                }
                .login,#djv-icon-user{
                    grid-area: login;
                    font-size: $font-blg;
                    text-align: center;
                    padding-top: 10px;
                    color: $primario3;
                }
                .djv-icon-coment{
                    grid-area: contacto;
                    font-size: $font-blg;
                    text-align: center;
                    padding-top: 10px;
                    color: $primario3;
                }
            }
            .header-nav-top{
                grid-area: mainMenu;
            }
            /* --- Fin Bloque de elementos adicionales*/
            /* Bloque de elementos adicionales*/

            .logo{
                background-image: url("#{$urlimagenes}logo.png");
                background-position: 0px top;
                background-repeat: no-repeat;
                background-size: contain;
                width: 380px;
                height: 63px;
                display: block;
                margin: 0;
                padding: 78px 0 0;
                position: relative;
            }


            /* -- Fin Bloque de elementos adicionales*/
        }
    }
}
