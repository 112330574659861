#product{
    .product-prices{
        .offer-flag{
            position: relative;
            width: 65px;
            display: inline-block;
            padding: 6px;
            background-color: $primario6;
            color: white;
            &::before{
                content: "";
                display: block;
                position: absolute;
                width: 10px;
                height: 10px;
                bottom: -10px;
                background-image: url("#{$urlicons}/triangle_down.svg");
            }
        }
        .product-discount{
            float: right;
            margin-bottom: 0px;
            margin-right: 11px;
            padding-top: 6px;
            text-align: right;
            .regular-price{
                display: inline-block;
                @include fuente($font-first, $font-medium);
                font-size: $font-smd;
                text-decoration: line-through;
                color: $gris7;
            }
        }
        .product-price{
            display: inline-block;
            .with-taxes{
                font-size: $font-xxl;
                @include fuente($font-first, $font-semibold);
                @media(max-width: $screen-md){
                    font-size: $font-blg;
                }
                small{
                    font-size: $font-xxs;
                    @include fuente($font-first, $font-regular);
                    margin-top: 0px;
                    color:$gris9;
                    line-height: 26px;
                    @media(max-width: $screen-md){
                        font-size: $font-xxxs;
                    }
                }
                .decimales{
                    font-size: $font-lg;
                }
            }
            .without-taxes{
                font-size: $font-xl;
                @include fuente($font-first, $font-semibold);
                @media(max-width: $screen-md){
                    font-size: $font-blg;
                }
                small{
                    display: inline-block;
                    margin-top: 0px;
                    font-size: $font-xs;
                    @include fuente($font-first, $font-regular);
                    color:$gris7;
                    line-height: 26px;
                    @media(max-width: $screen-md){
                        font-size: $font-xxxs;
                    }
                }
                .decimales{
                    font-size: $font-xs;
                }
            }
            .advertiseWithoutTaxes{
                display: block;
                font-size: $font-xxs;
                @include fuente($font-first, $font-regular);
                line-height: $font-xs;
                margin-top: 0px;
                color:$gris5;
                text-align: center;
                @media(max-width: $screen-md){
                    font-size: $font-xxxs;
                }
            }
            &.has-discount{
                margin-top: 5px;
                .with-taxes{
                    color: $primario6;
                }
            }
        }
        .discount-percentage,.discount-amount{
            display: none;
        }
    }
}
