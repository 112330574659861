#module-djv_navigation-nsearch {
    #djv-two-columns-layer{
        .textoSeo{
            h2{
                @include fuente($font-first, $font-semibold);
                color: $primario2;
                font-size: $font-bmd;
                text-align: left;
                margin-top: 20px;
            }
            p{
                @include fuente($font-first, $font-regular);
                margin-bottom: 12px;
                padding-left: 12px;
                font-size: $font-smd;
                text-align: left;
            }
            b{
                padding-left: 12px;
                font-size: $font-smd;
            }
            @media(max-width: $screen-sm){
                width: auto;
                margin-left: auto;
            }
        }
    }
}

.textoSeo,#category-description{
    section:not(.full-width){
        @include djv-container();
    }
    section{
        header{
            text-align: center;
        }
    }
    .ver-mas{
        @include btn-hover-animated($primario7, white, 50px, 20px, 5px);
        color: white;
        width: 170px;
        margin: 0px auto;
        font-size: $font-sm;
        display: block;
        margin-top: 30px;
    }
    .djv-flex{
        display: flex;
        > *{
            flex: 1;
            margin: 0 7px;
        }
        >a{
            background-color: black;
            img{
                transition: opacity 500ms;
                opacity: 1;
                &:hover{
                    opacity: .5;
                }
            }
        }
        @media(max-width: $screen-md){
            display: block;
        }
    }
    .element-icon{
        display: grid;
        grid-template-columns: 20% 80%;
        @media(max-width: $screen-md){
            display: block;
            text-align: center;
            margin-bottom: 30px;
        }
        img{
            float: left;
            display: inline-block;
            width: 50px;
            margin-right: 16px;
            @media(max-width: $screen-md){
                float: none;
                margin-right: 0;
            }
        }
        div{
            display: inline-block;
            @media(max-width: $screen-md){
                display: block;
            }
            h3{
                color:black;
                font-size: 21px;
                @include fuente($font-first, $font-medium);
                display: block;
                margin-bottom: 0;
            }
            p{
                display: block;
                font-size: 14px;
                @include fuente($font-first, $font-light);
            }
        }
    }
    .tag-list{
        display:flex;
        justify-content: center;
        flex-wrap: wrap;
        a{
            @include btn-hover-animated($gris5, white, 50px, 20px, 5px);
            min-width: 120px;
            margin: 10px;
        }
    }
}
