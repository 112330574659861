#module-djv_navigation-nsearch, #category{
    >#bannerSlider{
        display: none;
    }
    .djv-navigation-nsearch-header{
        background-color: white;
    }

    #category-description{
        @include fuente($font-first, $font-regular);
        padding: 15px 0;
        color: black;
        text-align: center;
        h1{
            @extend .full-width;
            display: block;
            @include fuente($font-second, $font-semibold);
            text-transform: uppercase;
            font-size: $font-bmd;
            margin-bottom: 0px;
            padding: 16px;
            background-color: $primario1;
            color: white;
        }
        a{
            color:$primario3;
        }
        p{
            @include fuente($font-first, $font-light);
            font-size: $font-smd;
            line-height: $font-slg;
            span{
                &#sigue_leyendo{
                    font-size: $font-smd;
                    color:$primario3;
                    cursor: pointer;
                }
            }
        }
        .djv-product-list-count{
            position:absolute;
            top:15px;
            right: 15px;
        }
    }

    #djv-two-columns-layer{
        @include djv-container();
        display: block;
        margin-top: 20px;
        #left-column{
            display: none;
        }
        #content-wrapper{
            position: relative;
            h1{
                display: block;
                @media(max-width: $screen-sm){
                    font-size: $font-lg;
                }
            }
            #bannerSlider{
                &.carousel [class*="djv-icon-arrow-"]::before {
                    background-color: transparent;
                }
                .carousel-indicator-container{
                    display: none;
                }
            }
        }
        #js-product-list-top{
            width: 100%;
            position: relative;
            padding: 0;
            margin-top: 15px;
            display: grid;
            grid-template-areas: "order pagination";
            grid-template-columns: 40% 60%;
            @media(max-width: $screen-md){
                display: block;
                border-bottom: 1px solid $gris5;
                padding-bottom: 5px;
                margin-top: 0px;
                margin-bottom: 20px;
            }
            .products-sort-order{
                grid-area: order;
                justify-self: left;
                margin-right: 38px;
                @media(max-width: $screen-sm){
                    margin-right: 0px;
                }
            }
            .pagination{
                grid-area: pagination;
                justify-self: left;
                align-items: center;
            }
        }
        #js-product-list{
            display:grid;
            grid-template-columns: repeat(5, calc(20% - 15px));
            grid-gap: 15px;
            padding: 16px;
            width: 100%;
            @media(max-width: $screen-md){
                display: block;
                padding: 0;
            }
            .product-miniature{
                text-align: center;
                @media(max-width: $screen-md){
                    padding: 0;
                    height: auto;
                    min-height: auto;
                    margin-bottom: 16px;
                }
            }
            &.djv-display-list{
                grid-template-columns:auto;
            }
        }
        #js-product-list-bottom{
            @include djv-container();
        }
        .page-not-found{
            text-align: center;
            p{
                font-size: $font-lg;
                margin-bottom: 16px;
            }
            #search_widget{
                input{
                    border: 1px solid $gris5;
                    padding: 16px 50px;
                    border-radius: 20px;
                }
                button{
                    padding: 8px;
                    border-radius: 20px;
                }
            }
        }
    }
}


@media(max-width:$screen-lg){
    .carousel ~ .djv-navigation-nsearch-header{
        margin-top: 0px;
    }
}
.djv_carouselh.list-originales{
    .products{
        .product-miniature{
            min-height: 380px;
            max-height: 380px;
        }
    }
}
