.page-cms{
    #djv-two-columns-layer{
        @include djv-container;
        h1{
            margin-top: 20px;
            margin-bottom: 20px;
            padding-bottom: 15px;
            border-bottom: 1px dashed #bababa;
            @include fuente($font-second, $font-semibold);
            font-size: $font-lg;
            @media(max-width: $screen-sm){
                font-size: $font-md;
            }
        }
        h2, h3, h4{
            margin-top: 30px;
            text-align:left;
            @include fuente($font-second, $font-semibold);
            font-size: $font-lg;
            color: black;
        }
        h3{
            font-size: $font-slg;
        }
        h4{
            font-size: $font-bmd;
        }
        ul{
            list-style-type: disc;
            box-sizing: border-box;
            &.nobullet{
                list-style-type:none;
            }
            @media(max-width: $screen-md){
                display: block;
                li{
                    display: block;
                    margin-bottom: 20px;
                }
            }
        }
        p{
            margin-bottom: 16px;
        }
        a{
            color: $primario2;
            font-size: 18px;
            &:hover{
                text-decoration: underline;
            }
        }

        .btn-cms{
            display:block;
            font-size: 20px;
            color: #ffffff;
            background-color: $primario3;
            padding: 15px 35px 15px 35px;
            margin-top: 20px;
            margin-left: auto;
            margin-right: auto;
            width: 220px;
            text-align: center;
            a{
                color: white;
            }
        }
        img.full{
            max-width: 100vw;
            width: 100%;
            @media(max-width: $screen-md){
                height: auto;
            }
        }
    }
}
