#index{


    h2{
        margin: 16px auto 45px auto;
        @include fuente($font-second, $font-regular);
        font-size: $font-xl;
        color: black;
        @media(max-width: $screen-md){
            font-size: $font-slg;
        }
    }
    section{
        @media(max-width: $screen-md){
            margin-top: 5px;
            margin-bottom: 5px;
        }
        &
        .full-width{
            padding: 15px 0;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
    #main,#content{
        margin-bottom: 0;
    }
    #footer{
        margin-top: 42px;
    }

    .propuestas-valor{
        @include full-width();
        margin-top: 10px;
        margin-bottom: 25px;
        border-bottom: 1px solid #eaeaea;
        padding-bottom: 40px;
        text-align: center;
        @media(max-width: $screen-md){
            padding: 16px;
            overflow-x: scroll;
        }
        .djv-container{
            display: flex;
            @media(max-width: $screen-md){
                display: block;
                max-width: 100vw;
            }
            article{
                padding: 0px;
                @media(max-width: $screen-md){
                    margin-bottom: 15px;
                    border-right: 0;
                }
                img{
                    display: block;
                    width: 69px;
                    margin: 0px auto;
                }
                p{
                    @include fuente($font-second, $font-medium);
                    font-size: $font-md;
                }
            }
        }
    }
    .destacado-portada{
        @include fuente($font-second, $font-medium);
        text-align: center;
        display: block;
        padding-top: 0;
        border-bottom: 1px solid #eaeaea;
        padding-bottom: 54px;
        margin-bottom: 35px;
        h2{
            margin-bottom: 30px;
        }
        article{
            background-color: $primario6;
            padding: 54px 20px 30px;
            width: 480px;
            text-align: center;
            @media(max-width: $screen-md){
                max-width: 100vw;
                padding: 30px 15px;
            }
        }
        h5{
            margin-top: 0px;
            margin-bottom: 0px;
            font-size: $font-blg;
            text-transform: uppercase;
        }
        p{
            font-size: $font-slg;
        }
        .btn-ver{
            @include btn-hover-animated($primario3,$primario1,0,6px,16px);
            font-size: $font-bmd;
            text-transform: lowercase;
            display: block;
            margin: 13px auto;
            width: 150px;
        }
    }
    .destacado-portada2{

        display: flex;
        @media(max-width: $screen-md){
            display: block;
        }
        h5{
            @include fuente($font-first, $font-bold);
            font-size: $font-xxl;
            margin-bottom: 0;
            margin-bottom: 9px;
            line-height: 36px;
        }
        p{
            font-size: $font-md;
        }
        article{
            flex: 1;
            background-repeat: no-repeat;;
            background-size: cover;
            padding: 165px 30px 50px;
            color: white;
            text-align: center;
            @media(max-width: $screen-md){
                padding-top: 70px;
                padding-bottom: 70px;
            }
            &:nth-of-type(1){
                margin-right: 5px;
                background-image: url("#{$urlimagenes}bloque01.jpg");
                @media(max-width: $screen-md){
                    margin-right: 0;
                }
                .btn-ver{
                    color: $primario1;
                    display: block;
                    text-transform: lowercase;
                    margin-top: 20px;
                }
            }
            &:nth-of-type(2){
                margin-left: 5px;
                background-image: url("#{$urlimagenes}bloque02.jpg");
                @media(max-width: $screen-md){
                    margin-left: 0;
                }
                .contacto{
                    margin-top: 10px;
                    display: block;
                    color:$primario1;
                    font-weight: 600;
                    a{
                        color:$primario1;
                        margin-right: 10px;
                    }
                }
            }
            &:nth-of-type(3){
                margin-left: 5px;
                background-image: url("#{$urlimagenes}bloque03.jpg");
                @media(max-width: $screen-md){
                    margin-left: 0;
                }
            }
        }
    }
}
