#product{
    .djv-product-images{
        position: relative;
        .product-flags{
            display: none;
        }
        .product-cover{
            overflow: hidden;
            position: relative;
            margin-bottom: 20px;
            cursor: pointer;
            @media(max-width: $screen-sm){
                min-width: 200px;
                min-height: 0px;
            }
            img{
                margin-left: auto;
                margin-right: auto;
                display: block;
                @media(max-width: $screen-sm){
                    max-width: 100%;
                }
            }
        }
        .product-thumbnails{
            @media(max-width: $screen-sm){
                margin-top: -16px;
            }
            &.djv_carouselh{
                @media(max-width: $screen-md){
                    &::after{
                        top: 0px;
                    }
                }
            }
            .thumbnails{
                height: 80px;
                overflow: hidden;
                padding: 0;
                background-color: white;
                @media(max-width: $screen-md){
                    width: 100%;
                    overflow-x: scroll;
                    overflow-y: hidden;
                    white-space: nowrap;
                    -webkit-overflow-scrolling: touch;
                    -webkit-scroll-snap-type: mandatory;
                    scroll-snap-type: x mandatory;
                    -webkit-scroll-snap-points-x: repeat(100%);
                    scroll-snap-points-x: repeat(100%);
                }
                li{
                    list-style: none;
                    padding: 6px;
                    display: inline-block;
                    width: 120px;
                    min-height: 120px;
                    background-color: white;
                    img{
                        width: 100%;
                    }
                    @media(max-width: $screen-md){
                        scroll-snap-align: start;
                        width: 33%;
                        position: relative;
                        display: inline-block;
                        margin-right: -4px;
                        text-align: center;
                        img{
                            margin-left: auto;
                            margin-right: auto;
                            width: 70px!important;
                            height: 70px!important;
                        }
                    }
                }
            }
        }

    }
}
