.form-fields{
    margin-top: 30px;
    label:not(.checkbox-field){
        display: block;
        max-width: 100%;
        margin-bottom: 20px;
        position: relative;
        max-height: 70px;
        @media(max-width: $screen-md){
            max-width: 100%;
        }
        span{
            display: block;
            margin: 10px 0;
        }
        input{
            border: none;
            background-color: #eee;
            padding: 10px;
            width: 100%;
            font-size: $font-bmd;
            @media(max-width: $screen-sm){
                width: 100%;
            }
        }
    }

    label.checkbox-field{
        display: flex;
        margin-bottom: 15px;
        align-items: flex-start;
        span{
            margin-left: 5px;
            display: block;
            em{
                font-size: $font-xs;
                color: $gris8;
                font-style: normal;
            }
        }
    }
    label.radio-field {
        display: none;
        label{
            display:inline-block;
            width: auto;
            margin-right: 5px;
            margin-bottom: 15px;
            input{
                display:inline-block;
                width: auto;
                margin-right: 5px;
            }
        }
    }
    label.same-address{
        align-items: center;
    }
    .passwordCloseEye,.passwordOpenEye{
        position: absolute;
        top: 28px;
        margin-top: 0 !important;
        left: calc(100% - 42px);
        height: 42px;
        width: 42px !important;
        padding: 9px;
        background-color: $primario3;
        color: white;
        margin-bottom: 0 !important;
        cursor: pointer;
        border-radius: 3px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        font-size: 24px;
        &::after{
            content: "";
            display: none;
        }
    }
    .vat_number, .id_country{
        display: none !important;
    }
}
#customer-form{
    label{
        max-width: 50%;
        @media(max-width: $screen-md){
            max-width: 100%;
        }
    }
}
.lost_password{
    margin-top: 20px;
    text-decoration: underline;
}
