#footer{
    margin-top: 50px;
    background-color: $primario6;
    @media(max-width: $screen-lg){
        margin-top: 30px;
    }

    .block-contact{
        padding: 5px;
        background-color: $primario7;
        font-size: $font-slg;
        color: white;
        .block-contact-container{
            @include djv-container();
            display: flex;
            padding: 6px 53px;
            justify-content: space-between;
            @media(max-width: $screen-lg){
                display: block;
                padding: 10px;
            }
            [class*='djv-icon']:before{
                margin: 0 6px 0 0;
                position: relative;
                top: 2px;
            }
            a{
                color: white;
            }
            p{
                margin-bottom: 0;
            }
        }
    }

    .djv-footer-navigation{
        color: black;
        margin-top: 15px;
        margin-bottom: 0;
        .djv-footer-container{
            @include djv-container();
            display: grid;
            grid-template-columns: 50% 40% auto;
            padding: 0 52px;
            @media(max-width: $screen-md){
                display: block;
                padding: 10px;
            }
        }
        .djv-footer-navigation-container{
            display: grid;
            grid-template-columns: 50% 50%;
            grid-gap: 0 24px;
            max-width: 520px;
            min-width: 520px;
            margin-left: 50px;
            @media(max-width: $screen-md){
                display: block;
            }
            h4{
                font-weight: 500;
                font-size: $font-slg;
                margin-bottom: 5px;
            }
            ul{
                padding: 0;
                li{
                    list-style: none;
                    font-weight: 300;
                    font-size: $font-md;
                    margin-bottom: 6px;
                }
            }
            .formas-pago{
                text-align: center;
                grid-column-start: 1;
                grid-column-end: 3;
                margin-top: 22px;
            }
        }
    }

    .djv-email-subscription{
        padding: 10px 50px 0 0 ;
        @media(max-width: $screen-md){
            padding: 20px;
        }
        .djv-email-subscription-container{
            .djv-email-subscription-logo{
                display: none;
                img{
                    width: 156px;
                    margin-bottom: 10px;
                }
            }
            .email-subcription-text{
                position: relative;
                h4{
                    display: inline-block;
                    margin-bottom: 5px;
                    font-weight: 700;
                    font-size: $font-slg;
                    color: black;
                    text-transform: uppercase;
                }
                p{
                    font-weight: 500;
                    font-size: $font-smd;
                    color: black;
                }
                p.notification{
                    position: absolute;
                    left: 0;
                    width: 350px;
                    top: 133px;
                    background-color: white;
                    border: 3px solid;
                    padding: 6px;
                    &.notification-success{
                        color:$complementario1;
                        @media(max-width: $screen-md){
                            margin-bottom: 0;
                        }
                    }
                    &.notification-error{
                        color:$complementario2;
                        &::before{
                            content:"* ";
                            font-size: $font-md;
                        }
                        @media(max-width: $screen-md){
                            right: 0;
                            margin-bottom: 0;
                        }
                    }
                }
            }
            .djv-email-subscription-form{
                padding: 0px 5px 0 0;
                .input-submit{
                    display: grid;
                    grid-template-columns: 75% 25%;
                    margin: 10px auto;
                    &.accept{
                        text-align: center;
                    }
                }
                input[type="text"]{
                    border: 0;
                    padding: 10px 10px;
                    font-size: $font-sm;
                    font-weight: 300;
                    background-color: white;
                }
                input[type="checkbox"]{
                    border: 1px solid $gris2;
                }
                input[type="submit"]{
                    @include btn-hover-animated($primario1,white,0,10px,10px);
                    @include fuente($font-first, $font-semibold);
                    font-size: $font-sm;
                }
                .control-label{
                    font-size: $font-xs;
                    margin: 10px 0 0 10px;
                    a{
                        @include fuente($font-first, $font-medium);
                    }
                }
                .accept-policy{
                    display: block;
                }
            }
            .notification-error{
                position: relative;
                right: -453px;
                width: 350px;
                color:$primario10;
            }
        }
    }

    .footer-below, .checkout-footer-simplified{
        margin-top: 30px;
        padding: 10px 16px;
        @include fuente($font-first, $font-regular);
        font-size: $font-xs;
        text-align: center;
        a{
            font-weight: 600;
            color:$primario2;
            text-decoration: underline;
        }
    }
}
