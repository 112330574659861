@charset "UTF-8";
/* Generated by Glyphter (http://www.glyphter.com) on  Thu May 16 2019*/
@font-face {
  font-family: 'comunes';
  src: url("project/djv-icon-font/fonts/comunes.eot");
  src: url("project/djv-icon-font/fonts/comunes.eot?#iefix") format("embedded-opentype"), url("project/djv-icon-font/fonts/comunes.woff") format("woff"), url("project/djv-icon-font/fonts/comunes.ttf") format("truetype");
  /* url('project/djv-icon-font/fonts/comunes.svg#comunes') format('svg');*/
  font-weight: normal;
  font-style: normal; }

[class*='djv-icon']:before {
  display: inline-block;
  font-family: 'comunes';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.djv-icon-coment:before {
  content: '\0041'; }

.djv-icon-coment-2:before {
  content: '\0042'; }

.djv-icon-carro:before {
  content: '\0043'; }

.djv-icon-menu:before {
  content: '\0044'; }

.djv-icon-lupa:before {
  content: '\0045'; }

.djv-icon-lupa-plus:before {
  content: '\0046'; }

.djv-icon-lupa-minus:before {
  content: '\0047'; }

.djv-icon-usuario:before {
  content: '\0048'; }

.djv-icon-telefono:before {
  content: '\0049'; }

.djv-icon-mail:before {
  content: '\004a'; }

.djv-icon-arrow-left:before {
  content: '\004b'; }

.djv-icon-arrow-right:before {
  content: '\004c'; }

.djv-icon-arrow-up:before {
  content: '\004d'; }

.djv-icon-arrow-down:before {
  content: '\004e'; }

.djv-icon-facebook:before, #product .djv-product-detail-share .social-sharing .facebook:before {
  content: '\004f'; }

.djv-icon-twitter:before, #product .djv-product-detail-share .social-sharing .twitter:before {
  content: '\0050'; }

.djv-icon-youtube:before {
  content: '\0051'; }

.djv-icon-instagram:before {
  content: '\0052'; }

.djv-icon-delete:before {
  content: '\0053'; }

.djv-icon-close:before {
  content: '\0054'; }

.djv-icon-ok:before {
  content: '\0055'; }

.djv-icon-ok-circle:before {
  content: '\0056'; }

.djv-icon-ok-circle-open:before {
  content: '\0057'; }

.djv-icon-plus:before {
  content: '\0058'; }

.djv-icon-minus:before {
  content: '\0059'; }

.djv-icon-refresh:before {
  content: '\005a'; }

.djv-icon-reload:before {
  content: '\0061'; }

.djv-icon-reply:before {
  content: '\0062'; }

.djv-icon-move:before {
  content: '\0063'; }

.djv-icon-filter:before {
  content: '\0064'; }

.djv-icon-exit:before {
  content: '\0065'; }

.djv-icon-gear:before {
  content: '\0066'; }

.djv-icon-question:before {
  content: '\0067'; }

.djv-icon-question-circle:before {
  content: '\0068'; }

.djv-icon-information:before {
  content: '\0069'; }

.djv-icon-warning:before {
  content: '\006a'; }

.djv-icon-star:before {
  content: '\006b'; }

.djv-icon-empty-star:before {
  content: '\006c'; }

.djv-icon-printer:before {
  content: '\006d'; }

.djv-icon-swipe:before {
  content: '\006e'; }

.djv-icon-tap:before {
  content: '\006f'; }

.djv-icon-whatsapp:before {
  content: '\0070'; }

.djv-icon-pinterest:before {
  content: '\0071'; }

.djv-icon-delivery:before {
  content: '\0072'; }

.djv-icon-heart:before {
  content: '\0073'; }

.djv-icon-bag:before {
  content: '\0074'; }

.djv-icon-linearheart:before {
  content: '\0075'; }

.djv-icon-linearuser:before {
  content: '\0076'; }

.djv-icon-lineardelivery:before {
  content: '\0077'; }

.djv-icon-clock:before {
  content: '\0078'; }

.djv-icon-trash:before {
  content: '\0079'; }

.djv-icon-openeye:before {
  content: '\007a'; }

.djv-icon-closeeye:before {
  content: '\0030'; }

.djv-icon-pencil:before {
  content: '\0031'; }

/* #### Visualizaciones #### */
@media (max-width: 768px) {
  .xs-visible {
    display: block !important; } }

.etiqueta-flecha {
  -webkit-clip-path: polygon(0% 0%, 70% 0, 90% 50%, 70% 100%, 0% 100%);
  clip-path: polygon(0% 0%, 70% 0, 90% 50%, 70% 100%, 0% 100%); }

/* #### LAYOUT #### */
.djv-container-stickytop {
  margin-top: 200px; }

/* ---- grid ---- */
.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 10px));
  grid-gap: 10px; }
  .grid-2 > * {
    margin: auto; }
  @media (max-width: 768px) {
    .grid-2 {
      display: block; } }

.grid-3 {
  display: grid;
  grid-template-columns: repeat(3, calc(33.33% - 10px));
  grid-gap: 10px; }
  .grid-3 > * {
    margin: auto; }
  @media (max-width: 768px) {
    .grid-3 {
      display: block; } }

.grid-4 {
  display: grid;
  grid-template-columns: repeat(4, calc(25% - 10px));
  grid-gap: 10px; }
  .grid-4 > * {
    margin: auto; }
  @media (max-width: 768px) {
    .grid-4 {
      display: block; } }

.grid-5 {
  display: grid;
  grid-template-columns: repeat(5, calc(20% - 10px));
  grid-gap: 10px; }
  .grid-5 > * {
    margin: auto; }
  @media (max-width: 768px) {
    .grid-5 {
      display: block; } }

.flex-center {
  display: flex;
  justify-content: space-around; }
  .flex-center > * {
    margin: auto 20px; }

/* ···· FIN BOTONES ···· */
/* #### TITULARES #### */
.djv-titulos-barras-laterales {
  display: grid;
  grid-template-columns: auto 30% auto;
  position: relative; }
  @media (max-width: 768px) {
    .djv-titulos-barras-laterales {
      grid-template-columns: auto 80% auto; } }
  .djv-titulos-barras-laterales::before {
    content: "";
    border-bottom: 1px solid #cccccc;
    display: block;
    width: 100%;
    margin-right: 20px;
    position: relative;
    transform: translateY(-50%); }
  .djv-titulos-barras-laterales::after {
    content: "";
    border-bottom: 1px solid #cccccc;
    display: block;
    width: 100%;
    margin-right: 20px;
    position: relative;
    transform: translateY(-50%); }

.djv-titulos-left-linea-discontinua, #order-detail #order-history h1, #order-confirmation #main h3, #identity #main h3, #contact #content h1, #search h2, #search .page-not-found > p, #module-ph_simpleblog-list #djv-two-columns-layer h1,
#module-ph_simpleblog-page #djv-two-columns-layer h1,
#module-ph_simpleblog-category #djv-two-columns-layer h1,
#module-ph_simpleblog-tag #djv-two-columns-layer h1,
#module-ph_simpleblog-categorypage #djv-two-columns-layer h1, .ph_simpleblog .title_block {
  font-size: 22px;
  padding: 10px 0 15px 5px;
  border-bottom: 1px dashed #666666;
  margin-bottom: 20px;
  text-transform: uppercase; }

/* ···· FIN TITULARES ···· */
/* #### BLOQUES #### */
.cms-block-example, #checkout #payment-option-1-additional-information section p, #order-detail #order-infos {
  background-color: #EAEAEA;
  display: block;
  padding: 30px;
  margin: 16px 0px;
  outline: 1px dashed #cccccc;
  outline-offset: -10px; }

/* ···· FIN BLOQUES ···· */
/* #### BORDES #### */
.djv-border-top-dashed {
  border-top: 1px dashed #cccccc;
  padding-top: 20px;
  margin-top: 20px; }

.djv-border-top-solid {
  border-top: 1px solid #cccccc;
  padding-top: 20px;
  margin-top: 20px; }

.djv-border-bottom-dashed {
  border-bottom: 1px dashed #cccccc;
  padding-bottom: 20px;
  margin-bottom: 20px; }

.djv-border-bottom-solid {
  border-bottom: 1px solid #cccccc;
  padding-bottom: 20px;
  margin-bottom: 20px; }

/* ···· FIN BORDES ···· */
* {
  box-sizing: border-box; }

body {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  color: black;
  font-size: 16px;
  line-height: inherit; }

h2 {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-size: 28px;
  text-align: center; }
  @media (max-width: 768px) {
    h2 {
      font-size: 32px; } }

h3 {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-size: 26px; }

a {
  color: black; }

p {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  margin-bottom: auto; }

.azul {
  color: #015f9b; }

.page-content {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px; }
  @media (max-width: 1024px) {
    .page-content {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    .page-content {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    .page-content {
      max-width: 500px;
      min-width: 300px; } }

.full-width, #module-djv_navigation-nsearch #category-description h1, #category #category-description h1 {
  width: 100vw;
  display: block;
  position: relative;
  margin-left: 50%;
  transform: translateX(-50%); }

.djv-container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px; }
  @media (max-width: 1024px) {
    .djv-container {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    .djv-container {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    .djv-container {
      max-width: 500px;
      min-width: 300px; } }

.ps-alert-success li {
  list-style: none;
  border-bottom: 1px dashed #0c3a07; }
  .ps-alert-success li i {
    float: left;
    margin-right: 20px;
    color: #1a7e10;
    font-size: 40px;
    padding-bottom: 20px; }

.stars i {
  color: #f1ba05;
  font-size: 10px; }

.stars span {
  font-size: 9px;
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  top: -5px; }
  .stars span a {
    position: absolute;
    top: 2px; }

@media (max-width: 768px) {
  html, body {
    overflow-x: hidden;
    max-width: 100vw; }
  .xs-hidden {
    display: none; } }

.djv_debug {
  position: fixed;
  opacity: 0.1;
  top: 80px;
  left: 10px;
  background-color: black;
  border: 1px solid black;
  z-index: 1000;
  padding: 20px;
  width: 110px;
  height: 90px;
  transition: all 1s;
  overflow: hidden; }
  .djv_debug::before {
    content: "DEBUG";
    color: red;
    font-weight: bold;
    display: block; }
  .djv_debug:hover {
    width: 55vw;
    opacity: .9;
    height: calc(100vh - 300px);
    border: 5px solid black;
    background-color: white;
    overflow: auto; }

.noslider-banner {
  position: relative; }
  .noslider-banner .data-noslider-banner {
    position: absolute;
    top: 110px;
    width: 100%;
    text-align: center;
    color: white; }
    .noslider-banner .data-noslider-banner h3 {
      font-family: "Lato", sans-serif;
      font-weight: 900;
      text-transform: uppercase;
      font-size: 28px; }
    .noslider-banner .data-noslider-banner p {
      font-family: "Lato", sans-serif;
      font-weight: 100;
      font-size: 16px; }
    .noslider-banner .data-noslider-banner .offer-banner .antes {
      color: #999999;
      text-decoration: line-through; }
    .noslider-banner .data-noslider-banner .offer-banner strong {
      font-family: "Lato", sans-serif;
      font-weight: 900;
      font-size: 28px;
      color: #e1eef7; }
      .noslider-banner .data-noslider-banner .offer-banner strong .decimales {
        font-size: 18px; }

#header_logo .logo .subTitleLogo {
  font-family: "Lato", sans-serif;
  font-weight: 100;
  font-size: 14px;
  color: black; }

#header {
  min-height: 160px;
  position: relative;
  z-index: 99;
  width: 100%;
  top: 0;
  background-image: url("https://www.seleccionatolon.com/themes/base/assets/img/banda_menu.gif");
  background-repeat: repeat-x;
  background-size: 1px 31px;
  background-position-y: 99px;
  background-color: white; }
  #header .icon {
    width: 17px;
    display: inline-block;
    margin-top: 2px; }
  #header #header-topbanner {
    position: relative;
    width: 100%;
    display: none;
    overflow: hidden;
    background-color: white; }
    #header #header-topbanner .djv-container {
      position: relative; }
      #header #header-topbanner .djv-container #close-banner-top {
        position: absolute;
        top: 5px;
        right: 10px;
        background-color: white;
        border-radius: 50%;
        height: 18px; }
      #header #header-topbanner .djv-container img {
        position: relative;
        left: 50%;
        transform: translateX(-50%); }
  #header .headerGrid {
    background-image: url("https://www.seleccionatolon.com/themes/base/assets/img/banda_menu.gif");
    background-repeat: repeat-x;
    background-size: 1px 31px;
    font-size: 14px;
    font-weight: 300; }
    #header .headerGrid .djv-container {
      position: relative;
      display: grid;
      grid-template-columns: 39% auto 31%;
      grid-template-areas: "contacto enviofree envios" "headersectionleft headersectioncenter headersectionright" "mainMenu mainMenu mainMenu";
      grid-template-rows: 31px 98px 45px;
      align-items: center;
      min-height: 175px;
      /* Bloque de grid */
      /* --- Fin Bloque de elementos adicionales*/
      /* Bloque de elementos adicionales*/
      /* -- Fin Bloque de elementos adicionales*/ }
      #header .headerGrid .djv-container .azul {
        color: #84b3d1;
        margin: 0 5px; }
      #header .headerGrid .djv-container .nav-icons {
        grid-area: navicons;
        display: none; }
      #header .headerGrid .djv-container .header-topbar-before {
        grid-area: contacto;
        font-size: 16px;
        font-weight: 600;
        display: flex; }
        #header .headerGrid .djv-container .header-topbar-before .whatsapp-block {
          display: flex;
          margin-right: 6px; }
          #header .headerGrid .djv-container .header-topbar-before .whatsapp-block img {
            width: 16px;
            height: 16px;
            margin-right: 4px; }
        #header .headerGrid .djv-container .header-topbar-before .djv-icon-mail {
          margin-right: 6px; }
      #header .headerGrid .djv-container .header-topbar {
        grid-area: enviofree;
        text-align: center;
        font-size: 16px;
        font-weight: 600; }
        #header .headerGrid .djv-container .header-topbar p {
          color: #015f9b; }
        #header .headerGrid .djv-container .header-topbar img {
          width: 14px;
          height: 14px;
          margin-right: 5px;
          margin-top: -2px; }
      #header .headerGrid .djv-container .header-topbar-after {
        font-size: 16px;
        font-weight: 600;
        grid-area: envios;
        text-align: right;
        align-content: center;
        display: flex;
        justify-content: end;
        padding-right: 10px;
        padding-top: 4px; }
        #header .headerGrid .djv-container .header-topbar-after i {
          font-size: 18px;
          margin: 0px 2px 0 0; }
      #header .headerGrid .djv-container .header-section-left {
        grid-area: headersectionleft;
        align-self: center; }
      #header .headerGrid .djv-container .header-section-center {
        grid-area: headersectioncenter; }
      #header .headerGrid .djv-container .header-section-right {
        grid-area: headersectionright;
        position: relative;
        display: grid;
        grid-template-areas: "contacto login resumecart";
        grid-template-columns: repeat(3, 35px);
        grid-gap: 0px 22px;
        align-self: center;
        justify-content: end;
        padding-right: 13px; }
        #header .headerGrid .djv-container .header-section-right #blockcart-wrapper {
          grid-area: resumecart;
          position: relative;
          z-index: 999;
          display: inline-block; }
        #header .headerGrid .djv-container .header-section-right .login, #header .headerGrid .djv-container .header-section-right #djv-icon-user {
          grid-area: login;
          font-size: 26px;
          text-align: center;
          padding-top: 10px;
          color: #015f9b; }
        #header .headerGrid .djv-container .header-section-right .djv-icon-coment {
          grid-area: contacto;
          font-size: 26px;
          text-align: center;
          padding-top: 10px;
          color: #015f9b; }
      #header .headerGrid .djv-container .header-nav-top {
        grid-area: mainMenu; }
      #header .headerGrid .djv-container .logo {
        background-image: url("https://www.seleccionatolon.com/themes/base/assets/img/logo.png");
        background-position: 0px top;
        background-repeat: no-repeat;
        background-size: contain;
        width: 380px;
        height: 63px;
        display: block;
        margin: 0;
        padding: 78px 0 0;
        position: relative; }

#header.recogido {
  min-height: auto;
  position: fixed;
  top: 0;
  background: white; }
  #header.recogido .headerGrid {
    height: 45px;
    border-bottom: 1px solid #dddddd;
    padding-bottom: 10px;
    background: white; }
    #header.recogido .headerGrid .djv-container {
      grid-template-columns: 70px 250px auto 340px;
      grid-template-areas: "navicons headersectionleft headersectioncenter headersectionright";
      grid-template-rows: 38px;
      position: relative;
      align-items: center; }
    #header.recogido .headerGrid .nav-icons {
      grid-area: navicons;
      display: flex;
      align-items: center;
      padding-top: 10px; }
      #header.recogido .headerGrid .nav-icons #menu-icon-responsive {
        flex: 1;
        font-size: 22px;
        color: #015f9b;
        margin-left: 5px; }
      #header.recogido .headerGrid .nav-icons #search-icon-responsive {
        flex: 1;
        font-size: 22px;
        color: #015f9b; }
    #header.recogido .headerGrid .header-topbar-before,
    #header.recogido .headerGrid .header-topbar,
    #header.recogido .headerGrid .header-topbar-center,
    #header.recogido .headerGrid .header-topbar-after {
      display: none; }
    #header.recogido .headerGrid .header-section-left {
      display: headersectionleft;
      padding-top: 7px; }
      #header.recogido .headerGrid .header-section-left #search_widget {
        display: none;
        margin: 0;
        margin-top: -2px; }
        #header.recogido .headerGrid .header-section-left #search_widget form {
          width: 100%;
          position: relative; }
          #header.recogido .headerGrid .header-section-left #search_widget form input {
            height: 24px;
            padding: 3px 10px;
            border: 0;
            width: 250px;
            margin: 0;
            border-radius: 0;
            background-color: rgba(0, 0, 0, 0.2); }
            #header.recogido .headerGrid .header-section-left #search_widget form input::placeholder {
              color: white; }
          #header.recogido .headerGrid .header-section-left #search_widget form button {
            display: none; }
        #header.recogido .headerGrid .header-section-left #search_widget::placeholder {
          color: white;
          display: none; }
    #header.recogido .headerGrid .logo {
      display: block;
      width: 200px;
      height: 20px;
      margin: 2px auto;
      background: url("https://www.seleccionatolon.com/themes/base/assets/img/logo.png") no-repeat center;
      background-size: contain;
      padding-top: 50px; }
      @media (max-width: 1024px) {
        #header.recogido .headerGrid .logo {
          margin: 0; } }
      #header.recogido .headerGrid .logo .subTitleLogo {
        display: none; }
    #header.recogido .headerGrid .header-section-center {
      grid-area: headersectioncenter; }
    #header.recogido .headerGrid .header-section-right {
      grid-area: headersectionright;
      color: black;
      align-items: center; }
      #header.recogido .headerGrid .header-section-right .pedidos-telefono {
        display: none; }
      #header.recogido .headerGrid .header-section-right .djv-icon-linearuser {
        color: #015f9b;
        cursor: pointer; }
      #header.recogido .headerGrid .header-section-right #blockcart-wrapper {
        float: none; }
        #header.recogido .headerGrid .header-section-right #blockcart-wrapper .cart-preview .header i {
          padding-top: 7px; }
    #header.recogido .headerGrid .header-nav-top {
      grid-area: mainMenu;
      display: none;
      position: absolute;
      left: 0;
      top: 36px;
      z-index: 10; }
    #header.recogido .headerGrid .header-nav-corporative {
      display: none; }

@media (max-width: 768px) {
  #header {
    min-height: 36px;
    position: fixed;
    padding-top: 0px;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.5);
    max-width: 100vw; }
    #header #header-topbanner .djv-container {
      width: 100%;
      max-width: 100vw;
      margin-left: 0;
      margin-right: 0;
      max-height: 40px; }
      #header #header-topbanner .djv-container #close-banner-top {
        right: 2px; }
      #header #header-topbanner .djv-container img {
        max-width: 180%;
        max-height: 25px; }
    #header .headerGrid {
      min-height: 36px;
      background-image: none; }
      #header .headerGrid .djv-container {
        grid-template-columns: 100%;
        grid-template-areas: "topheadermobile" "bottomheadermobile" "headersectionleft";
        grid-template-rows: 36px;
        min-height: 36px;
        max-width: 100vw;
        width: 100vh;
        margin-left: 0;
        margin-right: 0; }
        #header .headerGrid .djv-container .nav-icons {
          display: flex;
          align-items: center; }
          #header .headerGrid .djv-container .nav-icons #menu-icon-responsive {
            color: #015f9b;
            font-size: 20px;
            padding: 0 5px;
            margin-left: 5px;
            height: 21px; }
          #header .headerGrid .djv-container .nav-icons .logo {
            width: 150px;
            height: 36px;
            background-image: url("https://www.seleccionatolon.com/themes/base/assets/img/logo.png");
            margin-top: 12px;
            margin-left: 12px;
            padding: 0; }
        #header .headerGrid .djv-container .header-topbar {
          font-size: 12px;
          padding: 7px; }
          #header .headerGrid .djv-container .header-topbar p {
            color: black;
            white-space: normal; }
            #header .headerGrid .djv-container .header-topbar p img {
              display: none; }
        #header .headerGrid .djv-container .topheadermobile {
          grid-area: topheadermobile;
          display: flex;
          justify-content: space-between;
          background-color: white; }
        #header .headerGrid .djv-container .bottomheadermobile {
          grid-area: bottomheadermobile;
          display: flex;
          height: 33px;
          border-top: 1px solid #aaaaaa;
          overflow: hidden;
          opacity: 1;
          transition: all 500ms;
          z-index: 0; }
        #header .headerGrid .djv-container .header-section-center {
          border-top: 1px solid #aaaaaa;
          display: none; }
        #header .headerGrid .djv-container .header-section-right {
          display: flex;
          flex-direction: row-reverse;
          justify-content: left;
          align-items: center; }
          #header .headerGrid .djv-container .header-section-right #search-icon-responsive, #header .headerGrid .djv-container .header-section-right .djv-icon-lupa, #header .headerGrid .djv-container .header-section-right .djv-icon-coment, #header .headerGrid .djv-container .header-section-right #djv-icon-user, #header .headerGrid .djv-container .header-section-right .djv-icon-carro {
            font-size: 20px;
            color: #015f9b;
            height: 21px;
            padding-top: 0; }
          #header .headerGrid .djv-container .header-section-right #blockcart-wrapper .cart-preview .header .djv-icon-carro {
            padding-top: 10px; }
          #header .headerGrid .djv-container .header-section-right #blockcart-wrapper .cart-preview .header span {
            left: 9px;
            top: 0;
            width: 16px;
            height: 16px;
            padding: 3px 4px;
            background-color: #dbebf5;
            color: #015f9b; }
        #header .headerGrid .djv-container .header-topbar-before {
          font-size: 12px;
          padding: 7px; }
          #header .headerGrid .djv-container .header-topbar-before a {
            color: black; }
          #header .headerGrid .djv-container .header-topbar-before .whatsapp-block {
            margin-right: 5px; }
          #header .headerGrid .djv-container .header-topbar-before .azul {
            display: none; }
          #header .headerGrid .djv-container .header-topbar-before .djv-icon-mail {
            display: none; }
        #header .headerGrid .djv-container #h1Portada {
          align-self: end;
          margin: 8px 0;
          font-size: 12px;
          text-align: right; }
        #header .headerGrid .djv-container .header-section-left {
          border-top: 1px solid #999999;
          max-width: 100vw; }
        #header .headerGrid .djv-container .header-section-left,
        #header .headerGrid .djv-container .header-nav-top,
        #header .headerGrid .djv-container .header-topbar-after,
        #header .headerGrid .djv-container .login {
          display: none; } }

@media (min-width: 769px) {
  body:not(.recogido) .header-nav-top {
    position: relative;
    height: 60px;
    z-index: 1;
    margin: auto;
    width: 100%; }
    body:not(.recogido) .header-nav-top #djv-close-nav-responsive {
      display: none; }
    body:not(.recogido) .header-nav-top #main-menu {
      width: 100vw;
      display: block;
      position: relative;
      margin-left: 50%;
      transform: translateX(-50%);
      position: absolute;
      border-top: 1px solid #cccccc;
      margin-top: 0px;
      padding-top: 0px;
      background-color: white; }
      body:not(.recogido) .header-nav-top #main-menu .ul-menu {
        margin-right: auto;
        margin-left: auto;
        max-width: 1170px;
        min-width: 920px;
        display: flex;
        position: relative;
        margin-bottom: 0;
        justify-content: left;
        list-style: none; } }
      @media (min-width: 769px) and (max-width: 1024px) {
        body:not(.recogido) .header-nav-top #main-menu .ul-menu {
          max-width: 920px;
          min-width: 750px; } }
      @media (min-width: 769px) and (max-width: 768px) {
        body:not(.recogido) .header-nav-top #main-menu .ul-menu {
          max-width: 750px;
          min-width: 480px; } }
      @media (min-width: 769px) and (max-width: 480px) {
        body:not(.recogido) .header-nav-top #main-menu .ul-menu {
          max-width: 500px;
          min-width: 300px; } }

@media (min-width: 769px) {
        body:not(.recogido) .header-nav-top #main-menu .ul-menu > li {
          padding: 5px 5px 3px 5px;
          line-height: 33px;
          white-space: nowrap;
          font-size: 18px; }
          body:not(.recogido) .header-nav-top #main-menu .ul-menu > li:nth-of-type(1) {
            font-size: 17px;
            margin-right: 26px;
            margin-left: 8px; }
          body:not(.recogido) .header-nav-top #main-menu .ul-menu > li:nth-of-type(2) {
            margin-right: 26px; }
          body:not(.recogido) .header-nav-top #main-menu .ul-menu > li:nth-of-type(3) {
            margin-left: 26px; }
          body:not(.recogido) .header-nav-top #main-menu .ul-menu > li strong {
            text-transform: uppercase;
            font-weight: 700; }
          body:not(.recogido) .header-nav-top #main-menu .ul-menu > li a {
            color: #015f9b; } }

body.recogido .header-nav-top {
  position: relative;
  z-index: 1;
  width: 460px; }
  body.recogido .header-nav-top #djv-close-nav-responsive {
    display: none; }
  body.recogido .header-nav-top #main-menu {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-size: 16px;
    padding: 20px;
    margin-top: 0px;
    background-color: white;
    box-shadow: 0px 3px 3px rgba(32, 72, 96, 0.3);
    height: 100px; }
    body.recogido .header-nav-top #main-menu .menu-first-level-withoutlink {
      display: none; }
    body.recogido .header-nav-top #main-menu .ver-todo-responsive {
      display: none; }
    body.recogido .header-nav-top #main-menu .ul-menu {
      display: block;
      position: relative;
      color: black;
      margin-bottom: 0;
      padding: 4px 8px 16px; }
      body.recogido .header-nav-top #main-menu .ul-menu a {
        text-decoration: none;
        color: black;
        transition: color 500ms; }
        body.recogido .header-nav-top #main-menu .ul-menu a:hover {
          color: #015f9b; }
      body.recogido .header-nav-top #main-menu .ul-menu ul {
        padding: 0; }
      body.recogido .header-nav-top #main-menu .ul-menu li {
        list-style: none;
        margin-bottom: 5px; }

@media (max-width: 768px) {
  body:not(.recogido) #contact-icon-responsive {
    display: inline-block; }
  body:not(.recogido) .header-nav-top {
    float: none;
    width: 100%;
    padding: 10px;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.95);
    height: calc(100vh);
    overflow: scroll;
    display: none;
    box-shadow: 1px 7px 5px 2px rgba(0, 0, 0, 0.3);
    z-index: 999; }
    body:not(.recogido) .header-nav-top #djv-close-nav-responsive {
      color: #015f9b;
      display: block;
      margin-bottom: 20px;
      cursor: pointer;
      position: relative;
      padding-left: 30px;
      padding-top: 3px;
      font-size: 14px;
      border-bottom: 1px solid #015f9b;
      padding-bottom: 10px; }
      body:not(.recogido) .header-nav-top #djv-close-nav-responsive::before {
        font-size: 22px;
        color: #015f9b;
        position: absolute;
        top: 0px;
        left: 0; }
    body:not(.recogido) .header-nav-top #main-menu .ul-menu {
      display: block;
      position: relative;
      padding-left: 15px; }
      body:not(.recogido) .header-nav-top #main-menu .ul-menu li {
        list-style: none;
        margin-bottom: 16px; } }

@media (min-width: 769px) {
  .djv_carouselh {
    position: relative; }
    .djv_carouselh > * {
      overflow: hidden;
      position: relative; }
    .djv_carouselh .djv_carouselh-left-arrow,
    .djv_carouselh .djv_carouselh-right-arrow {
      position: absolute;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
      font-size: 36px;
      width: 50px;
      height: 100%;
      cursor: pointer;
      background-color: transparent; }
      .djv_carouselh .djv_carouselh-left-arrow::before,
      .djv_carouselh .djv_carouselh-right-arrow::before {
        position: absolute;
        top: 50%;
        transform: translateY(-70%);
        height: 40px;
        width: 40px;
        padding: 13px 4px 20px 14px;
        color: #444444;
        font-weight: normal;
        font-size: 28px;
        display: inline-block;
        font-family: "comunes";
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .djv_carouselh .djv_carouselh-left-arrow {
      left: 0px; }
      .djv_carouselh .djv_carouselh-left-arrow::before {
        left: 0px;
        content: '\004b'; }
    .djv_carouselh .djv_carouselh-right-arrow {
      right: 0px; }
      .djv_carouselh .djv_carouselh-right-arrow::before {
        right: 0px;
        content: '\004c'; }
    .djv_carouselh .djv_carouselh-viewer {
      position: relative;
      width: calc(100% - 80px);
      margin: 0 auto; }
      .djv_carouselh .djv_carouselh-viewer > * {
        text-align: left;
        position: absolute;
        transition: left 1s; }
        .djv_carouselh .djv_carouselh-viewer > * > * {
          margin: 0;
          padding: 5px;
          float: left !important;
          clear: none; }
          .djv_carouselh .djv_carouselh-viewer > * > * .product-container {
            border-bottom: 0; } }

/* ###  Carousel de marcas ### */
.djv-troncal-marcasdestacadas-carousel ul {
  text-align: left;
  position: absolute;
  transition: left 1s; }
  .djv-troncal-marcasdestacadas-carousel ul > li {
    display: inline-block;
    width: 120px;
    min-height: 100px;
    margin: 0;
    padding: 5px 16px; }
    .djv-troncal-marcasdestacadas-carousel ul > li img {
      width: 100%; }

#carouselMarcas .carouselh-brands {
  text-align: left;
  position: absolute;
  transition: left 1s;
  display: flex;
  align-items: baseline; }
  @media (max-width: 768px) {
    #carouselMarcas .carouselh-brands {
      position: relative; } }
  #carouselMarcas .carouselh-brands .brands-miniature {
    display: inline-block !important;
    width: 120px;
    min-width: 120px;
    min-height: 120px;
    margin: 0;
    padding: 5px 10px;
    float: left; }
    @media (max-width: 768px) {
      #carouselMarcas .carouselh-brands .brands-miniature {
        width: 80px;
        min-width: 80px;
        height: 80px;
        min-height: 80px; } }
    #carouselMarcas .carouselh-brands .brands-miniature img {
      width: 100%;
      vertical-align: middle; }

#carouselMarcas .djv_carouselh-viewer {
  position: relative; }
  #carouselMarcas .djv_carouselh-viewer .djv_carouselh-left-arrow,
  #carouselMarcas .djv_carouselh-viewer .djv_carouselh-right-arrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    width: 44px;
    height: 100%;
    cursor: pointer;
    background-color: white; }
    @media (max-width: 768px) {
      #carouselMarcas .djv_carouselh-viewer .djv_carouselh-left-arrow,
      #carouselMarcas .djv_carouselh-viewer .djv_carouselh-right-arrow {
        background-color: #84b3d1;
        width: 24px;
        height: 34px;
        font-size: 32px;
        box-shadow: none; } }
    #carouselMarcas .djv_carouselh-viewer .djv_carouselh-left-arrow::before,
    #carouselMarcas .djv_carouselh-viewer .djv_carouselh-right-arrow::before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: 40px; }
      @media (max-width: 768px) {
        #carouselMarcas .djv_carouselh-viewer .djv_carouselh-left-arrow::before,
        #carouselMarcas .djv_carouselh-viewer .djv_carouselh-right-arrow::before {
          color: white;
          font-size: 22px; } }
  #carouselMarcas .djv_carouselh-viewer .djv_carouselh-left-arrow {
    left: 0;
    box-shadow: none; }
    @media (max-width: 768px) {
      #carouselMarcas .djv_carouselh-viewer .djv_carouselh-left-arrow {
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px; } }
    #carouselMarcas .djv_carouselh-viewer .djv_carouselh-left-arrow::before {
      left: 12px;
      content: '\0058';
      display: inline-block;
      font-family: 'comunes';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
      @media (max-width: 768px) {
        #carouselMarcas .djv_carouselh-viewer .djv_carouselh-left-arrow::before {
          font-size: 16px;
          left: 0; } }
  #carouselMarcas .djv_carouselh-viewer .djv_carouselh-right-arrow {
    right: 0;
    box-shadow: none; }
    @media (max-width: 768px) {
      #carouselMarcas .djv_carouselh-viewer .djv_carouselh-right-arrow {
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px; } }
    #carouselMarcas .djv_carouselh-viewer .djv_carouselh-right-arrow::before {
      right: 12px;
      content: '\0059';
      display: inline-block;
      font-family: 'comunes';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
      @media (max-width: 768px) {
        #carouselMarcas .djv_carouselh-viewer .djv_carouselh-right-arrow::before {
          font-size: 16px;
          right: 0; } }

@media (max-width: 768px) {
  #carouselMarcas .carouselh-brands {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    -webkit-scroll-snap-type: mandatory;
    scroll-snap-type: x mandatory;
    -webkit-scroll-snap-points-x: repeat(100%);
    scroll-snap-points-x: repeat(100%); }
    #carouselMarcas .carouselh-brands .brands-miniature {
      scroll-snap-align: start;
      position: relative;
      display: inline-block;
      margin-right: -4px;
      text-align: center;
      float: none; } }

/* ### fin Carousel de marcas ### */
@media (max-width: 768px) {
  .djv_carouselh.swipe::after {
    display: inline-block;
    font-family: 'comunes';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\006e';
    position: relative;
    left: 50%;
    top: -15px;
    transform: translateX(-50%);
    margin: 10px 0;
    font-size: 42px;
    color: #999999; }
  .djv_carouselh > * {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    -webkit-scroll-snap-type: mandatory;
    scroll-snap-type: x mandatory;
    -webkit-scroll-snap-points-x: repeat(100%);
    scroll-snap-points-x: repeat(100%); }
    .djv_carouselh > * > * {
      scroll-snap-align: start;
      width: 100%;
      position: relative;
      display: inline-block;
      margin-right: -4px;
      text-align: center; }
      .djv_carouselh > * > * .product-list-actions {
        margin-top: 0; } }

#index h2 {
  margin: 16px auto 45px auto;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-size: 28px;
  color: black; }
  @media (max-width: 768px) {
    #index h2 {
      font-size: 20px; } }

@media (max-width: 768px) {
  #index section {
    margin-top: 5px;
    margin-bottom: 5px; } }

#index section .full-width, #index section #module-djv_navigation-nsearch #category-description h1, #module-djv_navigation-nsearch #category-description #index section h1, #index section #category #category-description h1, #category #category-description #index section h1 {
  padding: 15px 0;
  background-repeat: no-repeat;
  background-size: cover; }

#index #main, #index #content {
  margin-bottom: 0; }

#index #footer {
  margin-top: 42px; }

#index .propuestas-valor {
  width: 100vw;
  display: block;
  position: relative;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 10px;
  margin-bottom: 25px;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 40px;
  text-align: center; }
  @media (max-width: 768px) {
    #index .propuestas-valor {
      padding: 16px;
      overflow-x: scroll; } }
  #index .propuestas-valor .djv-container {
    display: flex; }
    @media (max-width: 768px) {
      #index .propuestas-valor .djv-container {
        display: block;
        max-width: 100vw; } }
    #index .propuestas-valor .djv-container article {
      padding: 0px; }
      @media (max-width: 768px) {
        #index .propuestas-valor .djv-container article {
          margin-bottom: 15px;
          border-right: 0; } }
      #index .propuestas-valor .djv-container article img {
        display: block;
        width: 69px;
        margin: 0px auto; }
      #index .propuestas-valor .djv-container article p {
        font-family: "Lato", sans-serif;
        font-weight: 400;
        font-size: 16px; }

#index .destacado-portada {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  text-align: center;
  display: block;
  padding-top: 0;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 54px;
  margin-bottom: 35px; }
  #index .destacado-portada h2 {
    margin-bottom: 30px; }
  #index .destacado-portada article {
    background-color: #e1eef7;
    padding: 54px 20px 30px;
    width: 480px;
    text-align: center; }
    @media (max-width: 768px) {
      #index .destacado-portada article {
        max-width: 100vw;
        padding: 30px 15px; } }
  #index .destacado-portada h5 {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 26px;
    text-transform: uppercase; }
  #index .destacado-portada p {
    font-size: 20px; }
  #index .destacado-portada .btn-ver {
    background-color: #015f9b;
    color: #93ccfa;
    border: 2px solid #015f9b;
    border-radius: 0;
    transition: all 500ms;
    text-align: center;
    text-transform: uppercase;
    padding: 6px 16px;
    cursor: pointer;
    font-size: 18px;
    text-transform: lowercase;
    display: block;
    margin: 13px auto;
    width: 150px; }
    #index .destacado-portada .btn-ver:hover {
      background-color: #93ccfa;
      color: #015f9b; }

#index .destacado-portada2 {
  display: flex; }
  @media (max-width: 768px) {
    #index .destacado-portada2 {
      display: block; } }
  #index .destacado-portada2 h5 {
    font-family: "Lato", sans-serif;
    font-weight: 900;
    font-size: 40px;
    margin-bottom: 0;
    margin-bottom: 9px;
    line-height: 36px; }
  #index .destacado-portada2 p {
    font-size: 16px; }
  #index .destacado-portada2 article {
    flex: 1;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 165px 30px 50px;
    color: white;
    text-align: center; }
    @media (max-width: 768px) {
      #index .destacado-portada2 article {
        padding-top: 70px;
        padding-bottom: 70px; } }
    #index .destacado-portada2 article:nth-of-type(1) {
      margin-right: 5px;
      background-image: url("https://www.seleccionatolon.com/themes/base/assets/img/bloque01.jpg"); }
      @media (max-width: 768px) {
        #index .destacado-portada2 article:nth-of-type(1) {
          margin-right: 0; } }
      #index .destacado-portada2 article:nth-of-type(1) .btn-ver {
        color: #93ccfa;
        display: block;
        text-transform: lowercase;
        margin-top: 20px; }
    #index .destacado-portada2 article:nth-of-type(2) {
      margin-left: 5px;
      background-image: url("https://www.seleccionatolon.com/themes/base/assets/img/bloque02.jpg"); }
      @media (max-width: 768px) {
        #index .destacado-portada2 article:nth-of-type(2) {
          margin-left: 0; } }
      #index .destacado-portada2 article:nth-of-type(2) .contacto {
        margin-top: 10px;
        display: block;
        color: #93ccfa;
        font-weight: 600; }
        #index .destacado-portada2 article:nth-of-type(2) .contacto a {
          color: #93ccfa;
          margin-right: 10px; }
    #index .destacado-portada2 article:nth-of-type(3) {
      margin-left: 5px;
      background-image: url("https://www.seleccionatolon.com/themes/base/assets/img/bloque03.jpg"); }
      @media (max-width: 768px) {
        #index .destacado-portada2 article:nth-of-type(3) {
          margin-left: 0; } }

@media (max-width: 768px) {
  .carousel-marcas {
    display: none; } }

.carousel-marcas #carouselMarcas [class*='icon-caret-'] {
  border: 0 !important; }
  .carousel-marcas #carouselMarcas [class*='icon-caret-']::before {
    top: 55%; }

.carousel-marcas #carouselMarcas .djv_carouselh-right-arrow::before {
  right: 6px; }

.carousel-marcas #carouselMarcas .djv_carouselh-left-arrow::before {
  left: 6px; }

.carousel-marcas #carouselMarcas .carouselh-brands {
  text-align: left;
  position: absolute;
  transition: left 1s;
  padding-left: 0; }
  .carousel-marcas #carouselMarcas .carouselh-brands > * {
    display: inline-block !important;
    width: 195px;
    min-width: 195px;
    min-height: 195px;
    margin: 0;
    padding: 5px;
    float: left !important;
    transition: all 500ms; }
    .carousel-marcas #carouselMarcas .carouselh-brands > * img {
      max-width: 190px;
      padding: 10px; }

.carousel-marcas .vertodas {
  background-color: #dbebf5;
  color: white;
  border: 2px solid #dbebf5;
  border-radius: 30px;
  transition: all 500ms;
  text-align: center;
  text-transform: uppercase;
  padding: 15px 15px;
  cursor: pointer;
  display: block;
  width: 320px;
  margin: auto;
  font-size: 16px; }
  .carousel-marcas .vertodas:hover {
    background-color: white;
    color: #dbebf5; }

.cart-preview.cart-overview {
  width: 100%;
  position: inherit; }

.cart-preview.cart-overview .body {
  display: block;
  position: inherit;
  width: 100%;
  color: #333333; }

.cart-preview {
  float: right;
  position: relative; }
  .cart-preview .header {
    display: block;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    height: 35px;
    padding: 0 5px 0 5px; }
    .cart-preview .header i {
      font-size: 26px;
      display: block;
      line-height: 13px;
      padding-top: 10px;
      color: #015f9b; }
      @media (min-width: 769px) {
        .cart-preview .header i::before {
          font-family: "comunes";
          content: '\0074'; } }
    .cart-preview .header span {
      position: absolute;
      top: 5px;
      right: -6px;
      display: block;
      width: 20px;
      height: 20px;
      padding: 3px 6px 3px 3px;
      border-radius: 40px;
      background-color: #93ccfa;
      font-family: "Lato", sans-serif;
      font-weight: 900;
      font-size: 11px;
      color: white;
      text-align: center; }
  .cart-preview .body {
    display: none;
    right: 0;
    top: 38px;
    width: calc(100vw - 1.1rem);
    padding: 15px;
    background-color: white;
    box-shadow: 1px 12px 9px rgba(0, 0, 0, 0.4);
    color: black; }
    .cart-preview .body:hover {
      display: block;
      position: absolute; }
    .cart-preview .body > ul {
      padding-left: 0; }
      .cart-preview .body > ul li {
        list-style: none;
        padding: 4px 7px;
        display: grid;
        grid-template-columns: 8% 60% 26% 6%;
        border-bottom: 1px solid #EAEAEA;
        padding: 10px 0; }
        .cart-preview .body > ul li:last-of-type {
          border-bottom: 0px; }
        .cart-preview .body > ul li:hover {
          background-color: white; }
        .cart-preview .body > ul li .product-quantity {
          color: #015f9b;
          padding-top: 0px;
          border-bottom: 0 !important; }
        .cart-preview .body > ul li .product-quantity::after {
          content: 'x'; }
        .cart-preview .body > ul li .product-name {
          text-align: left;
          padding-left: 5px; }
        .cart-preview .body > ul li .product-price {
          text-align: right;
          margin-right: 5px; }
        .cart-preview .body > ul li .remove-from-cart {
          text-align: right;
          color: #015f9b;
          font-size: 10px; }
    .cart-preview .body .cart-subtotals {
      border-top: 1px dashed #cccccc;
      padding-top: 10px;
      margin-top: 10px;
      text-align: right;
      padding-right: 10px; }
      .cart-preview .body .cart-subtotals > * {
        display: grid;
        grid-template-columns: 72% 28%;
        text-align: right; }
      .cart-preview .body .cart-subtotals .value {
        font-family: "Lato", sans-serif;
        font-weight: 900; }
      .cart-preview .body .cart-subtotals .products {
        text-align: right; }
      .cart-preview .body .cart-subtotals small {
        font-size: 10px;
        color: #999999;
        margin-left: 4px; }
    @media (min-width: 576px) {
      .cart-preview .body {
        width: 400px; } }
    .cart-preview .body .blockcart-finalizar {
      background-color: #93ccfa;
      color: white;
      border: 2px solid #93ccfa;
      border-radius: 0px;
      transition: all 500ms;
      text-align: center;
      text-transform: uppercase;
      padding: 10px 20px;
      cursor: pointer;
      margin: 20px auto 0px;
      display: block;
      width: 100%;
      text-transform: uppercase; }
      .cart-preview .body .blockcart-finalizar:hover {
        background-color: white;
        color: #93ccfa; }
  .cart-preview:hover .body {
    display: block;
    position: absolute;
    z-index: 999; }

/* this is to make the containers take up vertical space */
.cart-preview .cart-total > div::after,
.cart-preview .header::after {
  clear: both;
  content: "\00a0";
  font-family: "comunes"; }

.cart-preview .cart-total .label::after {
  content: ": "; }

.cart-preview .cart-total > div {
  clear: both;
  border-bottom: 1px solid #ccc; }

.cart-preview .cart-total > div:not(:last-child) {
  margin-bottom: 5px; }

.cart-preview .cart-total {
  font-weight: bold;
  border-top: 1px solid #cccccc;
  padding-top: 10px;
  margin-top: 10px;
  text-align: right;
  padding-right: 10px; }
  .cart-preview .cart-total span {
    font-size: 22px; }

/*
.recogido{
    .cart-preview{
        .header{
            background-color: transparent;
            span{
                color: black;
            }
            &::after{
                color: black;
            }
            i{
                color: black;
            }
        }
    }
}*/
body#cart #notifications {
  display: block;
  margin-left: auto;
  margin-right: auto; }

body#cart #content-wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px; }
  @media (max-width: 1024px) {
    body#cart #content-wrapper {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    body#cart #content-wrapper {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    body#cart #content-wrapper {
      max-width: 500px;
      min-width: 300px; } }
  body#cart #content-wrapper .djv-breadcrumb {
    width: 100vw;
    display: block;
    position: relative;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-bottom: 20px; }
  body#cart #content-wrapper #main {
    display: grid;
    grid-template-columns: 75% 25%;
    gap: 0; }
    @media (max-width: 1024px) {
      body#cart #content-wrapper #main {
        display: block; } }
    body#cart #content-wrapper #main .continue-shopping {
      background-color: #015f9b;
      color: white;
      border: 2px solid #015f9b;
      border-radius: 50px;
      transition: all 500ms;
      text-align: center;
      text-transform: uppercase;
      padding: 10px 15px;
      cursor: pointer;
      width: 280px;
      margin-left: inherit;
      margin-top: 10px;
      position: relative; }
      body#cart #content-wrapper #main .continue-shopping:hover {
        background-color: white;
        color: #015f9b; }
      @media (max-width: 1024px) {
        body#cart #content-wrapper #main .continue-shopping {
          display: block;
          margin-left: auto;
          margin-right: auto;
          margin-top: 20px; } }
      body#cart #content-wrapper #main .continue-shopping i {
        position: absolute;
        left: 10px;
        top: 10px; }
    @media (max-width: 1024px) {
      body#cart #content-wrapper #main > div {
        box-shadow: 2px 2px 8px rgba(0, 0, 0, 0); } }
    body#cart #content-wrapper #main .cart-overview {
      margin-right: 25px; }
      @media (max-width: 1024px) {
        body#cart #content-wrapper #main .cart-overview {
          margin-right: 0; } }
      body#cart #content-wrapper #main .cart-overview h1 {
        padding: 16px 0;
        border-bottom: 1px solid white;
        text-transform: uppercase;
        font-size: 23px; }
      body#cart #content-wrapper #main .cart-overview .body {
        padding: 15px 0px; }
        body#cart #content-wrapper #main .cart-overview .body ul {
          padding-left: 0; }
        body#cart #content-wrapper #main .cart-overview .body .cart-header-items {
          list-style: none;
          display: grid;
          grid-template-columns: 65% 35%;
          border-bottom: 1px solid #cccccc;
          color: #666666; }
          body#cart #content-wrapper #main .cart-overview .body .cart-header-items .cart-header-items-total {
            text-align: right;
            margin-right: 50px; }
        body#cart #content-wrapper #main .cart-overview .body .cart-item {
          list-style: none;
          display: grid;
          grid-template-columns: 65% 35%;
          gap: 16px;
          padding-bottom: 16px;
          border-bottom: 1px dashed #dddddd;
          padding-top: 16px;
          font-size: 18px; }
          @media (max-width: 1024px) {
            body#cart #content-wrapper #main .cart-overview .body .cart-item {
              display: block;
              margin-bottom: 20px; } }
          body#cart #content-wrapper #main .cart-overview .body .cart-item a {
            color: inherit; }
          body#cart #content-wrapper #main .cart-overview .body .cart-item .secction-label {
            font-family: "Lato", sans-serif;
            font-weight: 400;
            font-size: 14px; }
          body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail {
            display: grid;
            grid-template-columns: 30% 70%;
            gap: 16px; }
            @media (max-width: 1024px) {
              body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail {
                display: block; } }
            @media (max-width: 1024px) {
              body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .cart-detailed-product-line-detail-image {
                float: left;
                width: 40%;
                margin-right: 15px;
                margin-bottom: 15px; } }
            body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .cart-detailed-product-line-detail-image img {
              width: 100%; }
            body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .cart-detailed-product-line-detail-tex > span {
              display: block; }
            body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .cart-detailed-product-line-detail-tex .product-price {
              font-family: "Lato", sans-serif;
              font-weight: 900;
              font-size: 20px; }
              body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .cart-detailed-product-line-detail-tex .product-price small {
                font-family: "Lato", sans-serif;
                font-weight: 300;
                font-size: 10px; }
                @media (max-width: 1024px) {
                  body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .cart-detailed-product-line-detail-tex .product-price small {
                    display: block; } }
            body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .cart-detailed-product-line-detail-tex .product-discount {
              position: relative; }
              body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .cart-detailed-product-line-detail-tex .product-discount .regular-price {
                text-decoration: line-through;
                color: #cccccc; }
              body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .cart-detailed-product-line-detail-tex .product-discount .discount-percentage {
                left: -60px; }
                @media (max-width: 1024px) {
                  body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .cart-detailed-product-line-detail-tex .product-discount .discount-percentage {
                    top: -80px;
                    left: 80px; } }
            @media (max-width: 576px) {
              body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .cart-detailed-product-line-detail-tex .cart-resume-selected-attributes {
                display: block;
                clear: both; } }
            body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .cart-detailed-product-line-detail-tex .cart-resume-selected-attributes .cart-resume-attributes {
              margin-left: 20px;
              list-style: none;
              margin-bottom: 10px; }
              body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .cart-detailed-product-line-detail-tex .cart-resume-selected-attributes .cart-resume-attributes li {
                line-height: 12px;
                font-size: 11px; }
                body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .cart-detailed-product-line-detail-tex .cart-resume-selected-attributes .cart-resume-attributes li label {
                  margin-bottom: 0; }
            body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .personalize-miniatures {
              position: relative; }
              @media (max-width: 576px) {
                body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .personalize-miniatures {
                  display: block; } }
              body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .personalize-miniatures .miniatura {
                position: relative;
                display: inline-block; }
              body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .personalize-miniatures .mini {
                width: 80px;
                height: 80px;
                cursor: pointer; }
                body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .personalize-miniatures .mini:hover {
                  border: 1px solid #999999; }
              body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .personalize-miniatures .ampliada {
                display: none; }
                body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .personalize-miniatures .ampliada::before {
                  width: 0;
                  height: 0;
                  border-right: 20px solid #aaa;
                  border-top: 15px solid transparent;
                  border-bottom: 15px solid transparent;
                  content: "";
                  position: absolute;
                  top: 50%;
                  left: -20px;
                  transform: translateY(-50%); }
              body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .personalize-miniatures .mini:hover + .ampliada {
                display: block;
                position: absolute;
                width: 250px;
                height: 250px;
                top: -105%;
                left: 90px;
                border: 1px solid #aaa;
                z-index: 2;
                box-shadow: 1px 1px 3px #999; }
                body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .personalize-miniatures .mini:hover + .ampliada img {
                  width: 100%; }
          body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-modify {
            display: grid;
            grid-template-columns: calc(45% - 15px) 45% 15px;
            text-align: center; }
            @media (max-width: 1024px) {
              body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-modify {
                margin-top: 5px;
                padding-top: 15px;
                border-top: 1px dashed #cccccc;
                clear: both; } }
            body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-modify .totalUnidades {
              font-size: 15px;
              margin-top: 3px; }
            body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-modify .product-price {
              text-align: center;
              font-size: 20px; }
            body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-modify .cart-edit-personalization {
              display: none;
              padding-top: 10px;
              color: #015f9b;
              font-size: 11px; }
              body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-modify .cart-edit-personalization::before {
                font-size: 12px; }
            body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-modify .djv-icon-delete {
              text-align: center;
              font-size: 16px;
              color: red;
              padding-top: 4px; }
            body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-modify .product-quantity {
              border-bottom: 0;
              padding-top: 0; }
              body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-modify .product-quantity .product-add-to-cart-quantity {
                width: 135px;
                font-size: 14px; }
                body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-modify .product-quantity .product-add-to-cart-quantity #quantity_wanted {
                  width: 55px; }
              body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-modify .product-quantity #quantity_wanted {
                font-size: 16px; }
    body#cart #content-wrapper #main .cart-detailed-right-side #items-subtotal {
      overflow: auto;
      border: 1px solid #dddddd;
      border-top: none; }
      body#cart #content-wrapper #main .cart-detailed-right-side #items-subtotal > * {
        padding: 8px 16px; }
      body#cart #content-wrapper #main .cart-detailed-right-side #items-subtotal .cart-title {
        background-color: #dddddd;
        color: black;
        text-transform: uppercase;
        font-size: 12px; }
      body#cart #content-wrapper #main .cart-detailed-right-side #items-subtotal .js-subtotal {
        float: left; }
      body#cart #content-wrapper #main .cart-detailed-right-side #items-subtotal small {
        font-size: 10px;
        color: #999999; }
      body#cart #content-wrapper #main .cart-detailed-right-side #items-subtotal span:not(.js-subtotal) {
        text-align: right;
        display: inline-block; }
        body#cart #content-wrapper #main .cart-detailed-right-side #items-subtotal span:not(.js-subtotal):last-of-type {
          float: right; }
    body#cart #content-wrapper #main .cart-detailed-right-side .djv-cart-promo {
      font-size: 11px;
      margin-top: 10px;
      padding: 8px 16px;
      border-bottom: 1px solid #dddddd; }
      body#cart #content-wrapper #main .cart-detailed-right-side .djv-cart-promo .djv-icon-calidad::before {
        margin-right: 8px; }
      body#cart #content-wrapper #main .cart-detailed-right-side .djv-cart-promo .djv-cart-promo-item {
        display: grid;
        grid-template-columns: 50% 40% 10%;
        color: #015f9b; }
        body#cart #content-wrapper #main .cart-detailed-right-side .djv-cart-promo .djv-cart-promo-item .djv-icon-close {
          color: #D53128; }
    body#cart #content-wrapper #main .cart-detailed-right-side .cart-detailed-totals {
      border: 1px solid #dddddd;
      border-top: none; }
      body#cart #content-wrapper #main .cart-detailed-right-side .cart-detailed-totals .cart-subtotals {
        padding: 8px 16px;
        border-bottom: 1px solid #dddddd; }
        body#cart #content-wrapper #main .cart-detailed-right-side .cart-detailed-totals .cart-subtotals .products {
          display: none; }
        body#cart #content-wrapper #main .cart-detailed-right-side .cart-detailed-totals .cart-subtotals .shipping {
          display: grid;
          grid-template-columns: auto auto;
          padding-bottom: 10px;
          margin-bottom: 10px;
          grid-gap: 0 10px; }
          body#cart #content-wrapper #main .cart-detailed-right-side .cart-detailed-totals .cart-subtotals .shipping .label {
            font-size: 11px; }
            body#cart #content-wrapper #main .cart-detailed-right-side .cart-detailed-totals .cart-subtotals .shipping .label small {
              display: block;
              color: #777777; }
          body#cart #content-wrapper #main .cart-detailed-right-side .cart-detailed-totals .cart-subtotals .shipping .value {
            text-align: right; }
            body#cart #content-wrapper #main .cart-detailed-right-side .cart-detailed-totals .cart-subtotals .shipping .value .shipping-link {
              display: block; }
              body#cart #content-wrapper #main .cart-detailed-right-side .cart-detailed-totals .cart-subtotals .shipping .value .shipping-link a {
                color: #444444;
                font-size: 9px; }
            body#cart #content-wrapper #main .cart-detailed-right-side .cart-detailed-totals .cart-subtotals .shipping .value small {
              font-size: 10px; }
        body#cart #content-wrapper #main .cart-detailed-right-side .cart-detailed-totals .cart-subtotals .discount {
          display: grid;
          grid-template-columns: auto auto;
          padding-bottom: 10px;
          margin-bottom: 10px; }
          body#cart #content-wrapper #main .cart-detailed-right-side .cart-detailed-totals .cart-subtotals .discount .value {
            text-align: right; }
            body#cart #content-wrapper #main .cart-detailed-right-side .cart-detailed-totals .cart-subtotals .discount .value::before {
              content: "-"; }
        body#cart #content-wrapper #main .cart-detailed-right-side .cart-detailed-totals .cart-subtotals .tax {
          display: grid;
          grid-template-columns: auto auto;
          padding-bottom: 10px;
          margin-bottom: 10px; }
          body#cart #content-wrapper #main .cart-detailed-right-side .cart-detailed-totals .cart-subtotals .tax .value {
            text-align: right; }
      body#cart #content-wrapper #main .cart-detailed-right-side .cart-detailed-totals .cart-total {
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 10px;
        align-items: center;
        font-size: 14px;
        padding: 8px 16px;
        background-color: #015f9b;
        color: white; }
        body#cart #content-wrapper #main .cart-detailed-right-side .cart-detailed-totals .cart-total .label {
          text-align: left;
          font-size: 12px; }
        body#cart #content-wrapper #main .cart-detailed-right-side .cart-detailed-totals .cart-total .value {
          text-align: right;
          font-size: 26px;
          font-weight: 700; }
    body#cart #content-wrapper #main .cart-detailed-right-side .payin_calculator {
      margin-top: 12px; }
    body#cart #content-wrapper #main .cart-detailed-right-side .tramitar-pedido-btn {
      background-color: #1a7e10;
      color: white;
      border: 2px solid #1a7e10;
      border-radius: 50px;
      transition: all 500ms;
      text-align: center;
      text-transform: uppercase;
      padding: 20px 5px;
      cursor: pointer;
      margin: 10px auto;
      display: block;
      width: 100%;
      font-size: 16px;
      border-radius: 30px;
      padding: 10px;
      text-decoration: none; }
      body#cart #content-wrapper #main .cart-detailed-right-side .tramitar-pedido-btn:hover {
        background-color: white;
        color: #1a7e10; }
    body#cart #content-wrapper #main .cart-detailed-right-side #block-reassurance {
      padding: 16px;
      border-bottom: 1px solid #dddddd; }
      body#cart #content-wrapper #main .cart-detailed-right-side #block-reassurance ul {
        padding-left: 30px; }
        body#cart #content-wrapper #main .cart-detailed-right-side #block-reassurance ul li {
          list-style: none;
          margin-bottom: 16px; }
          body#cart #content-wrapper #main .cart-detailed-right-side #block-reassurance ul li img {
            margin-left: -30px;
            filter: sepia(100%) brightness(310%) hue-rotate(60deg) saturate(300%); }

body#cart.cart-empty .checkout {
  display: none; }

body#cart.cart-empty .cart-overview .body::after {
  content: "No hay articulos seleccionados"; }

.cart-voucher {
  text-align: center;
  border-top: 1px solid #cccccc;
  margin-top: 10px;
  padding-top: 10px; }
  .cart-voucher .vouchers-title, .cart-voucher .added-vouchers-title {
    display: block;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer; }
  .cart-voucher #vouchers-form {
    height: 0;
    opacity: 0;
    display: none; }
  .cart-voucher .show-vouchers {
    display: block;
    animation-name: showvouchers;
    animation-duration: 1s;
    animation-fill-mode: forwards; }

@keyframes showvouchers {
  from {
    height: 0;
    opacity: 0; }
  to {
    height: 100%;
    opacity: 1; } }
  .cart-voucher input[name="discount_name"] {
    width: 160px;
    padding: 9px 10px 12px;
    border: 1px solid #cccccc;
    text-align: center;
    font-size: 12px; }
  .cart-voucher button {
    background-color: #015f9b;
    color: white;
    border: 2px solid #015f9b;
    border-radius: 0px;
    transition: all 500ms;
    text-align: center;
    text-transform: uppercase;
    padding: 6px 45px;
    cursor: pointer;
    float: right;
    font-size: 16px; }
    .cart-voucher button:hover {
      background-color: white;
      color: #015f9b; }
  .cart-voucher .vouchers-featured {
    margin-top: 16px; }
    .cart-voucher .vouchers-featured h4 {
      font-size: 14px; }
    .cart-voucher .vouchers-featured ul {
      padding-left: 0; }
      .cart-voucher .vouchers-featured ul li {
        padding: 5px 10px;
        list-style: none;
        font-size: 12px; }
        .cart-voucher .vouchers-featured ul li:nth-of-type(2n - 1) {
          background-color: #EAEAEA; }
        .cart-voucher .vouchers-featured ul li .code, .cart-voucher .vouchers-featured ul li .name {
          display: block;
          margin: 5px auto; }
        .cart-voucher .vouchers-featured ul li .code {
          color: #015f9b;
          cursor: pointer; }
  .cart-voucher #added_vouchers {
    list-style: none;
    padding-left: 0; }
    .cart-voucher #added_vouchers .added-voucher-name {
      background-color: #dbebf5;
      padding: 3px 15px 5px;
      margin-right: 15px; }
    .cart-voucher #added_vouchers .djv-icon-delete {
      color: black;
      font-size: 14px;
      text-decoration: none; }
      .cart-voucher #added_vouchers .djv-icon-delete::before {
        color: #D53128;
        font-size: 10px;
        margin-right: 5px; }
  .cart-voucher .djv-icon-question {
    font-size: 12px;
    color: #015f9b;
    margin-top: 16px;
    display: block; }
    .cart-voucher .djv-icon-question::before {
      margin-right: 5px; }
  .cart-voucher .notification {
    padding: 2px; }

.djv-breadcrumb {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding: 15px;
  background-color: #e1eef7; }
  .djv-breadcrumb ol {
    padding-left: 0;
    margin-bottom: 0;
    text-align: center; }
    .djv-breadcrumb ol li {
      display: inline-block;
      color: #015f9b; }
      .djv-breadcrumb ol li::after {
        content: '\004c';
        display: inline-block;
        font-family: 'comunes';
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin: 0 4px;
        font-size: 8px; }
      .djv-breadcrumb ol li a {
        color: #015f9b; }
      .djv-breadcrumb ol li:last-of-type a {
        color: #0a3855; }
      .djv-breadcrumb ol li:last-of-type::after {
        content: "";
        display: none; }

#product .product-first-section {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px;
  display: grid;
  grid-gap: 0px 20px;
  grid-template-columns: repeat(2, calc(50% - 10px));
  margin: 30px auto; }
  @media (max-width: 1024px) {
    #product .product-first-section {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #product .product-first-section {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #product .product-first-section {
      max-width: 500px;
      min-width: 300px; } }
  @media (max-width: 768px) {
    #product .product-first-section {
      display: block;
      padding-left: 10px;
      padding-right: 10px; } }
  #product .product-first-section .product-first-section-left .djv-product-images {
    grid-area: images;
    padding: 10px; }
    @media (max-width: 768px) {
      #product .product-first-section .product-first-section-left .djv-product-images {
        padding: 0; } }
    #product .product-first-section .product-first-section-left .djv-product-images .product-cover img {
      min-width: 360px; }
      @media (max-width: 1024px) {
        #product .product-first-section .product-first-section-left .djv-product-images .product-cover img {
          width: 250px;
          min-width: 250px;
          margin-left: auto;
          margin-right: auto; } }
    #product .product-first-section .product-first-section-left .djv-product-images .product-thumbnails {
      display: block;
      width: 100%;
      margin: auto; }
  #product .product-first-section .product-first-section-right #product-information-name {
    grid-area: name; }
    #product .product-first-section .product-first-section-right #product-information-name h1 {
      font-size: 22px;
      font-family: "Lato", sans-serif;
      font-weight: 700; }
      @media (max-width: 768px) {
        #product .product-first-section .product-first-section-right #product-information-name h1 {
          font-size: 22px; } }
  #product .product-first-section .product-first-section-right #product-reference {
    font-size: 11px;
    color: #aaaaaa;
    font-weight: normal; }
    #product .product-first-section .product-first-section-right #product-reference strong {
      font-weight: normal; }
  #product .product-first-section .product-first-section-right #product-description-short {
    grid-area: descriptionshort;
    font-family: "Lato", sans-serif;
    font-weight: 300;
    font-size: 16px; }
    #product .product-first-section .product-first-section-right #product-description-short p {
      margin-bottom: 16px; }
    #product .product-first-section .product-first-section-right #product-description-short .table-top {
      width: auto;
      margin: 10px 0;
      font-size: 14px; }
      #product .product-first-section .product-first-section-right #product-description-short .table-top header {
        width: 100%;
        background-color: #dbebf5;
        color: #015f9b;
        padding: 3px 5px;
        margin-bottom: 5px; }
        #product .product-first-section .product-first-section-right #product-description-short .table-top header h4 {
          font-size: 16px;
          margin-bottom: 0; }
      #product .product-first-section .product-first-section-right #product-description-short .table-top table {
        border: 1px solid #dddddd;
        width: 100%;
        border-collapse: collapse; }
        #product .product-first-section .product-first-section-right #product-description-short .table-top table td {
          border: 1px solid #dddddd;
          padding: 3px 5px;
          width: 50%; }
    #product .product-first-section .product-first-section-right #product-description-short .table-left {
      width: auto;
      margin: 10px 0;
      font-size: 14px; }
      #product .product-first-section .product-first-section-right #product-description-short .table-left table {
        width: 100%;
        border-collapse: collapse; }
        #product .product-first-section .product-first-section-right #product-description-short .table-left table tr td:nth-of-type(1) {
          font-weight: 500;
          background-color: #dbebf5;
          color: #015f9b;
          border: 1px solid white;
          width: calc(49%); }
        #product .product-first-section .product-first-section-right #product-description-short .table-left table tr td:nth-of-type(2) {
          width: calc(2%);
          border: 0px solid white; }
        #product .product-first-section .product-first-section-right #product-description-short .table-left table td {
          width: calc(49%);
          border: 1px solid #dddddd;
          padding: 3px 5px; }
    #product .product-first-section .product-first-section-right #product-description-short #sigue_leyendo {
      font-family: "Lato", sans-serif;
      font-weight: 700;
      font-size: 12px;
      color: #dbebf5;
      cursor: pointer;
      margin-top: 10px 0;
      display: block; }
      #product .product-first-section .product-first-section-right #product-description-short #sigue_leyendo:hover {
        text-decoration: underline; }
  #product .product-first-section .product-first-section-right .djv-product-detail-variants {
    grid-area: variants; }
  #product .product-first-section .product-first-section-right .product-buy-actions {
    grid-area: actions;
    clear: both;
    margin-bottom: 16px; }
    @media (max-width: 1024px) {
      #product .product-first-section .product-first-section-right .product-buy-actions {
        margin-left: auto;
        margin-right: auto; } }
    #product .product-first-section .product-first-section-right .product-buy-actions #add-to-cart-or-refresh {
      display: grid;
      grid-template-areas: "quantity price availabilityinfo" "variants variants variants" "addtocart addtocart addtocart";
      grid-template-columns: 175px 200px 200px;
      text-align: center;
      padding: 10px 0;
      border-top: 1px solid #cccccc;
      margin-top: 10px; }
      @media (max-width: 768px) {
        #product .product-first-section .product-first-section-right .product-buy-actions #add-to-cart-or-refresh {
          display: block; } }
      #product .product-first-section .product-first-section-right .product-buy-actions #add-to-cart-or-refresh .djv-variants-message {
        grid-area: variantsmsg; }
      #product .product-first-section .product-first-section-right .product-buy-actions #add-to-cart-or-refresh .etiqueta-oferta {
        width: 90px;
        padding: 5px 10px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0px;
        background-color: #e1eef7;
        font-size: 12px;
        color: white;
        text-align: center; }
      #product .product-first-section .product-first-section-right .product-buy-actions #add-to-cart-or-refresh .product-variants {
        grid-area: variants; }
      #product .product-first-section .product-first-section-right .product-buy-actions #add-to-cart-or-refresh .product-quantity {
        grid-area: quantity;
        align-self: center;
        display: grid;
        justify-content: center; }
      #product .product-first-section .product-first-section-right .product-buy-actions #add-to-cart-or-refresh .product-add-to-cart {
        grid-area: addtocart;
        padding-top: 16px; }
        #product .product-first-section .product-first-section-right .product-buy-actions #add-to-cart-or-refresh .product-add-to-cart .add-to-cart {
          background-color: #015f9b;
          color: white;
          border: 2px solid #015f9b;
          border-radius: 0px;
          transition: all 500ms;
          text-align: center;
          text-transform: uppercase;
          padding: 10px 30px;
          cursor: pointer;
          display: block;
          width: 50%;
          margin: 0 auto;
          font-size: 16px; }
          #product .product-first-section .product-first-section-right .product-buy-actions #add-to-cart-or-refresh .product-add-to-cart .add-to-cart:hover {
            background-color: white;
            color: #015f9b; }
      #product .product-first-section .product-first-section-right .product-buy-actions #add-to-cart-or-refresh .product-prices {
        grid-area: price;
        align-self: end;
        padding: 0; }
      #product .product-first-section .product-first-section-right .product-buy-actions #add-to-cart-or-refresh .djv-product-availability-info {
        grid-area: availabilityinfo;
        align-self: end;
        padding: 0; }
      #product .product-first-section .product-first-section-right .product-buy-actions #add-to-cart-or-refresh.atributtes-with-quantity {
        grid-template-areas: "textoUnidades textoUnidades textoUnidades" "variants price availabilityinfo" "variants addtocart addtocart";
        grid-template-columns: 250px 172px 146px; }
        #product .product-first-section .product-first-section-right .product-buy-actions #add-to-cart-or-refresh.atributtes-with-quantity .product-quantity {
          display: none; }
        #product .product-first-section .product-first-section-right .product-buy-actions #add-to-cart-or-refresh.atributtes-with-quantity .texto-unidades {
          grid-area: textoUnidades;
          text-align: left;
          font-size: 14px;
          color: #999999;
          padding-bottom: 16px; }
        #product .product-first-section .product-first-section-right .product-buy-actions #add-to-cart-or-refresh.atributtes-with-quantity .product-variants .product-variants-select {
          padding-right: 5px; }
        #product .product-first-section .product-first-section-right .product-buy-actions #add-to-cart-or-refresh.atributtes-with-quantity .product-add-to-cart {
          padding-top: 0; }
          #product .product-first-section .product-first-section-right .product-buy-actions #add-to-cart-or-refresh.atributtes-with-quantity .product-add-to-cart .add-to-cart {
            width: 94%;
            margin-left: 20px;
            margin-top: 10px; }
  #product .product-first-section .product-first-section-right #mostrarMas, #product .product-first-section .product-first-section-right #mostrarMenos {
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: #dbebf5;
    padding: 16px 0;
    margin-bottom: 10px;
    cursor: pointer; }
    #product .product-first-section .product-first-section-right #mostrarMas::before, #product .product-first-section .product-first-section-right #mostrarMenos::before {
      margin-right: 6px;
      font-size: 12px; }
  #product .product-first-section .product-first-section-right .djv-advertencia-descatalogado {
    text-align: center;
    font-size: 26px;
    color: #D53128; }

#product .product-second-section {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px;
  display: grid;
  grid-template-areas: "accesories accesories" "extended extended" "opiniones opiniones";
  grid-template-columns: calc(65% - 5px) calc(35% - 5px);
  grid-gap: 10px; }
  @media (max-width: 1024px) {
    #product .product-second-section {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #product .product-second-section {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #product .product-second-section {
      max-width: 500px;
      min-width: 300px; } }
  @media (max-width: 768px) {
    #product .product-second-section {
      display: block;
      width: auto;
      padding: 5px 10px; } }
  #product .product-second-section .product-information-extended {
    grid-area: extended; }
  #product .product-second-section .djv-product-opiniones {
    grid-area: opiniones; }
  #product .product-second-section .product-accessories {
    grid-area: accesories; }

#product .djv-product-opiniones {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px; }
  @media (max-width: 1024px) {
    #product .djv-product-opiniones {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #product .djv-product-opiniones {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #product .djv-product-opiniones {
      max-width: 500px;
      min-width: 300px; } }
  #product .djv-product-opiniones .djv-product-opinion {
    color: #333333; }
    #product .djv-product-opiniones .djv-product-opinion .coment-header .comment_image {
      display: none; }
    #product .djv-product-opiniones .djv-product-opinion .coment-header .comment_title {
      display: none; }
    #product .djv-product-opiniones .djv-product-opinion .coment-header .comment_author {
      font-size: 10px;
      margin-bottom: 3px; }

.djv-product-availability-info {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center; }
  .djv-product-availability-info small {
    display: block;
    font-size: 11px; }
  .djv-product-availability-info span {
    display: block;
    text-transform: uppercase;
    font-size: 14px; }
    .djv-product-availability-info span.djv-icon-clock::before {
      display: block; }
  .djv-product-availability-info p a {
    color: inherit;
    text-decoration: underline;
    text-align: center; }
  .djv-product-availability-info.level1 {
    color: #1a7e10; }
    .djv-product-availability-info.level1 a {
      color: #1a7e10; }
  .djv-product-availability-info.level2 {
    color: #ff7926; }
    .djv-product-availability-info.level2 a {
      color: #ff7926; }
  .djv-product-availability-info.level3 {
    color: #D53128; }
    .djv-product-availability-info.level3 a {
      color: #D53128; }

#product .product-prices .offer-flag {
  position: relative;
  width: 65px;
  display: inline-block;
  padding: 6px;
  background-color: #e1eef7;
  color: white; }
  #product .product-prices .offer-flag::before {
    content: "";
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    bottom: -10px;
    background-image: url("https://www.seleccionatolon.com/themes/base/assets/icons//triangle_down.svg"); }

#product .product-prices .product-discount {
  float: right;
  margin-bottom: 0px;
  margin-right: 11px;
  padding-top: 6px;
  text-align: right; }
  #product .product-prices .product-discount .regular-price {
    display: inline-block;
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-size: 14px;
    text-decoration: line-through;
    color: #666666; }

#product .product-prices .product-price {
  display: inline-block; }
  #product .product-prices .product-price .with-taxes {
    font-size: 40px;
    font-family: "Lato", sans-serif;
    font-weight: 700; }
    @media (max-width: 768px) {
      #product .product-prices .product-price .with-taxes {
        font-size: 26px; } }
    #product .product-prices .product-price .with-taxes small {
      font-size: 10px;
      font-family: "Lato", sans-serif;
      font-weight: 300;
      margin-top: 0px;
      color: #333333;
      line-height: 26px; }
      @media (max-width: 768px) {
        #product .product-prices .product-price .with-taxes small {
          font-size: 9px; } }
    #product .product-prices .product-price .with-taxes .decimales {
      font-size: 22px; }
  #product .product-prices .product-price .without-taxes {
    font-size: 28px;
    font-family: "Lato", sans-serif;
    font-weight: 700; }
    @media (max-width: 768px) {
      #product .product-prices .product-price .without-taxes {
        font-size: 26px; } }
    #product .product-prices .product-price .without-taxes small {
      display: inline-block;
      margin-top: 0px;
      font-size: 11px;
      font-family: "Lato", sans-serif;
      font-weight: 300;
      color: #666666;
      line-height: 26px; }
      @media (max-width: 768px) {
        #product .product-prices .product-price .without-taxes small {
          font-size: 9px; } }
    #product .product-prices .product-price .without-taxes .decimales {
      font-size: 11px; }
  #product .product-prices .product-price .advertiseWithoutTaxes {
    display: block;
    font-size: 10px;
    font-family: "Lato", sans-serif;
    font-weight: 300;
    line-height: 11px;
    margin-top: 0px;
    color: #999999;
    text-align: center; }
    @media (max-width: 768px) {
      #product .product-prices .product-price .advertiseWithoutTaxes {
        font-size: 9px; } }
  #product .product-prices .product-price.has-discount {
    margin-top: 5px; }
    #product .product-prices .product-price.has-discount .with-taxes {
      color: #e1eef7; }

#product .product-prices .discount-percentage, #product .product-prices .discount-amount {
  display: none; }

.product-miniature {
  position: relative;
  display: grid;
  height: 346px;
  grid-template-columns: auto;
  grid-template-areas: "imgBlock" "dataBlock";
  grid-template-rows: 213px 131px;
  margin-bottom: 30px;
  padding: 0 10px 0 0; }
  @media (max-width: 768px) {
    .product-miniature {
      border-bottom: none;
      display: block;
      height: auto; } }
  @media (max-width: 576px) {
    .product-miniature {
      padding: 5px;
      border-bottom: none; } }
  .product-miniature a {
    display: block; }
  .product-miniature .product-miniature-img-block {
    grid-area: imgBlock; }
    .product-miniature .product-miniature-img-block img {
      display: block;
      position: relative;
      width: 100%;
      max-width: 250px;
      margin-left: auto;
      margin-right: auto; }
  .product-miniature .product-miniature-data-block {
    grid-area: dataBlock;
    display: grid;
    grid-template-areas: "nameProd" "priceProd" "actionsProd";
    grid-template-columns: auto;
    grid-gap: 0px;
    align-items: start;
    grid-template-rows: max-content auto 40px; }
    @media (max-width: 768px) {
      .product-miniature .product-miniature-data-block {
        display: block; } }
    .product-miniature .product-miniature-data-block .name-product {
      grid-area: nameProd;
      display: block;
      margin-bottom: 0px;
      padding: 8px 0;
      font-family: "Lato", sans-serif;
      font-weight: 400;
      font-size: 18px;
      text-align: center; }
      @media (max-width: 768px) {
        .product-miniature .product-miniature-data-block .name-product {
          margin-bottom: 10px;
          white-space: normal; } }
      .product-miniature .product-miniature-data-block .name-product a {
        color: #015f9b; }
        @media (max-width: 768px) {
          .product-miniature .product-miniature-data-block .name-product a {
            white-space: normal; } }
    .product-miniature .product-miniature-data-block .product-description-short {
      display: none; }
    .product-miniature .product-miniature-data-block .product-prices {
      grid-area: priceProd;
      position: relative;
      text-align: center;
      padding-top: 0px; }
      .product-miniature .product-miniature-data-block .product-prices .aeuc_from_label {
        font-weight: 300;
        font-size: 12px; }
      .product-miniature .product-miniature-data-block .product-prices .with-taxes {
        font-size: 28px;
        font-family: "Lato", sans-serif;
        font-weight: 700;
        color: black; }
        @media (max-width: 768px) {
          .product-miniature .product-miniature-data-block .product-prices .with-taxes {
            font-size: 26px; } }
        .product-miniature .product-miniature-data-block .product-prices .with-taxes .decimales {
          font-size: 18px; }
          @media (max-width: 768px) {
            .product-miniature .product-miniature-data-block .product-prices .with-taxes .decimales {
              font-size: 14px; } }
        .product-miniature .product-miniature-data-block .product-prices .with-taxes small {
          display: none;
          font-size: 10px;
          font-family: "Lato", sans-serif;
          font-weight: 300;
          margin-top: 0px;
          color: #666666;
          line-height: 26px; }
          @media (max-width: 768px) {
            .product-miniature .product-miniature-data-block .product-prices .with-taxes small {
              font-size: 9px; } }
        .product-miniature .product-miniature-data-block .product-prices .with-taxes.has-discount {
          color: #015f9b; }
      .product-miniature .product-miniature-data-block .product-prices .regular-price {
        display: block;
        font-family: "Lato", sans-serif;
        font-weight: 100;
        font-size: 14px;
        text-decoration: line-through;
        text-align: center;
        color: #999999; }
      .product-miniature .product-miniature-data-block .product-prices .product-discount {
        display: inline-block;
        width: 49%;
        margin-bottom: 0;
        text-align: right; }
        .product-miniature .product-miniature-data-block .product-prices .product-discount .discount-percentage, .product-miniature .product-miniature-data-block .product-prices .product-discount .discount-amount {
          display: inline-block;
          min-height: auto;
          padding: 0 5px;
          background-color: #00ff00;
          border: 1px solid #c4c4c4;
          font-family: "Lato", sans-serif;
          font-weight: 400;
          font-size: 22px;
          color: white; }
        .product-miniature .product-miniature-data-block .product-prices .product-discount .discount-percentage {
          display: none; }
        .product-miniature .product-miniature-data-block .product-prices .product-discount .discount-amount {
          display: none; }
      .product-miniature .product-miniature-data-block .product-prices .discount-percentage, .product-miniature .product-miniature-data-block .product-prices .discount-amount {
        display: none; }
    .product-miniature .product-miniature-data-block .product-list-actions {
      grid-area: actionsProd;
      width: 100%;
      align-self: end; }
      .product-miniature .product-miniature-data-block .product-list-actions .add-to-cart, .product-miniature .product-miniature-data-block .product-list-actions .djv-ver-mas, .product-miniature .product-miniature-data-block .product-list-actions .djv-personalizar {
        background-color: #015f9b;
        color: #93ccfa;
        border: 2px solid #015f9b;
        border-radius: 0px;
        transition: all 500ms;
        text-align: center;
        text-transform: uppercase;
        padding: 2px 14px;
        cursor: pointer;
        display: block;
        max-width: 195px;
        margin: 0px auto;
        font-size: 20px;
        text-transform: lowercase;
        white-space: nowrap;
        text-decoration: none;
        padding-bottom: 5px; }
        .product-miniature .product-miniature-data-block .product-list-actions .add-to-cart:hover, .product-miniature .product-miniature-data-block .product-list-actions .djv-ver-mas:hover, .product-miniature .product-miniature-data-block .product-list-actions .djv-personalizar:hover {
          background-color: #93ccfa;
          color: #015f9b; }

@media (max-width: 768px) {
  #product .product-accessories {
    width: auto;
    float: none;
    padding: 0 15px;
    margin-top: 25px; } }

#product .product-accessories > h3 {
  margin-bottom: 30px;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-size: 26px;
  text-align: center;
  color: black; }

@media (max-width: 576px) {
  #product .product-accessories .product-accessories-container.djv_carouselh {
    padding: 0px; } }

@media (max-width: 768px) {
  #product .product-accessories .product-accessories-container.djv_carouselh .products {
    display: block;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    -webkit-scroll-snap-type: mandatory;
    scroll-snap-type: x mandatory;
    -webkit-scroll-snap-points-x: repeat(100%);
    scroll-snap-points-x: repeat(100%); } }

#product .product-accessories .product-accessories-container.djv_carouselh .products .product-miniature {
  display: inline-block;
  width: calc(20% - 10px);
  padding: 0 5px;
  float: none;
  margin-bottom: 25px;
  min-height: 350px; }
  @media (max-width: 768px) {
    #product .product-accessories .product-accessories-container.djv_carouselh .products .product-miniature {
      scroll-snap-align: start;
      width: 100%;
      position: relative;
      margin-right: -4px;
      text-align: center;
      grid-template-areas: "imagen name" "imagen priceshipping" "actions actions";
      grid-template-columns: 40% auto; } }
  #product .product-accessories .product-accessories-container.djv_carouselh .products .product-miniature .product-miniature-img-block {
    padding: 0 16px; }
  #product .product-accessories .product-accessories-container.djv_carouselh .products .product-miniature .product-miniature-data-block {
    padding: 0 16px; }

#product .product-information-extended {
  position: relative; }
  #product .product-information-extended #leermas {
    display: block; }
  #product .product-information-extended .product-information-extended-heading {
    padding: 5px 16px;
    border-bottom: 1px solid #cccccc;
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-size: 20px;
    color: #333333; }
  #product .product-information-extended .product-information-extended-description {
    padding: 16px;
    font-size: 16px; }
    @media (max-width: 768px) {
      #product .product-information-extended .product-information-extended-description {
        padding: 0; } }
    #product .product-information-extended .product-information-extended-description > h2, #product .product-information-extended .product-information-extended-description h3 {
      margin-top: 20px;
      margin-bottom: 20px;
      font-family: "Lato", sans-serif;
      font-weight: 700;
      text-align: left; }
      @media (max-width: 768px) {
        #product .product-information-extended .product-information-extended-description > h2, #product .product-information-extended .product-information-extended-description h3 {
          font-size: 16px; } }
    #product .product-information-extended .product-information-extended-description > p {
      margin-bottom: 16px; }
    #product .product-information-extended .product-information-extended-description a {
      color: #dbebf5;
      font-weight: bold; }
    #product .product-information-extended .product-information-extended-description img {
      width: 100%; }

#product .product-attachments {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px; }
  @media (max-width: 1024px) {
    #product .product-attachments {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #product .product-attachments {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #product .product-attachments {
      max-width: 500px;
      min-width: 300px; } }
  #product .product-attachments .product-attachments-heading {
    background-color: #EAEAEA;
    padding: 10px;
    font-family: "Lato", sans-serif;
    font-weight: 900;
    font-size: 22px;
    font-weight: normal;
    text-align: left;
    margin-top: 35px;
    color: black; }
    @media (max-width: 768px) {
      #product .product-attachments .product-attachments-heading {
        font-size: 14px; } }
    #product .product-attachments .product-attachments-heading::before {
      content: "";
      display: inline-block;
      margin-right: 10px;
      background-color: #dbebf5;
      width: 18px;
      height: 18px;
      border-radius: 50%; }
  #product .product-attachments .attachment {
    display: inline-grid;
    margin-right: 15px; }
    #product .product-attachments .attachment h4 a {
      font-family: "Lato", sans-serif;
      font-weight: 700;
      font-size: 16px; }
    #product .product-attachments .attachment > a {
      color: #dbebf5;
      margin-top: 5px; }

#module-djv_navigation-nsearch > #bannerSlider, #category > #bannerSlider {
  display: none; }

#module-djv_navigation-nsearch .djv-navigation-nsearch-header, #category .djv-navigation-nsearch-header {
  background-color: white; }

#module-djv_navigation-nsearch #category-description, #category #category-description {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  padding: 15px 0;
  color: black;
  text-align: center; }
  #module-djv_navigation-nsearch #category-description h1, #category #category-description h1 {
    display: block;
    font-family: "Lato", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: 0px;
    padding: 16px;
    background-color: #93ccfa;
    color: white; }
  #module-djv_navigation-nsearch #category-description a, #category #category-description a {
    color: #015f9b; }
  #module-djv_navigation-nsearch #category-description p, #category #category-description p {
    font-family: "Lato", sans-serif;
    font-weight: 100;
    font-size: 14px;
    line-height: 20px; }
    #module-djv_navigation-nsearch #category-description p span#sigue_leyendo, #category #category-description p span#sigue_leyendo {
      font-size: 14px;
      color: #015f9b;
      cursor: pointer; }
  #module-djv_navigation-nsearch #category-description .djv-product-list-count, #category #category-description .djv-product-list-count {
    position: absolute;
    top: 15px;
    right: 15px; }

#module-djv_navigation-nsearch #djv-two-columns-layer, #category #djv-two-columns-layer {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px;
  display: block;
  margin-top: 20px; }
  @media (max-width: 1024px) {
    #module-djv_navigation-nsearch #djv-two-columns-layer, #category #djv-two-columns-layer {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #module-djv_navigation-nsearch #djv-two-columns-layer, #category #djv-two-columns-layer {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #module-djv_navigation-nsearch #djv-two-columns-layer, #category #djv-two-columns-layer {
      max-width: 500px;
      min-width: 300px; } }
  #module-djv_navigation-nsearch #djv-two-columns-layer #left-column, #category #djv-two-columns-layer #left-column {
    display: none; }
  #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper, #category #djv-two-columns-layer #content-wrapper {
    position: relative; }
    #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper h1, #category #djv-two-columns-layer #content-wrapper h1 {
      display: block; }
      @media (max-width: 576px) {
        #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper h1, #category #djv-two-columns-layer #content-wrapper h1 {
          font-size: 22px; } }
    #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper #bannerSlider.carousel [class*="djv-icon-arrow-"]::before, #category #djv-two-columns-layer #content-wrapper #bannerSlider.carousel [class*="djv-icon-arrow-"]::before {
      background-color: transparent; }
    #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper #bannerSlider .carousel-indicator-container, #category #djv-two-columns-layer #content-wrapper #bannerSlider .carousel-indicator-container {
      display: none; }
  #module-djv_navigation-nsearch #djv-two-columns-layer #js-product-list-top, #category #djv-two-columns-layer #js-product-list-top {
    width: 100%;
    position: relative;
    padding: 0;
    margin-top: 15px;
    display: grid;
    grid-template-areas: "order pagination";
    grid-template-columns: 40% 60%; }
    @media (max-width: 768px) {
      #module-djv_navigation-nsearch #djv-two-columns-layer #js-product-list-top, #category #djv-two-columns-layer #js-product-list-top {
        display: block;
        border-bottom: 1px solid #999999;
        padding-bottom: 5px;
        margin-top: 0px;
        margin-bottom: 20px; } }
    #module-djv_navigation-nsearch #djv-two-columns-layer #js-product-list-top .products-sort-order, #category #djv-two-columns-layer #js-product-list-top .products-sort-order {
      grid-area: order;
      justify-self: left;
      margin-right: 38px; }
      @media (max-width: 576px) {
        #module-djv_navigation-nsearch #djv-two-columns-layer #js-product-list-top .products-sort-order, #category #djv-two-columns-layer #js-product-list-top .products-sort-order {
          margin-right: 0px; } }
    #module-djv_navigation-nsearch #djv-two-columns-layer #js-product-list-top .pagination, #category #djv-two-columns-layer #js-product-list-top .pagination {
      grid-area: pagination;
      justify-self: left;
      align-items: center; }
  #module-djv_navigation-nsearch #djv-two-columns-layer #js-product-list, #category #djv-two-columns-layer #js-product-list {
    display: grid;
    grid-template-columns: repeat(5, calc(20% - 15px));
    grid-gap: 15px;
    padding: 16px;
    width: 100%; }
    @media (max-width: 768px) {
      #module-djv_navigation-nsearch #djv-two-columns-layer #js-product-list, #category #djv-two-columns-layer #js-product-list {
        display: block;
        padding: 0; } }
    #module-djv_navigation-nsearch #djv-two-columns-layer #js-product-list .product-miniature, #category #djv-two-columns-layer #js-product-list .product-miniature {
      text-align: center; }
      @media (max-width: 768px) {
        #module-djv_navigation-nsearch #djv-two-columns-layer #js-product-list .product-miniature, #category #djv-two-columns-layer #js-product-list .product-miniature {
          padding: 0;
          height: auto;
          min-height: auto;
          margin-bottom: 16px; } }
    #module-djv_navigation-nsearch #djv-two-columns-layer #js-product-list.djv-display-list, #category #djv-two-columns-layer #js-product-list.djv-display-list {
      grid-template-columns: auto; }
  #module-djv_navigation-nsearch #djv-two-columns-layer #js-product-list-bottom, #category #djv-two-columns-layer #js-product-list-bottom {
    margin-right: auto;
    margin-left: auto;
    max-width: 1170px;
    min-width: 920px; }
    @media (max-width: 1024px) {
      #module-djv_navigation-nsearch #djv-two-columns-layer #js-product-list-bottom, #category #djv-two-columns-layer #js-product-list-bottom {
        max-width: 920px;
        min-width: 750px; } }
    @media (max-width: 768px) {
      #module-djv_navigation-nsearch #djv-two-columns-layer #js-product-list-bottom, #category #djv-two-columns-layer #js-product-list-bottom {
        max-width: 750px;
        min-width: 480px; } }
    @media (max-width: 480px) {
      #module-djv_navigation-nsearch #djv-two-columns-layer #js-product-list-bottom, #category #djv-two-columns-layer #js-product-list-bottom {
        max-width: 500px;
        min-width: 300px; } }
  #module-djv_navigation-nsearch #djv-two-columns-layer .page-not-found, #category #djv-two-columns-layer .page-not-found {
    text-align: center; }
    #module-djv_navigation-nsearch #djv-two-columns-layer .page-not-found p, #category #djv-two-columns-layer .page-not-found p {
      font-size: 22px;
      margin-bottom: 16px; }
    #module-djv_navigation-nsearch #djv-two-columns-layer .page-not-found #search_widget input, #category #djv-two-columns-layer .page-not-found #search_widget input {
      border: 1px solid #999999;
      padding: 16px 50px;
      border-radius: 20px; }
    #module-djv_navigation-nsearch #djv-two-columns-layer .page-not-found #search_widget button, #category #djv-two-columns-layer .page-not-found #search_widget button {
      padding: 8px;
      border-radius: 20px; }

@media (max-width: 1024px) {
  .carousel ~ .djv-navigation-nsearch-header {
    margin-top: 0px; } }

.djv_carouselh.list-originales .products .product-miniature {
  min-height: 380px;
  max-height: 380px; }

.djv-pagination {
  position: relative;
  display: grid;
  grid-template-areas: "paginas numArticulos";
  grid-template-columns: 50% 50%;
  width: 100%;
  padding: 0; }
  @media (max-width: 768px) {
    .djv-pagination {
      display: block;
      padding: 0 0;
      margin-top: 0px;
      text-align: left; } }
  .djv-pagination .pagination-summary {
    grid-area: numArticulos;
    font-size: 16px;
    padding-left: 0px;
    padding-left: 0px;
    align-self: center;
    justify-self: right;
    margin-right: 40px; }
    @media (max-width: 768px) {
      .djv-pagination .pagination-summary {
        margin-right: 0px;
        justify-self: left;
        margin-top: 0px; } }
  .djv-pagination .pagination-summary-movile {
    display: none; }
    @media (max-width: 768px) {
      .djv-pagination .pagination-summary-movile {
        display: inline-block;
        font-size: 10px; } }
  .djv-pagination ul {
    grid-area: paginas;
    display: inline-block;
    margin: 0;
    padding: 0; }
    @media (max-width: 576px) {
      .djv-pagination ul {
        position: relative;
        margin-top: 5px;
        margin-bottom: 5px;
        padding-left: 0;
        float: right; } }
    .djv-pagination ul li {
      display: inline-block;
      margin: 0 3px; }
      .djv-pagination ul li a {
        color: #777777;
        padding: 4px; }
        .djv-pagination ul li a[rel="prev"] {
          margin-right: 0px; }
          .djv-pagination ul li a[rel="prev"]::before {
            display: inline-block;
            font-family: "comunes";
            font-style: normal;
            font-weight: normal;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: '\004b';
            display: block;
            float: left;
            margin-top: 2px; }
        .djv-pagination ul li a[rel="nofollow"] {
          min-width: 26px;
          display: block;
          text-align: center;
          border: 1px solid #777777;
          border-radius: 4px;
          font-size: 11px; }
        .djv-pagination ul li a[rel="next"] {
          margin-left: 0px; }
          .djv-pagination ul li a[rel="next"]::after {
            display: inline-block;
            font-family: "comunes";
            font-style: normal;
            font-weight: normal;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: '\004c';
            display: block;
            float: right;
            margin-top: 2px; }
      .djv-pagination ul li.current a[rel="nofollow"] {
        color: #dddddd;
        border: 1px solid #dddddd; }

.products-sort-order {
  display: inline-block;
  position: relative; }
  @media (max-width: 768px) {
    .products-sort-order {
      display: block;
      padding: 0 0;
      margin-bottom: 5px; } }
  .products-sort-order label {
    font-size: 16px; }
  .products-sort-order ul {
    padding: 0;
    display: inline-block;
    margin-bottom: 0; }
    .products-sort-order ul li {
      list-style: none;
      display: inline-block;
      margin: 5px;
      font-size: 16px;
      color: #015f9b; }
      @media (max-width: 768px) {
        .products-sort-order ul li {
          margin: 0; } }
      .products-sort-order ul li.sort-selected {
        font-size: 12px;
        background-color: #dbebf5;
        color: white;
        padding: 5px 10px;
        border-radius: 2px;
        display: block; }
      .products-sort-order ul li a, .products-sort-order ul li .separator {
        padding: 5px 0px 5px 3px;
        border-radius: 2px;
        display: inline-block;
        color: black; }
  .products-sort-order select {
    font-size: 12px;
    cursor: pointer;
    border: 0;
    color: #015f9b; }
    .products-sort-order select option {
      font-size: 12px;
      font-family: "Lato", sans-serif;
      font-weight: 300;
      color: black; }
      .products-sort-order select option.sort-selected {
        font-size: 12px;
        padding: 5px 10px;
        border-radius: 2px;
        display: block; }

.product-quantity {
  padding-top: 16px; }
  .product-quantity p {
    font-size: 11px;
    display: block;
    margin-bottom: 0; }
  .product-quantity label {
    display: block;
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    margin-bottom: 5px; }
  .product-quantity .product-add-to-cart-quantity {
    display: flex;
    margin: 0 auto;
    font-size: 11px; }
    @media (max-width: 768px) {
      .product-quantity .product-add-to-cart-quantity {
        width: 100% !important;
        display: flex;
        justify-content: center; } }
  .product-quantity #quantity_wanted {
    text-align: center;
    background-color: white;
    border: 1px solid #aaaaaa;
    padding-top: 4px;
    width: 40px;
    height: 30px; }
  .product-quantity .djv-product-add-to-cart-quantity-btn-less, .product-quantity .djv-product-add-to-cart-quantity-btn-plus {
    background-color: white;
    border: 1px solid #aaaaaa;
    color: black;
    cursor: pointer;
    font-size: 14px;
    width: 30px;
    height: 30px;
    padding: 9px 5px;
    text-align: center; }
  .product-quantity .djv-product-add-to-cart-quantity-btn-plus {
    border-left: none; }
  .product-quantity .djv-product-add-to-cart-quantity-btn-less {
    border-right: none; }

@media (max-width: 768px) {
  #product .product-add-to-cart-quantity {
    height: 42px;
    margin: 20px 0px;
    width: 211px; } }

@media (max-width: 768px) {
  #product .product-add-to-cart-quantity #quantity_wanted {
    height: 42px;
    width: 132px; } }

@media (max-width: 768px) {
  #product .product-add-to-cart-quantity .djv-product-add-to-cart-quantity-btn-less {
    height: 42px;
    padding: 5px 15px;
    width: 39px; } }

@media (max-width: 768px) {
  #product .product-add-to-cart-quantity .djv-product-add-to-cart-quantity-btn-plus {
    height: 42px;
    padding: 9px 15px;
    width: 39px; } }

.product-variants h2 {
  display: none; }

.product-variants label {
  text-align: left;
  display: block;
  padding: 5px 0px;
  margin-bottom: 0;
  font-size: 16px;
  font-family: "Lato", sans-serif;
  font-weight: 300; }

.product-variants .product-variants-radio {
  text-align: left; }
  .product-variants .product-variants-radio > a {
    margin-left: 15px;
    font-size: 12px;
    text-decoration: underline;
    font-family: "Lato", sans-serif;
    font-weight: 400; }
  .product-variants .product-variants-radio ul {
    padding-left: 30px; }
    .product-variants .product-variants-radio ul .product-variants-radio-input {
      list-style: none;
      display: flex;
      align-items: baseline;
      margin-bottom: 10px;
      cursor: pointer; }
      .product-variants .product-variants-radio ul .product-variants-radio-input input {
        cursor: pointer; }
      .product-variants .product-variants-radio ul .product-variants-radio-input .attribute-name {
        padding: 5px 15px;
        font-size: 12px; }
        .product-variants .product-variants-radio ul .product-variants-radio-input .attribute-name .destacar {
          font-family: "Lato", sans-serif;
          font-weight: 900;
          color: #EE2F34; }
      .product-variants .product-variants-radio ul .product-variants-radio-input.selected .swap-bg::before {
        content: "✔";
        position: absolute;
        margin-left: 5px; }
      .product-variants .product-variants-radio ul .product-variants-radio-input.selected .attribute-name {
        background-color: #015f9b;
        color: white;
        padding: 5px 15px;
        font-size: 12px; }
      .product-variants .product-variants-radio ul .product-variants-radio-input.disabled {
        position: relative; }
        .product-variants .product-variants-radio ul .product-variants-radio-input.disabled span {
          opacity: 0.5; }

.product-variants .product-variants-columns {
  display: flex;
  justify-content: space-around; }
  .product-variants .product-variants-columns .product-variants-select select {
    font-size: 16px;
    width: 100%;
    padding: 4px;
    border: 1px solid #999999; }

.product-variants .product-variants-color {
  text-align: left; }
  .product-variants .product-variants-color ul {
    padding: 10px;
    display: flex;
    border-left: 1px solid #e1eef7;
    border-right: 1px solid #e1eef7; }
    @media (max-width: 576px) {
      .product-variants .product-variants-color ul {
        margin-bottom: 5px; } }
    .product-variants .product-variants-color ul .product-variants-color-input-container {
      list-style: none;
      display: inline-block;
      margin-bottom: 10px;
      width: 30px;
      height: 30px;
      margin-right: 5px;
      cursor: pointer; }
      .product-variants .product-variants-color ul .product-variants-color-input-container.selected .swap-bg {
        border: 2px solid white;
        outline: 2px solid black;
        background-size: contain; }
      .product-variants .product-variants-color ul .product-variants-color-input-container .swap-bg {
        width: 30px;
        height: 30px;
        display: inline-block;
        border: 1px solid;
        background-size: contain;
        cursor: pointer; }
        .product-variants .product-variants-color ul .product-variants-color-input-container .swap-bg input {
          opacity: 0;
          width: 28px;
          height: 20px;
          cursor: pointer; }
        .product-variants .product-variants-color ul .product-variants-color-input-container .swap-bg span {
          display: none; }
      .product-variants .product-variants-color ul .product-variants-color-input-container.disabled {
        position: relative; }
        .product-variants .product-variants-color ul .product-variants-color-input-container.disabled span {
          opacity: 0.5; }
        .product-variants .product-variants-color ul .product-variants-color-input-container.disabled::after {
          content: "";
          display: block;
          width: 46px;
          height: 46px;
          position: absolute;
          z-index: 9;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          padding: 0 4px;
          border-radius: 40px;
          background-image: url("/themes/base/assets/img/aspa.svg");
          color: #EAEAEA; }

#product .djv-product-images {
  position: relative; }
  #product .djv-product-images .product-flags {
    display: none; }
  #product .djv-product-images .product-cover {
    overflow: hidden;
    position: relative;
    margin-bottom: 20px;
    cursor: pointer; }
    @media (max-width: 576px) {
      #product .djv-product-images .product-cover {
        min-width: 200px;
        min-height: 0px; } }
    #product .djv-product-images .product-cover img {
      margin-left: auto;
      margin-right: auto;
      display: block; }
      @media (max-width: 576px) {
        #product .djv-product-images .product-cover img {
          max-width: 100%; } }
  @media (max-width: 576px) {
    #product .djv-product-images .product-thumbnails {
      margin-top: -16px; } }
  @media (max-width: 768px) {
    #product .djv-product-images .product-thumbnails.djv_carouselh::after {
      top: 0px; } }
  #product .djv-product-images .product-thumbnails .thumbnails {
    height: 80px;
    overflow: hidden;
    padding: 0;
    background-color: white; }
    @media (max-width: 768px) {
      #product .djv-product-images .product-thumbnails .thumbnails {
        width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
        -webkit-scroll-snap-type: mandatory;
        scroll-snap-type: x mandatory;
        -webkit-scroll-snap-points-x: repeat(100%);
        scroll-snap-points-x: repeat(100%); } }
    #product .djv-product-images .product-thumbnails .thumbnails li {
      list-style: none;
      padding: 6px;
      display: inline-block;
      width: 120px;
      min-height: 120px;
      background-color: white; }
      #product .djv-product-images .product-thumbnails .thumbnails li img {
        width: 100%; }
      @media (max-width: 768px) {
        #product .djv-product-images .product-thumbnails .thumbnails li {
          scroll-snap-align: start;
          width: 33%;
          position: relative;
          display: inline-block;
          margin-right: -4px;
          text-align: center; }
          #product .djv-product-images .product-thumbnails .thumbnails li img {
            margin-left: auto;
            margin-right: auto;
            width: 70px !important;
            height: 70px !important; } }

#product .djv-product-detail-breadcrumb {
  border-bottom: 1px solid #EAEAEA;
  text-align: center;
  font-size: 11px;
  color: #cccccc;
  margin-bottom: 11px; }
  #product .djv-product-detail-breadcrumb ol {
    margin-bottom: 10px; }
    #product .djv-product-detail-breadcrumb ol li {
      display: inline; }
      #product .djv-product-detail-breadcrumb ol li::after {
        content: " > "; }
      #product .djv-product-detail-breadcrumb ol li:last-of-type::after {
        content: ""; }
      #product .djv-product-detail-breadcrumb ol li a {
        color: inherit; }

#product .djv-product-detail-suport {
  margin-top: 20px; }
  #product .djv-product-detail-suport > div {
    display: inline-block;
    width: 48%;
    margin-left: 0.5%;
    margin-right: 0.5%;
    text-align: center;
    padding: 0.5%;
    position: relative; }
    #product .djv-product-detail-suport > div:first-of-type::after {
      border-left: 1px solid #EAEAEA;
      content: " ";
      display: inline-block;
      height: 150px;
      position: absolute;
      top: 0;
      right: -14px; }
    #product .djv-product-detail-suport > div i {
      font-size: 28px; }
    #product .djv-product-detail-suport > div h4 {
      font-size: 16px;
      font-family: "Lato", sans-serif;
      font-weight: 400; }
    #product .djv-product-detail-suport > div p {
      font-size: 12px; }

#product .djv-product-detail-share {
  margin-top: 20px;
  text-align: center;
  color: #00ff00;
  font-size: 22px; }
  #product .djv-product-detail-share a {
    color: #00ff00;
    margin-right: 1em; }
    #product .djv-product-detail-share a:hover {
      color: #80ff80; }

#modal-zoom {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: white;
  z-index: 5000; }
  @media (max-width: 768px) {
    #modal-zoom {
      overflow: hidden; } }
  @media (max-width: 768px) {
    #modal-zoom #consolaDebug {
      position: fixed;
      top: 60px;
      width: 80px;
      padding: 10px; } }
  #modal-zoom #modal-zoom-close {
    margin-bottom: 50px;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 10;
    font-size: 32px; }
    @media (max-width: 768px) {
      #modal-zoom #modal-zoom-close {
        top: 16px;
        z-index: 11;
        font-size: 26px;
        color: white; } }
  #modal-zoom .controls {
    position: absolute;
    left: 50%;
    bottom: 97px;
    z-index: 10;
    transform: translateX(-50%);
    background-color: black;
    padding: 18px 10px 10px;
    display: block;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px; }
    @media (max-width: 768px) {
      #modal-zoom .controls {
        bottom: auto;
        top: 0;
        right: auto;
        left: 0;
        transform: none;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 3px;
        width: 100%;
        display: flex;
        justify-content: center; } }
    #modal-zoom .controls > i {
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      color: white;
      margin: 5px 10px 1px;
      cursor: pointer; }
  #modal-zoom #image-inside-modal {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh; }
    @media (max-width: 768px) {
      #modal-zoom #image-inside-modal {
        overflow: scroll; } }
    #modal-zoom #image-inside-modal #consola {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1000; }
    #modal-zoom #image-inside-modal .product-cover {
      text-align: center;
      display: block;
      width: 100vw;
      height: 100vh; }
      @media (max-width: 768px) {
        #modal-zoom #image-inside-modal .product-cover {
          overflow: auto; } }
      #modal-zoom #image-inside-modal .product-cover img {
        position: absolute;
        transform-origin: center center 0;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(0.3);
        cursor: move; }
        @media (max-width: 768px) {
          #modal-zoom #image-inside-modal .product-cover img {
            touch-action: pinch-zoom;
            position: relative;
            left: auto;
            top: auto;
            transform: none;
            display: block;
            margin: 5% auto;
            height: auto;
            display: block;
            border: 1px solid black; } }
  #modal-zoom .thumbs-inside-modal {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 0px;
    padding: 5px;
    margin: 0 auto; }
    #modal-zoom .thumbs-inside-modal .djv_carouselh {
      width: 85%;
      display: block;
      margin: 0 auto; }
    #modal-zoom .thumbs-inside-modal #thumbs-modal {
      display: flex;
      justify-content: space-around;
      list-style: none;
      margin-bottom: 0;
      margin-left: 0;
      padding-left: 0; }
      #modal-zoom .thumbs-inside-modal #thumbs-modal li {
        display: inline-block;
        margin: 0 4px;
        flex: 1;
        max-width: 85px;
        max-height: 85px;
        padding: 0;
        scroll-snap-align: start;
        width: 100%;
        position: relative;
        display: inline-block;
        text-align: center; }
        #modal-zoom .thumbs-inside-modal #thumbs-modal li img {
          background-color: white;
          margin-top: 0;
          transition: all 500ms ease-out;
          cursor: pointer;
          box-shadow: 0 0 0 rgba(10, 10, 10, 0);
          width: 100%;
          max-width: 85px;
          max-height: 85px;
          min-width: 85px;
          min-height: 85px;
          border: 0px;
          border-color: #93ccfa; }
          #modal-zoom .thumbs-inside-modal #thumbs-modal li img:hover {
            border: 3px;
            border-color: #93ccfa;
            box-shadow: 1px 1px 5px rgba(10, 10, 10, 0.85); }

.expandText .content {
  position: relative;
  /*max-height: 100px;
        height: 100px;*/
  overflow: hidden;
  transition: all 600ms; }

.expandText .expand-handler, .expandText .contract-handler {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-size: 16px;
  padding: 16px 0;
  margin-bottom: 10px;
  cursor: pointer; }
  .expandText .expand-handler::before, .expandText .contract-handler::before {
    margin-right: 6px;
    font-size: 12px; }

#index #djv-two-columns-layer .djv-listas-productos {
  margin-bottom: 45px; }
  @media (max-width: 768px) {
    #index #djv-two-columns-layer .djv-listas-productos h3 {
      margin-top: 10px; } }
  #index #djv-two-columns-layer .djv-listas-productos .djv_carouselh .products .product-miniature {
    width: calc(25% - 15px);
    min-height: 380px;
    max-height: 390px;
    padding: 0 15px;
    grid-template-rows: 250px 130px; }
    @media (max-width: 768px) {
      #index #djv-two-columns-layer .djv-listas-productos .djv_carouselh .products .product-miniature {
        scroll-snap-align: start;
        width: 100%;
        position: relative;
        display: inline-block;
        margin-right: -4px;
        text-align: center; } }
    #index #djv-two-columns-layer .djv-listas-productos .djv_carouselh .products .product-miniature .product-miniature-img-block {
      max-width: 90vw; }
  @media (max-width: 768px) {
    #index #djv-two-columns-layer .djv-listas-productos {
      padding: 5px; } }

.djv-listas-productos {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px; }
  @media (max-width: 1024px) {
    .djv-listas-productos {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    .djv-listas-productos {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    .djv-listas-productos {
      max-width: 500px;
      min-width: 300px; } }
  .djv-listas-productos h3 {
    text-align: center;
    font-family: "Lato", sans-serif;
    font-weight: 900;
    font-size: 16px; }

#checkout {
  background-color: #eee;
  font-family: "Lato", sans-serif;
  font-weight: 700; }
  #checkout h1 {
    font-size: 26px;
    border-bottom: 1px solid #aaa;
    padding-bottom: 10px;
    margin: 10px 0;
    color: black; }
  #checkout .-complete h1 {
    color: #999; }
    #checkout .-complete h1::after {
      font-size: 12px;
      color: #015f9b;
      content: "editar";
      float: right;
      cursor: pointer;
      padding-top: 5px; }
  #checkout h2 {
    text-align: left;
    font-size: 22px;
    margin-bottom: 10px; }
  #checkout h4 {
    font-size: 18px; }
  #checkout a {
    color: #015f9b;
    text-decoration: underline; }
  #checkout #header {
    position: relative;
    top: -5px;
    min-height: 36px;
    box-shadow: none; }
    #checkout #header .checkout-header-simplified {
      background-color: white; }
      #checkout #header .checkout-header-simplified div {
        margin-right: auto;
        margin-left: auto;
        max-width: 1170px;
        min-width: 920px; }
        @media (max-width: 1024px) {
          #checkout #header .checkout-header-simplified div {
            max-width: 920px;
            min-width: 750px; } }
        @media (max-width: 768px) {
          #checkout #header .checkout-header-simplified div {
            max-width: 750px;
            min-width: 480px; } }
        @media (max-width: 480px) {
          #checkout #header .checkout-header-simplified div {
            max-width: 500px;
            min-width: 300px; } }
        #checkout #header .checkout-header-simplified div .logo {
          background-image: url("/themes/base/assets/img/logo.png");
          width: 120px;
          height: 60px;
          display: block;
          background-repeat: no-repeat;
          background-size: contain;
          margin: 5px;
          background-position: center; }
          @media (max-width: 576px) {
            #checkout #header .checkout-header-simplified div .logo {
              margin-left: auto;
              margin-right: auto; } }
  #checkout #content {
    margin-right: auto;
    margin-left: auto;
    max-width: 1170px;
    min-width: 920px;
    display: grid;
    grid-template-columns: calc(65% - 16px) calc(35% - 16px);
    grid-column-gap: 16px; }
    @media (max-width: 1024px) {
      #checkout #content {
        max-width: 920px;
        min-width: 750px; } }
    @media (max-width: 768px) {
      #checkout #content {
        max-width: 750px;
        min-width: 480px; } }
    @media (max-width: 480px) {
      #checkout #content {
        max-width: 500px;
        min-width: 300px; } }
    @media (max-width: 576px) {
      #checkout #content {
        grid-template-columns: auto;
        padding: 0 10px; } }
    #checkout #content > * {
      background-color: white;
      border: 1px solid #dadada;
      padding: 15px; }
      @media (max-width: 576px) {
        #checkout #content > * {
          margin-bottom: 10px; } }
  #checkout .checkout-process {
    background-color: white;
    padding: 22px; }
    @media (max-width: 576px) {
      #checkout .checkout-process {
        margin-bottom: 10px; } }
    #checkout .checkout-process section .content {
      display: none; }
    #checkout .checkout-process section.js-current-step .content {
      display: block; }
    #checkout .checkout-process .payment-option img {
      display: block; }
  #checkout .djv-checkout-blocks {
    display: grid;
    grid-template-columns: repeat(2, 50%); }
    @media (max-width: 1024px) {
      #checkout .djv-checkout-blocks {
        display: block; } }
    #checkout .djv-checkout-blocks .djv-checkout-block-login {
      padding: 10px 20px;
      border-right: 1px solid #666666; }
      @media (max-width: 1024px) {
        #checkout .djv-checkout-blocks .djv-checkout-block-login {
          border-right: none;
          border-bottom: 1px solid #666666;
          margin-bottom: 20px;
          padding: 10px 10px; } }
    #checkout .djv-checkout-blocks .djv-checkout-block-register {
      padding: 10px 20px;
      position: relative; }
      @media (max-width: 1024px) {
        #checkout .djv-checkout-blocks .djv-checkout-block-register {
          border-bottom: 1px solid #666666;
          margin-bottom: 20px;
          padding: 10px 10px; } }
      #checkout .djv-checkout-blocks .djv-checkout-block-register #djv-checkout-button-register {
        background-color: #015f9b;
        color: white;
        border: 2px solid #015f9b;
        border-radius: 50px;
        transition: all 500ms;
        text-align: center;
        text-transform: uppercase;
        padding: 5px 10px;
        cursor: pointer;
        display: block;
        width: 200px;
        font-size: 14px; }
        #checkout .djv-checkout-blocks .djv-checkout-block-register #djv-checkout-button-register:hover {
          background-color: white;
          color: #015f9b; }
  #checkout #djv-checkout-form-register {
    display: none; }
    #checkout #djv-checkout-form-register #customer-form .checkbox-field {
      position: relative; }
      #checkout #djv-checkout-form-register #customer-form .checkbox-field input[type="checkbox"] {
        float: left;
        margin-top: 5px;
        margin-right: 5px; }
  #checkout #delivery_message {
    width: 100%;
    padding: 16px;
    border: 1px solid #444444; }
  #checkout .js-address-form .form-footer {
    border-top: 1px dashed #cccccc;
    padding-top: 10px;
    margin-top: 10px;
    margin: 10px 0; }
    #checkout .js-address-form .form-footer a {
      background-color: #0083ff;
      color: white;
      border: 2px solid #0083ff;
      border-radius: 30px;
      transition: all 500ms;
      text-align: center;
      text-transform: uppercase;
      padding: 15px 15px;
      cursor: pointer; }
      #checkout .js-address-form .form-footer a:hover {
        background-color: white;
        color: #0083ff; }
    #checkout .js-address-form .form-footer button {
      background-color: #1a7e10;
      color: white;
      border: 2px solid #1a7e10;
      border-radius: 30px;
      transition: all 500ms;
      text-align: center;
      text-transform: uppercase;
      padding: 15px 15px;
      cursor: pointer; }
      #checkout .js-address-form .form-footer button:hover {
        background-color: white;
        color: #1a7e10; }
  #checkout .continue {
    background-color: #1a7e10;
    color: white;
    border: 2px solid #1a7e10;
    border-radius: 30px;
    transition: all 500ms;
    text-align: center;
    text-transform: uppercase;
    padding: 6px 15px;
    cursor: pointer;
    width: 130px;
    margin: 10px 0; }
    #checkout .continue:hover {
      background-color: white;
      color: #1a7e10; }
    @media (max-width: 1024px) {
      #checkout .continue {
        width: 100%;
        margin: 20px auto; } }
  #checkout #delivery-addresses, #checkout #invoice-addresses {
    display: grid;
    grid-template-columns: calc(33% - 16px) calc(33% - 16px) calc(33% - 16px);
    grid-gap: 16px;
    margin-bottom: 30px; }
    @media (max-width: 576px) {
      #checkout #delivery-addresses, #checkout #invoice-addresses {
        grid-template-columns: auto; } }
    #checkout #delivery-addresses .address-item, #checkout #invoice-addresses .address-item {
      border: 1px dashed #dadada;
      padding: 8px;
      position: relative; }
      #checkout #delivery-addresses .address-item:last-of-type, #checkout #invoice-addresses .address-item:last-of-type {
        border-bottom: 1px dashed #dadada; }
      #checkout #delivery-addresses .address-item.selected, #checkout #invoice-addresses .address-item.selected {
        border: 4px solid #666666; }
      #checkout #delivery-addresses .address-item label, #checkout #invoice-addresses .address-item label {
        width: 100%;
        display: block;
        padding-left: 26px;
        margin-bottom: 16px;
        padding-bottom: 40px; }
        #checkout #delivery-addresses .address-item label input, #checkout #invoice-addresses .address-item label input {
          margin-left: -26px;
          margin-right: 10px; }
      #checkout #delivery-addresses .address-item header, #checkout #invoice-addresses .address-item header {
        font-size: 26px;
        color: #333333;
        border-bottom: 1px solid #dadada;
        padding-bottom: 6px; }
      #checkout #delivery-addresses .address-item footer, #checkout #invoice-addresses .address-item footer {
        position: absolute;
        display: flex;
        margin-top: 40px;
        bottom: 10px;
        width: 92%;
        justify-content: space-between; }
        #checkout #delivery-addresses .address-item footer a, #checkout #invoice-addresses .address-item footer a {
          display: inline-block;
          padding: 3px 16px;
          text-align: center;
          color: white;
          font-size: 14px; }
        #checkout #delivery-addresses .address-item footer a[data-link-action="edit-address"], #checkout #invoice-addresses .address-item footer a[data-link-action="edit-address"] {
          background-color: #015f9b;
          color: white;
          border: 2px solid #015f9b;
          border-radius: 0px;
          transition: all 500ms;
          text-align: center;
          text-transform: uppercase;
          padding: 5px 10px;
          cursor: pointer; }
          #checkout #delivery-addresses .address-item footer a[data-link-action="edit-address"]:hover, #checkout #invoice-addresses .address-item footer a[data-link-action="edit-address"]:hover {
            background-color: white;
            color: #015f9b; }
        #checkout #delivery-addresses .address-item footer a[data-link-action="delete-address"], #checkout #invoice-addresses .address-item footer a[data-link-action="delete-address"] {
          background-color: #D53128;
          color: white;
          border: 2px solid #D53128;
          border-radius: 0px;
          transition: all 500ms;
          text-align: center;
          text-transform: uppercase;
          padding: 5px 10px;
          cursor: pointer; }
          #checkout #delivery-addresses .address-item footer a[data-link-action="delete-address"]:hover, #checkout #invoice-addresses .address-item footer a[data-link-action="delete-address"]:hover {
            background-color: white;
            color: #D53128; }
  #checkout #delivery-addresses + a + h2 {
    margin: 30px 0 0 0; }
  #checkout #checkout-addresses-step,
  #checkout #checkout-delivery-step,
  #checkout #checkout-payment-step {
    padding-bottom: 5px;
    margin-bottom: 5px; }
    #checkout #checkout-addresses-step .warning,
    #checkout #checkout-delivery-step .warning,
    #checkout #checkout-payment-step .warning {
      background-color: #D53128;
      color: white;
      padding: 10px;
      font-size: 14px; }
      #checkout #checkout-addresses-step .warning::before,
      #checkout #checkout-delivery-step .warning::before,
      #checkout #checkout-payment-step .warning::before {
        content: "!";
        float: left;
        display: inline-block;
        margin-right: 10px;
        padding: 5px 12px;
        border-radius: 50%;
        font-size: 18px;
        font-weight: 600;
        color: black;
        background-color: white; }
  #checkout #customer-form {
    margin: 10px 0; }
    #checkout #customer-form .addDoc {
      display: block;
      padding: 16px;
      background-color: #EAEAEA; }
      #checkout #customer-form .addDoc br {
        margin-bottom: 16px; }
  #checkout .delivery-options-list .delivery-options {
    display: block; }
    #checkout .delivery-options-list .delivery-options .delivery-option {
      display: grid;
      grid-template-columns: 30px calc(100% - 30px);
      align-items: start; }
      #checkout .delivery-options-list .delivery-options .delivery-option input[type="radio"] {
        margin-top: 5px; }
      #checkout .delivery-options-list .delivery-options .delivery-option img {
        margin-bottom: 3px;
        max-width: 30px;
        margin-left: 10px; }
      #checkout .delivery-options-list .delivery-options .delivery-option .cms-block-example, #checkout .delivery-options-list .delivery-options .delivery-option #payment-option-1-additional-information section p, #checkout #payment-option-1-additional-information section .delivery-options-list .delivery-options .delivery-option p, #checkout .delivery-options-list .delivery-options .delivery-option #order-detail #order-infos, #order-detail #checkout .delivery-options-list .delivery-options .delivery-option #order-infos {
        grid-column-start: 1;
        grid-column-end: 3; }
        #checkout .delivery-options-list .delivery-options .delivery-option .cms-block-example ul, #checkout .delivery-options-list .delivery-options .delivery-option #payment-option-1-additional-information section p ul, #checkout #payment-option-1-additional-information section .delivery-options-list .delivery-options .delivery-option p ul, #checkout .delivery-options-list .delivery-options .delivery-option #order-detail #order-infos ul, #order-detail #checkout .delivery-options-list .delivery-options .delivery-option #order-infos ul {
          margin-left: 0;
          padding-left: 5px; }
          #checkout .delivery-options-list .delivery-options .delivery-option .cms-block-example ul li, #checkout .delivery-options-list .delivery-options .delivery-option #payment-option-1-additional-information section p ul li, #checkout #payment-option-1-additional-information section .delivery-options-list .delivery-options .delivery-option p ul li, #checkout .delivery-options-list .delivery-options .delivery-option #order-detail #order-infos ul li, #order-detail #checkout .delivery-options-list .delivery-options .delivery-option #order-infos ul li {
            margin-bottom: 10px; }
  #checkout #bankwire-modal {
    display: none;
    width: 1px;
    height: 1px; }

#js-checkout-summary h5 a {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-size: 22px;
  color: black; }

#js-checkout-summary header p {
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: black; }

#js-checkout-summary .products {
  border-top: 1px solid #cccccc;
  padding-top: 10px;
  margin-top: 10px; }

#js-checkout-summary .show-details {
  animation-name: showdetails;
  animation-duration: 1s;
  animation-fill-mode: forwards; }

@keyframes showdetails {
  from {
    height: 0;
    opacity: 0; }
  to {
    height: 100%;
    opacity: 1; } }

#js-checkout-summary .cart-summary-totals .aviso-portes {
  display: block;
  font-size: 12px; }

#js-checkout-summary .cart-summary-totals .cart-summary-totals {
  border-top: 1px solid #666666;
  margin-top: 15px;
  padding-top: 15px;
  text-align: center;
  color: black; }
  #js-checkout-summary .cart-summary-totals .cart-summary-totals .label {
    font-size: 16px;
    display: block; }
  #js-checkout-summary .cart-summary-totals .cart-summary-totals .value {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-size: 52px; }

#js-checkout-summary .checkout-continuar-comprando {
  border-top: 1px solid #cccccc;
  padding-top: 10px;
  margin-top: 10px;
  position: relative;
  font-size: 18px;
  text-align: center; }
  #js-checkout-summary .checkout-continuar-comprando i {
    display: none; }
  #js-checkout-summary .checkout-continuar-comprando a {
    display: block;
    font-size: 14px; }

#cart-summary-ul {
  padding-left: 0;
  display: none;
  height: 0;
  opacity: 0; }
  #cart-summary-ul li {
    list-style: none;
    font-size: 14px;
    display: grid;
    grid-template-columns: 40px auto 100px;
    padding: 5px 0;
    border-bottom: 1px solid #ddd; }
    #cart-summary-ul li:last-of-type {
      border-bottom: 0; }
    #cart-summary-ul li .product-price {
      text-align: right; }
    #cart-summary-ul li .product-name {
      font-size: 14px; }
    #cart-summary-ul li .product-quantity {
      padding-top: 0; }
    #cart-summary-ul li span:first-of-type {
      text-align: center; }
    #cart-summary-ul li span:last-of-type {
      text-align: right; }

#cart-summary .shipping .shipping-link {
  display: block; }
  #cart-summary .shipping .shipping-link a {
    color: #444444;
    font-size: 10px; }

#cart-summary .tax {
  font-size: 14px;
  color: #444444; }

#cart-summary > div {
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 5px));
  grid-gap: 10px;
  margin-bottom: 10px; }
  #cart-summary > div > .value {
    text-align: right; }

#cart-summary-product-list {
  margin-top: 10px; }

#cart-summary-viewdetails {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-size: 12px;
  color: #015f9b;
  cursor: pointer; }
  #cart-summary-viewdetails::before {
    margin-right: 5px;
    font-size: 10px; }

#conditions-to-approve {
  margin-top: 20px; }
  #conditions-to-approve ul {
    margin-left: 0;
    padding-left: 0; }
    #conditions-to-approve ul li {
      list-style: none;
      display: flex;
      align-items: start; }
      #conditions-to-approve ul li label {
        margin-bottom: 0;
        margin-left: 5px; }

#payment-confirmation button {
  border: none;
  background-color: #dddddd;
  color: #aaaaaa;
  padding: 6px;
  margin: 10px; }
  #payment-confirmation button:not([disabled]) {
    background-color: #0a3855;
    color: white;
    border: 2px solid #0a3855;
    border-radius: 30px;
    transition: all 500ms;
    text-align: center;
    text-transform: uppercase;
    padding: 15px 15px;
    cursor: pointer;
    margin: 10px; }
    #payment-confirmation button:not([disabled]):hover {
      background-color: white;
      color: #0a3855; }

.form-errors {
  margin-left: 0;
  padding-left: 0; }
  .form-errors .errorExist {
    background-color: red;
    color: white;
    padding: 16px; }

[class*='required'] > span::after {
  content: "*";
  display: inline-block;
  margin-left: 4px;
  color: red; }

#authentication #main {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto; }
  @media (max-width: 1024px) {
    #authentication #main {
      padding: 10px; } }
  #authentication #main h1.h3 {
    font-size: 22px; }
  #authentication #main .djv-register-link {
    display: block;
    border-top: 1px dashed #cccccc;
    padding-top: 10px;
    margin-top: 10px;
    border-bottom: 1px dashed #cccccc;
    padding-bottom: 10px;
    margin-bottom: 10px; }
  #authentication #main .form-footer button {
    background-color: #4CAF50;
    color: white;
    border: 2px solid #4CAF50;
    border-radius: 0;
    transition: all 500ms;
    text-align: center;
    text-transform: uppercase;
    padding: 10px 30px;
    cursor: pointer; }
    #authentication #main .form-footer button:hover {
      background-color: white;
      color: #4CAF50; }
  #authentication #main .page-authentication #login-form label {
    max-width: 320px; }

#password .form-fields label {
  display: block;
  margin-top: 20px; }
  #password .form-fields label > * {
    display: inline-block;
    width: 200px; }

.carousel {
  margin-bottom: 5px; }
  @media (max-width: 1024px) {
    .carousel {
      margin-bottom: 0; } }
  .carousel .carousel-item {
    overflow: hidden;
    max-width: 100%; }
    .carousel .carousel-item img {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      top: 0; }
  .carousel [class*='djv-icon-arrow-']::before {
    height: 50px;
    width: 50px;
    padding: 17px 4px 20px 7px;
    background: #015f9b;
    border: none;
    border-radius: 50%;
    font-size: 16px;
    color: white;
    font-weight: 900; }
    @media (max-width: 1024px) {
      .carousel [class*='djv-icon-arrow-']::before {
        height: 30px;
        width: 30px;
        font-size: 12px;
        padding: 9px 0 9px; } }

.carousel-indicator-container {
  display: block;
  position: relative;
  bottom: 30px;
  height: 25px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  .carousel-indicator-container .carousel-indicators {
    margin-right: auto;
    margin-left: auto;
    max-width: 1170px;
    min-width: 920px;
    position: relative;
    right: 0;
    bottom: 0px;
    left: 0;
    z-index: 15;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 0;
    list-style: none;
    width: 70%; }
    @media (max-width: 1024px) {
      .carousel-indicator-container .carousel-indicators {
        max-width: 920px;
        min-width: 750px; } }
    @media (max-width: 768px) {
      .carousel-indicator-container .carousel-indicators {
        max-width: 750px;
        min-width: 480px; } }
    @media (max-width: 480px) {
      .carousel-indicator-container .carousel-indicators {
        max-width: 500px;
        min-width: 300px; } }
    @media (max-width: 1024px) {
      .carousel-indicator-container .carousel-indicators {
        min-width: 100px; } }
    .carousel-indicator-container .carousel-indicators > li {
      border-right: 0;
      width: 12px;
      height: 12px;
      padding: 0;
      cursor: pointer;
      border-radius: 50px;
      margin-right: 5px;
      font-family: "Lato", sans-serif;
      font-weight: 100;
      font-size: 0;
      background-color: rgba(200, 200, 255, 0.75);
      color: white; }
      .carousel-indicator-container .carousel-indicators > li::before {
        display: none; }
      .carousel-indicator-container .carousel-indicators > li::after {
        display: none; }
      .carousel-indicator-container .carousel-indicators > li.active {
        background-color: white; }
      @media (min-width: 1024px) {
        .carousel-indicator-container .carousel-indicators > li:last-of-type {
          border-right: none; } }
      .carousel-indicator-container .carousel-indicators > li::marker {
        display: none; }

/*.carousel-indicators li::after, .carousel-indicators li::before {
    position: relative;
    bottom:0px;
    top:0px;
    left: 0;
    display: inline-block;
    width: auto;
    height: auto;
    content: "";
}*/
@media only screen and (max-width: 1024px) {
  .carousel-item img {
    max-height: 160px; }
  .carousel-indicator-container {
    bottom: 0px;
    border: none;
    height: 16px;
    background-color: #333333; }
    .carousel-indicator-container .carousel-indicators > li {
      font-size: 0px;
      border: 2px solid white;
      border-radius: 50%;
      margin: 3px;
      height: 10px;
      width: 10px;
      padding: 0px;
      overflow: hidden;
      background-color: #444444; }
      .carousel-indicator-container .carousel-indicators > li.active {
        height: 10px;
        width: 10px;
        margin: 3px;
        overflow: hidden;
        background-color: #666666; }
  .carousel-control-prev, .carousel-control-next {
    max-height: 250px; } }

.page-cms #djv-two-columns-layer {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px; }
  @media (max-width: 1024px) {
    .page-cms #djv-two-columns-layer {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    .page-cms #djv-two-columns-layer {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    .page-cms #djv-two-columns-layer {
      max-width: 500px;
      min-width: 300px; } }
  .page-cms #djv-two-columns-layer h1 {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 1px dashed #bababa;
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-size: 22px; }
    @media (max-width: 576px) {
      .page-cms #djv-two-columns-layer h1 {
        font-size: 16px; } }
  .page-cms #djv-two-columns-layer h2, .page-cms #djv-two-columns-layer h3, .page-cms #djv-two-columns-layer h4 {
    margin-top: 30px;
    text-align: left;
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-size: 22px;
    color: black; }
  .page-cms #djv-two-columns-layer h3 {
    font-size: 20px; }
  .page-cms #djv-two-columns-layer h4 {
    font-size: 18px; }
  .page-cms #djv-two-columns-layer ul {
    list-style-type: disc;
    box-sizing: border-box; }
    .page-cms #djv-two-columns-layer ul.nobullet {
      list-style-type: none; }
    @media (max-width: 768px) {
      .page-cms #djv-two-columns-layer ul {
        display: block; }
        .page-cms #djv-two-columns-layer ul li {
          display: block;
          margin-bottom: 20px; } }
  .page-cms #djv-two-columns-layer p {
    margin-bottom: 16px; }
  .page-cms #djv-two-columns-layer a {
    color: #dbebf5;
    font-size: 18px; }
    .page-cms #djv-two-columns-layer a:hover {
      text-decoration: underline; }
  .page-cms #djv-two-columns-layer .btn-cms {
    display: block;
    font-size: 20px;
    color: #ffffff;
    background-color: #015f9b;
    padding: 15px 35px 15px 35px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 220px;
    text-align: center; }
    .page-cms #djv-two-columns-layer .btn-cms a {
      color: white; }
  .page-cms #djv-two-columns-layer img.full {
    max-width: 100vw;
    width: 100%; }
    @media (max-width: 768px) {
      .page-cms #djv-two-columns-layer img.full {
        height: auto; } }

#footer {
  margin-top: 50px;
  background-color: #e1eef7; }
  @media (max-width: 1024px) {
    #footer {
      margin-top: 30px; } }
  #footer .block-contact {
    padding: 5px;
    background-color: #76a9cc;
    font-size: 20px;
    color: white; }
    #footer .block-contact .block-contact-container {
      margin-right: auto;
      margin-left: auto;
      max-width: 1170px;
      min-width: 920px;
      display: flex;
      padding: 6px 53px;
      justify-content: space-between; }
      @media (max-width: 1024px) {
        #footer .block-contact .block-contact-container {
          max-width: 920px;
          min-width: 750px; } }
      @media (max-width: 768px) {
        #footer .block-contact .block-contact-container {
          max-width: 750px;
          min-width: 480px; } }
      @media (max-width: 480px) {
        #footer .block-contact .block-contact-container {
          max-width: 500px;
          min-width: 300px; } }
      @media (max-width: 1024px) {
        #footer .block-contact .block-contact-container {
          display: block;
          padding: 10px; } }
      #footer .block-contact .block-contact-container [class*='djv-icon']:before {
        margin: 0 6px 0 0;
        position: relative;
        top: 2px; }
      #footer .block-contact .block-contact-container a {
        color: white; }
      #footer .block-contact .block-contact-container p {
        margin-bottom: 0; }
  #footer .djv-footer-navigation {
    color: black;
    margin-top: 15px;
    margin-bottom: 0; }
    #footer .djv-footer-navigation .djv-footer-container {
      margin-right: auto;
      margin-left: auto;
      max-width: 1170px;
      min-width: 920px;
      display: grid;
      grid-template-columns: 50% 40% auto;
      padding: 0 52px; }
      @media (max-width: 1024px) {
        #footer .djv-footer-navigation .djv-footer-container {
          max-width: 920px;
          min-width: 750px; } }
      @media (max-width: 768px) {
        #footer .djv-footer-navigation .djv-footer-container {
          max-width: 750px;
          min-width: 480px; } }
      @media (max-width: 480px) {
        #footer .djv-footer-navigation .djv-footer-container {
          max-width: 500px;
          min-width: 300px; } }
      @media (max-width: 768px) {
        #footer .djv-footer-navigation .djv-footer-container {
          display: block;
          padding: 10px; } }
    #footer .djv-footer-navigation .djv-footer-navigation-container {
      display: grid;
      grid-template-columns: 50% 50%;
      grid-gap: 0 24px;
      max-width: 520px;
      min-width: 520px;
      margin-left: 50px; }
      @media (max-width: 768px) {
        #footer .djv-footer-navigation .djv-footer-navigation-container {
          display: block; } }
      #footer .djv-footer-navigation .djv-footer-navigation-container h4 {
        font-weight: 500;
        font-size: 20px;
        margin-bottom: 5px; }
      #footer .djv-footer-navigation .djv-footer-navigation-container ul {
        padding: 0; }
        #footer .djv-footer-navigation .djv-footer-navigation-container ul li {
          list-style: none;
          font-weight: 300;
          font-size: 16px;
          margin-bottom: 6px; }
      #footer .djv-footer-navigation .djv-footer-navigation-container .formas-pago {
        text-align: center;
        grid-column-start: 1;
        grid-column-end: 3;
        margin-top: 22px; }
  #footer .djv-email-subscription {
    padding: 10px 50px 0 0; }
    @media (max-width: 768px) {
      #footer .djv-email-subscription {
        padding: 20px; } }
    #footer .djv-email-subscription .djv-email-subscription-container .djv-email-subscription-logo {
      display: none; }
      #footer .djv-email-subscription .djv-email-subscription-container .djv-email-subscription-logo img {
        width: 156px;
        margin-bottom: 10px; }
    #footer .djv-email-subscription .djv-email-subscription-container .email-subcription-text {
      position: relative; }
      #footer .djv-email-subscription .djv-email-subscription-container .email-subcription-text h4 {
        display: inline-block;
        margin-bottom: 5px;
        font-weight: 700;
        font-size: 20px;
        color: black;
        text-transform: uppercase; }
      #footer .djv-email-subscription .djv-email-subscription-container .email-subcription-text p {
        font-weight: 500;
        font-size: 14px;
        color: black; }
      #footer .djv-email-subscription .djv-email-subscription-container .email-subcription-text p.notification {
        position: absolute;
        left: 0;
        width: 350px;
        top: 133px;
        background-color: white;
        border: 3px solid;
        padding: 6px; }
        #footer .djv-email-subscription .djv-email-subscription-container .email-subcription-text p.notification.notification-success {
          color: #1a7e10; }
          @media (max-width: 768px) {
            #footer .djv-email-subscription .djv-email-subscription-container .email-subcription-text p.notification.notification-success {
              margin-bottom: 0; } }
        #footer .djv-email-subscription .djv-email-subscription-container .email-subcription-text p.notification.notification-error {
          color: #D53128; }
          #footer .djv-email-subscription .djv-email-subscription-container .email-subcription-text p.notification.notification-error::before {
            content: "* ";
            font-size: 16px; }
          @media (max-width: 768px) {
            #footer .djv-email-subscription .djv-email-subscription-container .email-subcription-text p.notification.notification-error {
              right: 0;
              margin-bottom: 0; } }
    #footer .djv-email-subscription .djv-email-subscription-container .djv-email-subscription-form {
      padding: 0px 5px 0 0; }
      #footer .djv-email-subscription .djv-email-subscription-container .djv-email-subscription-form .input-submit {
        display: grid;
        grid-template-columns: 75% 25%;
        margin: 10px auto; }
        #footer .djv-email-subscription .djv-email-subscription-container .djv-email-subscription-form .input-submit.accept {
          text-align: center; }
      #footer .djv-email-subscription .djv-email-subscription-container .djv-email-subscription-form input[type="text"] {
        border: 0;
        padding: 10px 10px;
        font-size: 12px;
        font-weight: 300;
        background-color: white; }
      #footer .djv-email-subscription .djv-email-subscription-container .djv-email-subscription-form input[type="checkbox"] {
        border: 1px solid #dddddd; }
      #footer .djv-email-subscription .djv-email-subscription-container .djv-email-subscription-form input[type="submit"] {
        background-color: #93ccfa;
        color: white;
        border: 2px solid #93ccfa;
        border-radius: 0;
        transition: all 500ms;
        text-align: center;
        text-transform: uppercase;
        padding: 10px 10px;
        cursor: pointer;
        font-family: "Lato", sans-serif;
        font-weight: 700;
        font-size: 12px; }
        #footer .djv-email-subscription .djv-email-subscription-container .djv-email-subscription-form input[type="submit"]:hover {
          background-color: white;
          color: #93ccfa; }
      #footer .djv-email-subscription .djv-email-subscription-container .djv-email-subscription-form .control-label {
        font-size: 11px;
        margin: 10px 0 0 10px; }
        #footer .djv-email-subscription .djv-email-subscription-container .djv-email-subscription-form .control-label a {
          font-family: "Lato", sans-serif;
          font-weight: 400; }
      #footer .djv-email-subscription .djv-email-subscription-container .djv-email-subscription-form .accept-policy {
        display: block; }
    #footer .djv-email-subscription .djv-email-subscription-container .notification-error {
      position: relative;
      right: -453px;
      width: 350px;
      color: #00ff00; }
  #footer .footer-below, #footer .checkout-footer-simplified {
    margin-top: 30px;
    padding: 10px 16px;
    font-family: "Lato", sans-serif;
    font-weight: 300;
    font-size: 11px;
    text-align: center; }
    #footer .footer-below a, #footer .checkout-footer-simplified a {
      font-weight: 600;
      color: #dbebf5;
      text-decoration: underline; }

#left-column #left-responsive-switch {
  display: none;
  color: #93ccfa;
  font-size: 12px; }
  @media (max-width: 1024px) {
    #left-column #left-responsive-switch {
      display: block;
      padding: 6px;
      border-radius: 3px;
      background-color: #93ccfa;
      color: white;
      text-align: center;
      cursor: pointer; }
      #left-column #left-responsive-switch i {
        float: right;
        margin-right: 4px;
        margin-top: 3px; } }

#left-column .djv-navigator-responsive-filter-button {
  display: none;
  color: #93ccfa;
  font-size: 12px; }
  @media (max-width: 1024px) {
    #left-column .djv-navigator-responsive-filter-button {
      display: block;
      position: relative;
      background-color: #93ccfa;
      color: white;
      text-align: center;
      padding: 10px;
      border-radius: 5px;
      cursor: pointer; }
      #left-column .djv-navigator-responsive-filter-button i {
        position: absolute;
        right: 8px;
        top: 8px;
        background-color: white;
        padding: 5px;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        color: #93ccfa;
        text-align: center; } }

#left-column #djv-navigation-left {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-size: 14px; }
  @media (max-width: 1024px) {
    #left-column #djv-navigation-left {
      display: none;
      position: fixed;
      top: 0;
      background-color: white;
      z-index: 100;
      width: 100%;
      left: 0;
      padding: 10px;
      overflow-y: auto;
      height: 100vh; } }
  #left-column #djv-navigation-left h2 {
    display: none; }
    @media (max-width: 768px) {
      #left-column #djv-navigation-left h2 {
        display: block; } }
  #left-column #djv-navigation-left > div {
    border: 1px solid #84b3d1;
    padding: 0px;
    margin-bottom: 0px;
    max-height: 350px;
    overflow-y: auto;
    scrollbar-color: #dbebf5 white;
    scrollbar-width: thin; }
    #left-column #djv-navigation-left > div::-webkit-scrollbar {
      width: 10px;
      border-left: 1px solid #EAEAEA; }
    #left-column #djv-navigation-left > div::-webkit-scrollbar-track {
      background: white;
      border-radius: 6px; }
    #left-column #djv-navigation-left > div::-webkit-scrollbar-thumb {
      background: #dbebf5;
      border-radius: 6px; }
    #left-column #djv-navigation-left > div::-webkit-scrollbar-thumb:hover {
      background: #dbebf5; }
    #left-column #djv-navigation-left > div > div {
      padding: 10px; }
    #left-column #djv-navigation-left > div h3 {
      font-family: "Lato", sans-serif;
      font-weight: 700;
      font-size: 18px;
      background-color: #84b3d1;
      color: black;
      padding: 7px;
      margin-bottom: 5px;
      margin-top: 0px; }
      #left-column #djv-navigation-left > div h3 span {
        display: none; }
    #left-column #djv-navigation-left > div ul {
      margin-left: 0px;
      padding-left: 0;
      margin-bottom: 0; }
      #left-column #djv-navigation-left > div ul li {
        list-style: none;
        margin-bottom: 0;
        font-size: 12px;
        padding: 1px; }
        #left-column #djv-navigation-left > div ul li a {
          padding: 2px;
          color: #333333; }
        #left-column #djv-navigation-left > div ul li span {
          text-align: center;
          padding: 1px;
          font-size: 10px;
          color: #666666; }
        #left-column #djv-navigation-left > div ul li::before {
          font-family: "comunes";
          font-style: normal;
          font-weight: normal;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: '\004c';
          font-size: 9px;
          padding-top: 6px;
          color: #0a3855; }
    #left-column #djv-navigation-left > div.filter-type {
      border: 0px;
      background-color: #dbebf5;
      color: white;
      padding: 16px; }
      #left-column #djv-navigation-left > div.filter-type h3 {
        color: white;
        border-bottom: 1px solid white; }
      #left-column #djv-navigation-left > div.filter-type ul li a {
        color: white; }
      #left-column #djv-navigation-left > div.filter-type ul li span {
        color: white; }
  #left-column #djv-navigation-left .djv_navigation-price .djv_navigation-price-slider-range {
    margin-bottom: 15px;
    font-size: 14px; }
    @media (max-width: 768px) {
      #left-column #djv-navigation-left .djv_navigation-price .djv_navigation-price-slider-range {
        font-size: 16px; } }
  #left-column #djv-navigation-left .djv_navigation-price .noUi-base, #left-column #djv-navigation-left .djv_navigation-price .noUi-connects {
    height: 2px;
    background: #EAEAEA; }
  #left-column #djv-navigation-left .djv_navigation-price .noUi-target {
    background: white;
    border-radius: 0px;
    border: none;
    box-shadow: none;
    height: 2px; }
  #left-column #djv-navigation-left .djv_navigation-price .djv_navigation-price-slider {
    margin-bottom: 20px;
    width: 90%;
    margin-left: 5%;
    margin-bottom: 20px; }
    #left-column #djv-navigation-left .djv_navigation-price .djv_navigation-price-slider .noUi-connect {
      background: #93ccfa; }
    #left-column #djv-navigation-left .djv_navigation-price .djv_navigation-price-slider .noUi-handle {
      border: none;
      border-radius: 50px;
      background: #93ccfa;
      cursor: default;
      box-shadow: none;
      width: 14px;
      height: 14px; }
    #left-column #djv-navigation-left .djv_navigation-price .djv_navigation-price-slider .noUi-handle::after, #left-column #djv-navigation-left .djv_navigation-price .djv_navigation-price-slider .noUi-handle::before {
      content: "";
      display: none; }
  #left-column #djv-navigation-left .djv_navigation-price .djv_navigation-price-apply-button {
    background-color: #dbebf5;
    color: white;
    border: 2px solid #dbebf5;
    border-radius: 50px;
    transition: all 500ms;
    text-align: center;
    text-transform: uppercase;
    padding: 20px 5px;
    cursor: pointer;
    display: block;
    cursor: pointer;
    width: 60%;
    margin: 20px auto;
    padding: 10px;
    border-radius: 20px;
    font-size: 12px;
    color: white; }
    #left-column #djv-navigation-left .djv_navigation-price .djv_navigation-price-apply-button:hover {
      background-color: white;
      color: #dbebf5; }
    #left-column #djv-navigation-left .djv_navigation-price .djv_navigation-price-apply-button:hover {
      background-color: white; }
  #left-column #djv-navigation-left .djv-navigation-left-selected-item a::before {
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: white;
    background-color: red;
    padding: 4px;
    border-radius: 50px;
    font-size: 8px;
    font-weight: bold;
    margin-right: 5px; }
  #left-column #djv-navigation-left .djv-navigation-left-nofilter-selected {
    color: #cccccc; }
  #left-column #djv-navigation-left #djv-icon-close-filters {
    display: none;
    float: right;
    font-size: 12px;
    font-style: normal;
    font-family: "Lato", sans-serif;
    font-weight: 300;
    cursor: pointer;
    background-color: #D53128;
    color: white;
    padding: 5px;
    border-radius: 5px; }
    #left-column #djv-navigation-left #djv-icon-close-filters::before {
      display: inline-block;
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: #D53128;
      background-color: white;
      padding: 4px;
      border-radius: 50px;
      font-size: 11px;
      font-weight: bold;
      margin-right: 0px; }
    @media (max-width: 576px) {
      #left-column #djv-navigation-left #djv-icon-close-filters {
        display: inline-block;
        padding: 7px;
        position: absolute;
        right: 11px;
        top: 11px; } }

#left-column .djv-information-delivery {
  padding: 15px 20px;
  border: 1px solid #aaaaaa;
  margin-bottom: 20px;
  line-height: 22px; }
  @media (max-width: 1024px) {
    #left-column .djv-information-delivery {
      display: none; } }
  #left-column .djv-information-delivery i {
    font-size: 30px;
    color: #333333;
    float: left;
    margin-right: 6px;
    margin-top: 2px; }
  #left-column .djv-information-delivery p {
    font-size: 10.8px;
    color: #666666;
    margin-bottom: auto;
    padding-left: 32px; }

#left-column .djv-need-help {
  background-color: #0a3855;
  padding: 15px 10px;
  position: relative;
  margin-top: 20px; }
  #left-column .djv-need-help h3 {
    text-transform: uppercase;
    color: #444444;
    font-size: 14px;
    font-weight: 600; }
  #left-column .djv-need-help .content-block h4 {
    font-size: 12px;
    margin-bottom: 0;
    margin-top: 15px; }
    #left-column .djv-need-help .content-block h4 .icon {
      width: 20px;
      margin-top: -3px; }
    #left-column .djv-need-help .content-block h4 a {
      color: #dbebf5;
      font-weight: 600; }
  #left-column .djv-need-help .content-block p {
    padding-left: 23px; }
  #left-column .djv-need-help .content-block ul {
    padding-left: 37px;
    margin-top: 7px; }
    #left-column .djv-need-help .content-block ul li {
      margin-bottom: 7px; }
      #left-column .djv-need-help .content-block ul li strong, #left-column .djv-need-help .content-block ul li a {
        color: #333333; }

#left-column-filters-sticky {
  position: sticky;
  float: left;
  top: 100px; }
  @media (max-width: 768px) {
    #left-column-filters-sticky {
      position: relative;
      float: none;
      top: 0; } }

#djv-navigation-left .djv_navigation-left-filters .djv_navigation-left-filters-header {
  display: grid;
  grid-template-columns: 80% 20%; }
  #djv-navigation-left .djv_navigation-left-filters .djv_navigation-left-filters-header span {
    font-size: 11px;
    color: #cccccc;
    padding-top: 8px; }
  #djv-navigation-left .djv_navigation-left-filters .djv_navigation-left-filters-header span.title {
    font-family: "Oswald-Medium";
    font-size: 22px;
    margin-bottom: 20px;
    color: #333333;
    padding-top: 0; }

#djv-navigation-left .djv_navigation-left-filters li {
  display: grid;
  grid-template-columns: 80% 20%;
  margin-bottom: 0;
  border-bottom: 1px solid #EAEAEA;
  font-size: 12px;
  background-color: rgba(130, 130, 130, 0);
  transition: background-color 750ms;
  padding: 1px; }
  #djv-navigation-left .djv_navigation-left-filters li a {
    padding: 2px; }
  #djv-navigation-left .djv_navigation-left-filters li span {
    display: none;
    text-align: center;
    padding: 1px;
    font-size: 11px;
    color: #cccccc; }
  #djv-navigation-left .djv_navigation-left-filters li:hover {
    background-color: whitesmoke; }

#my-account .link-list {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  padding-left: 0;
  display: grid;
  grid-template-columns: calc(33% - 10px) calc(33% - 10px) calc(33% - 10px);
  grid-gap: 10px; }
  @media (max-width: 576px) {
    #my-account .link-list {
      display: block; } }
  #my-account .link-list li {
    list-style: none;
    padding: 10px;
    background-color: #dbebf5;
    transition: all 500ms; }
    @media (max-width: 576px) {
      #my-account .link-list li {
        margin-bottom: 10px; } }
    #my-account .link-list li a {
      color: #015f9b; }
    #my-account .link-list li:hover {
      background-color: #015f9b; }
      #my-account .link-list li:hover a {
        color: white; }
  #my-account .link-list .order-slips-link, #my-account .link-list .identity-link, #my-account .link-list .discounts-link {
    display: none; }

#my-account .page-my-account header {
  margin-bottom: 16px; }
  #my-account .page-my-account header h1 {
    font-size: 18px; }

@media (max-width: 1024px) {
  #order-detail {
    font-size: 10px; } }

#order-detail #main {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px;
  margin-top: 40px; }
  @media (max-width: 1024px) {
    #order-detail #main {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #order-detail #main {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #order-detail #main {
      max-width: 500px;
      min-width: 300px; } }

#order-detail #order-infos {
  color: #444444; }
  #order-detail #order-infos a {
    background-color: #1a7e10;
    color: white;
    border: 2px solid #1a7e10;
    border-radius: 50px;
    transition: all 500ms;
    text-align: center;
    text-transform: uppercase;
    padding: 20px 5px;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 15px;
    text-decoration: none; }
    #order-detail #order-infos a:hover {
      background-color: white;
      color: #1a7e10; }

#order-detail #order-history {
  padding: 16px;
  margin-bottom: 16px; }
  @media (max-width: 1024px) {
    #order-detail #order-history {
      padding: 16px 6px; } }
  @media (max-width: 1024px) {
    #order-detail #order-history h1 {
      font-size: 16px; } }
  #order-detail #order-history table thead {
    border-bottom: 1px solid #999; }
  #order-detail #order-history table td, #order-detail #order-history table th {
    padding: 8px; }
    @media (max-width: 1024px) {
      #order-detail #order-history table td, #order-detail #order-history table th {
        font-size: 10px; } }
    #order-detail #order-history table td .order-status-label, #order-detail #order-history table th .order-status-label {
      padding: 4px 8px;
      font-size: 14px;
      white-space: nowrap;
      color: white; }
      @media (max-width: 1024px) {
        #order-detail #order-history table td .order-status-label, #order-detail #order-history table th .order-status-label {
          font-size: 10px; } }

#order-detail #delivery-address, #order-detail #invoice-address {
  width: calc(50% - 20px);
  margin: 8px;
  display: inline-block;
  border: 1px dashed #999;
  padding: 16px; }
  @media (max-width: 576px) {
    #order-detail #delivery-address, #order-detail #invoice-address {
      width: calc(100% - 20px); }
      #order-detail #delivery-address h1, #order-detail #invoice-address h1 {
        font-size: 16px; } }

#order-detail .box ul {
  margin: 10px 0;
  padding: 0; }
  #order-detail .box ul li {
    list-style: none; }

#order-detail #order-products {
  margin: 40px 0; }
  #order-detail #order-products th {
    white-space: nowrap;
    padding: 8px;
    background-color: #dddddd; }
    @media (max-width: 576px) {
      #order-detail #order-products th {
        font-size: 9px;
        white-space: normal; } }
  #order-detail #order-products td {
    padding: 8px; }
    @media (max-width: 576px) {
      #order-detail #order-products td {
        font-size: 9px; } }
  #order-detail #order-products tfoot, #order-detail #order-products .line-total {
    border-top: 2px solid #999999; }

#order-detail #order-shipping {
  display: block;
  width: 100%; }
  #order-detail #order-shipping tr {
    width: 100%; }
    @media (max-width: 576px) {
      #order-detail #order-shipping tr {
        font-size: 9px; } }
    #order-detail #order-shipping tr td {
      padding: 8px; }
      @media (max-width: 576px) {
        #order-detail #order-shipping tr td {
          font-size: 9px; } }
    #order-detail #order-shipping tr th {
      white-space: nowrap;
      padding: 8px;
      background-color: #dddddd; }
      @media (max-width: 576px) {
        #order-detail #order-shipping tr th {
          white-space: normal; } }

#order-detail .order-message-form {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #777777; }
  @media (max-width: 1024px) {
    #order-detail .order-message-form {
      padding: 10px; }
      #order-detail .order-message-form h1 {
        font-size: 16px; } }
  #order-detail .order-message-form .form-fields {
    /*
            label{
                display: block;
                margin-bottom: 15px;
                span{
                    margin-right: 10px;
                }
                @media(max-width: $screen-lg){
                    textarea, select{
                        width: 100%;
                    }
                }
            }*/ }
  #order-detail .order-message-form .form-footer button {
    background-color: #1a7e10;
    color: white;
    border: 2px solid #1a7e10;
    border-radius: 50px;
    transition: all 500ms;
    text-align: center;
    text-transform: uppercase;
    padding: 20px 5px;
    cursor: pointer; }
    #order-detail .order-message-form .form-footer button:hover {
      background-color: white;
      color: #1a7e10; }

.page-footer {
  padding: 0;
  margin-top: 14px; }
  .page-footer ul {
    padding-left: 0;
    margin-left: 0;
    margin-top: 20px; }
  .page-footer li {
    display: inline-block;
    list-style: none; }
    @media (max-width: 1024px) {
      .page-footer li {
        display: block;
        margin-bottom: 10px; } }
    .page-footer li a {
      background-color: #015f9b;
      color: white;
      border: 2px solid #015f9b;
      border-radius: 0px;
      transition: all 500ms;
      text-align: center;
      text-transform: uppercase;
      padding: 10px 10px;
      cursor: pointer;
      font-size: 11px;
      margin-right: 10px; }
      .page-footer li a:hover {
        background-color: white;
        color: #015f9b; }
      @media (max-width: 1024px) {
        .page-footer li a {
          width: 100%;
          position: relative;
          display: block; } }

#addresses #main {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px; }
  @media (max-width: 1024px) {
    #addresses #main {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #addresses #main {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #addresses #main {
      max-width: 500px;
      min-width: 300px; } }
  #addresses #main .page-addresses {
    display: grid;
    grid-template-columns: calc(33% - 16px) calc(33% - 16px) calc(33% - 16px);
    grid-gap: 16px; }
    @media (max-width: 1024px) {
      #addresses #main .page-addresses {
        display: block; } }
    #addresses #main .page-addresses article {
      border: 1px dashed #dadada;
      padding: 16px;
      position: relative; }
      @media (max-width: 1024px) {
        #addresses #main .page-addresses article {
          padding: 8px; } }
      #addresses #main .page-addresses article h3 {
        font-weight: 600;
        font-size: 14px; }
      #addresses #main .page-addresses article .text-block {
        display: block;
        margin: 5px auto;
        padding: 10px 0;
        border-top: 1px solid #cccccc;
        border-bottom: 1px solid #cccccc; }
        #addresses #main .page-addresses article .text-block p {
          margin: 5px;
          line-height: normal; }
        #addresses #main .page-addresses article .text-block .name {
          font-weight: 600; }
      #addresses #main .page-addresses article .action-list {
        padding-left: 0;
        margin-bottom: 0;
        margin-top: 16px; }
        #addresses #main .page-addresses article .action-list li {
          display: inline-block; }
          @media (max-width: 1024px) {
            #addresses #main .page-addresses article .action-list li {
              width: 49%; } }
          #addresses #main .page-addresses article .action-list li a {
            display: block;
            padding: 3px;
            text-align: center;
            color: white;
            font-size: 10px;
            width: 100%; }
            #addresses #main .page-addresses article .action-list li a[data-link-action="edit-address"] {
              background-color: #015f9b;
              color: white;
              border: 2px solid #015f9b;
              border-radius: 0px;
              transition: all 500ms;
              text-align: center;
              text-transform: uppercase;
              padding: 10px 10px;
              cursor: pointer; }
              #addresses #main .page-addresses article .action-list li a[data-link-action="edit-address"]:hover {
                background-color: white;
                color: #015f9b; }
            #addresses #main .page-addresses article .action-list li a[data-link-action="delete-address"] {
              background-color: #D53128;
              color: white;
              border: 2px solid #D53128;
              border-radius: 0px;
              transition: all 500ms;
              text-align: center;
              text-transform: uppercase;
              padding: 10px 10px;
              cursor: pointer; }
              #addresses #main .page-addresses article .action-list li a[data-link-action="delete-address"]:hover {
                background-color: white;
                color: #D53128; }
  #addresses #main .page-footer {
    border-top: 1px dashed #cccccc;
    padding-top: 10px;
    margin-top: 10px; }
    @media (max-width: 1024px) {
      #addresses #main .page-footer {
        border: none;
        padding: 0 10px; } }

#address #main {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px; }
  @media (max-width: 1024px) {
    #address #main {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #address #main {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #address #main {
      max-width: 500px;
      min-width: 300px; } }
  #address #main .form-fields {
    /*
            @media(max-width: $screen-lg){
                display: block;
            }
            label{
                display: block;
                @media(max-width: $screen-lg){
                    width: 100%;
                    display:block;
                    margin-bottom: 20px;
                }
                span{
                    display: inline-block;
                    width: 25%;
                    @media(max-width: $screen-lg){
                        display: block;
                        width: 100%;
                    }
                }
                input, select{
                    border: none;
                    background-color: #eee;
                    padding: 5px;
                    display: inline-block;
                    width: 70%;
                    @media(max-width: $screen-lg){
                        display: block;
                        width: 100%;
                    }
                }
                input[name="esempresa"]{
                    width: 25px;
                    @media(max-width: $screen-lg){
                        display: block;
                        width: 100%;
                    }
                }
            }
            &::after{
                content: "Los campos marcados con un asterisco son obligatorios";
            }*/ }
  #address #main .form-footer {
    border-top: 1px solid #cccccc;
    padding-top: 10px;
    margin-top: 10px; }
    #address #main .form-footer button {
      background-color: #1a7e10;
      color: white;
      border: 2px solid #1a7e10;
      border-radius: 50px;
      transition: all 500ms;
      text-align: center;
      text-transform: uppercase;
      padding: 10px 10px;
      cursor: pointer;
      font-size: 12px; }
      #address #main .form-footer button:hover {
        background-color: white;
        color: #1a7e10; }
  #address #main .page-footer {
    border-top: 1px solid #cccccc;
    padding-top: 10px;
    margin-top: 10px; }

#order-confirmation #main {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px;
  font-family: "Lato", sans-serif;
  font-weight: 300; }
  @media (max-width: 1024px) {
    #order-confirmation #main {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #order-confirmation #main {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #order-confirmation #main {
      max-width: 500px;
      min-width: 300px; } }
  @media (max-width: 768px) {
    #order-confirmation #main {
      padding: 10px; } }
  #order-confirmation #main h3 {
    font-family: "Lato", sans-serif;
    font-weight: 300; }
  #order-confirmation #main hr {
    border-top: 3px solid #015f9b;
    margin: 30px 0; }
  #order-confirmation #main #order-items .djv-order-confirmation-items-table tr {
    border-bottom: 1px solid #015f9b; }
    @media (max-width: 576px) {
      #order-confirmation #main #order-items .djv-order-confirmation-items-table tr {
        border-bottom: 3px solid #015f9b; } }
    #order-confirmation #main #order-items .djv-order-confirmation-items-table tr td {
      padding: 30px;
      border-right: 1px dotted #cccccc;
      position: relative; }
      @media (max-width: 576px) {
        #order-confirmation #main #order-items .djv-order-confirmation-items-table tr td {
          display: block;
          padding: 10px;
          border-right: none;
          text-align: center; } }
      #order-confirmation #main #order-items .djv-order-confirmation-items-table tr td.djv-order-confirmation-quantity {
        font-size: 22px; }
        @media (max-width: 576px) {
          #order-confirmation #main #order-items .djv-order-confirmation-items-table tr td.djv-order-confirmation-quantity {
            display: inline-block;
            width: calc(50% - 10px); } }
      #order-confirmation #main #order-items .djv-order-confirmation-items-table tr td.djv-order-confirmation-price {
        font-size: 22px; }
        @media (max-width: 576px) {
          #order-confirmation #main #order-items .djv-order-confirmation-items-table tr td.djv-order-confirmation-price {
            display: inline-block;
            width: calc(50% - 10px); } }
      #order-confirmation #main #order-items .djv-order-confirmation-items-table tr td:last-of-type {
        border-right: none; }
      #order-confirmation #main #order-items .djv-order-confirmation-items-table tr td img {
        width: 100%; }
        @media (max-width: 576px) {
          #order-confirmation #main #order-items .djv-order-confirmation-items-table tr td img {
            width: auto;
            margin-left: auto;
            margin-right: auto; } }
      @media (max-width: 576px) {
        #order-confirmation #main #order-items .djv-order-confirmation-items-table tr td:nth-of-type(2) {
          border-top: 1px dotted #015f9b;
          border-bottom: 1px dotted #015f9b;
          text-align: left; } }
    #order-confirmation #main #order-items .djv-order-confirmation-items-table tr:last-of-type {
      border-bottom: none; }
  #order-confirmation #main #order-items .djv-order-confirmation-resume-table tr {
    border-bottom: 1px dotted #015f9b; }
    #order-confirmation #main #order-items .djv-order-confirmation-resume-table tr td {
      padding: 16px 30px; }
    #order-confirmation #main #order-items .djv-order-confirmation-resume-table tr:last-of-type {
      border-bottom: none;
      background-color: #015f9b;
      font-size: 16px;
      color: white; }
  #order-confirmation #main #registration-form #customer-form .form-fields {
    /*
                    column-count: 3;
                    label{
                        span{
                            display: block;
                        }
                    }*/ }

#identity #main {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px;
  font-family: "Lato", sans-serif;
  font-weight: 300; }
  @media (max-width: 1024px) {
    #identity #main {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #identity #main {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #identity #main {
      max-width: 500px;
      min-width: 300px; } }
  #identity #main h3 {
    font-family: "Lato", sans-serif;
    font-weight: 300; }
  #identity #main #customer-form .form-fields {
    /*
                width: auto;
                padding-left: 10px;

                @media(max-width: $screen-lg){
                    display: block;
                }
                label{
                    margin-bottom: 10px;
                    display: block;
                    width: 50%;
                    min-width: 320px;
                    @media(max-width: $screen-lg){
                        display: block;
                        width: 100%;
                        margin-bottom: 15px;
                    }
                    .title-label{
                        font-weight: 600;
                        display: block;
                        margin-bottom: 5px;
                    }
                    input:not([type=radio]),input:not([type=checkbox]), select{
                        border: none;
                        background-color: #eee;
                        padding: 5px;
                        width: 100%;
                    }
                    input[type="radio"],input[type="checkbox"]{
                        display: inline-block;
                        margin: 0 5px;
                        width: auto;
                        vertical-align: top;
                    }
                }
                .checkbox-field{
                    .title-label{
                        display: inline-block;
                        width: 90%;
                        font-weight: 400;
                    }
                }
                .id_gender{
                    display: none;
                }
                .newsletter{
                    width: 100%;
                    em{
                        font-style: normal;
                        font-size: $font-xs;
                    }
                }
                .psgdpr{
                    width: 100%;
                }*/ }
  #identity #main .form-footer [data-link-action="save-customer"] {
    background-color: #1a7e10;
    color: white;
    border: 2px solid #1a7e10;
    border-radius: 50px;
    transition: all 500ms;
    text-align: center;
    text-transform: uppercase;
    padding: 10px 10px;
    cursor: pointer;
    font-size: 12px; }
    #identity #main .form-footer [data-link-action="save-customer"]:hover {
      background-color: white;
      color: #1a7e10; }
    @media (max-width: 1024px) {
      #identity #main .form-footer [data-link-action="save-customer"] {
        width: 100%;
        display: block;
        margin-top: 15px; } }
  #identity #main .page-footer {
    border-top: 1px solid #cccccc;
    padding-top: 10px;
    margin-top: 10px; }

#history .page-content {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px; }
  @media (max-width: 1024px) {
    #history .page-content {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #history .page-content {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #history .page-content {
      max-width: 500px;
      min-width: 300px; } }
  #history .page-content h1 {
    font-size: 18px; }
  #history .page-content h2 {
    font-size: 14px;
    font-weight: 400;
    border-bottom: 1px dashed #cccccc;
    padding-bottom: 10px;
    margin-bottom: 10px;
    text-align: left;
    margin-bottom: 20px; }
  #history .page-content .history-list .history-list-header {
    display: grid;
    grid-template-columns: 15% 8% 13% 22% repeat(2, 14%) 14%;
    border-bottom: solid 1px black;
    padding-bottom: 10px;
    margin-bottom: 0px; }
    @media (max-width: 576px) {
      #history .page-content .history-list .history-list-header {
        display: none; } }
    #history .page-content .history-list .history-list-header > div {
      font-family: "Lato", sans-serif;
      font-weight: 400;
      font-size: 12px; }
  #history .page-content .history-list .history-list-body .history-list-body-item {
    display: grid;
    grid-template-columns: 15% 8% 13% 22% repeat(2, 14%) 14%;
    padding: 20px 0;
    border-bottom: 1px solid #dddddd; }
    @media (max-width: 576px) {
      #history .page-content .history-list .history-list-body .history-list-body-item {
        display: block; } }
    #history .page-content .history-list .history-list-body .history-list-body-item .history-list-body-item-col {
      align-content: center;
      font-size: 10px; }
      @media (max-width: 1024px) {
        #history .page-content .history-list .history-list-body .history-list-body-item .history-list-body-item-col {
          margin-bottom: 10px;
          text-align: left; } }
      #history .page-content .history-list .history-list-body .history-list-body-item .history-list-body-item-col .etiqueta {
        display: none; }
        @media (max-width: 1024px) {
          #history .page-content .history-list .history-list-body .history-list-body-item .history-list-body-item-col .etiqueta {
            display: inline-block;
            font-weight: 600;
            margin-right: 10px; } }
      #history .page-content .history-list .history-list-body .history-list-body-item .history-list-body-item-col [data-link-action="view-order-details"] {
        background-color: #dbebf5;
        color: white;
        border: 2px solid #dbebf5;
        border-radius: 5px;
        transition: all 500ms;
        text-align: center;
        text-transform: uppercase;
        padding: 5px 5px;
        cursor: pointer;
        display: inline-block;
        margin-bottom: 8px;
        font-size: 9px; }
        #history .page-content .history-list .history-list-body .history-list-body-item .history-list-body-item-col [data-link-action="view-order-details"]:hover {
          background-color: white;
          color: #dbebf5; }
      #history .page-content .history-list .history-list-body .history-list-body-item .history-list-body-item-col .order-reorder-link {
        background-color: #1a7e10;
        color: white;
        border: 2px solid #1a7e10;
        border-radius: 5px;
        transition: all 500ms;
        text-align: center;
        text-transform: uppercase;
        padding: 5px 5px;
        cursor: pointer;
        display: inline-block;
        font-size: 9px; }
        #history .page-content .history-list .history-list-body .history-list-body-item .history-list-body-item-col .order-reorder-link:hover {
          background-color: white;
          color: #1a7e10; }
      #history .page-content .history-list .history-list-body .history-list-body-item .history-list-body-item-col .order-status-label {
        font-size: 9px;
        white-space: nowrap;
        color: white;
        padding: 5px;
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        text-align: center; }
  #history .page-content .page-footer {
    margin-top: 40px;
    padding-top: 20px;
    border-top: 1px dashed #999; }

.form-fields {
  margin-top: 30px; }
  .form-fields label:not(.checkbox-field) {
    display: block;
    max-width: 100%;
    margin-bottom: 20px;
    position: relative;
    max-height: 70px; }
    @media (max-width: 768px) {
      .form-fields label:not(.checkbox-field) {
        max-width: 100%; } }
    .form-fields label:not(.checkbox-field) span {
      display: block;
      margin: 10px 0; }
    .form-fields label:not(.checkbox-field) input {
      border: none;
      background-color: #eee;
      padding: 10px;
      width: 100%;
      font-size: 18px; }
      @media (max-width: 576px) {
        .form-fields label:not(.checkbox-field) input {
          width: 100%; } }
  .form-fields label.checkbox-field {
    display: flex;
    margin-bottom: 15px;
    align-items: flex-start; }
    .form-fields label.checkbox-field span {
      margin-left: 5px;
      display: block; }
      .form-fields label.checkbox-field span em {
        font-size: 11px;
        color: #444444;
        font-style: normal; }
  .form-fields label.radio-field {
    display: none; }
    .form-fields label.radio-field label {
      display: inline-block;
      width: auto;
      margin-right: 5px;
      margin-bottom: 15px; }
      .form-fields label.radio-field label input {
        display: inline-block;
        width: auto;
        margin-right: 5px; }
  .form-fields label.same-address {
    align-items: center; }
  .form-fields .passwordCloseEye, .form-fields .passwordOpenEye {
    position: absolute;
    top: 28px;
    margin-top: 0 !important;
    left: calc(100% - 42px);
    height: 42px;
    width: 42px !important;
    padding: 9px;
    background-color: #015f9b;
    color: white;
    margin-bottom: 0 !important;
    cursor: pointer;
    border-radius: 3px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    font-size: 24px; }
    .form-fields .passwordCloseEye::after, .form-fields .passwordOpenEye::after {
      content: "";
      display: none; }
  .form-fields .vat_number, .form-fields .id_country {
    display: none !important; }

#customer-form label {
  max-width: 50%; }
  @media (max-width: 768px) {
    #customer-form label {
      max-width: 100%; } }

.lost_password {
  margin-top: 20px;
  text-decoration: underline; }

#contact #content {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px; }
  @media (max-width: 1024px) {
    #contact #content {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #contact #content {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #contact #content {
      max-width: 500px;
      min-width: 300px; } }
  #contact #content h1 {
    font-size: 22px; }
  #contact #content .login-form {
    font-family: "Lato", sans-serif;
    font-weight: 300;
    font-size: 16px; }
    #contact #content .login-form h2 {
      font-size: 22px;
      font-weight: 600; }
    #contact #content .login-form .form-fields {
      display: grid;
      grid-template-columns: 50%;
      padding-top: 16px; }
      #contact #content .login-form .form-fields label {
        margin-bottom: 20px; }
        #contact #content .login-form .form-fields label span {
          display: block;
          margin-bottom: 5px; }
        #contact #content .login-form .form-fields label:first-of-type {
          display: none; }
    #contact #content .login-form button {
      background-color: #76a9cc;
      color: white;
      border: 2px solid #76a9cc;
      border-radius: 30px;
      transition: all 500ms;
      text-align: center;
      text-transform: uppercase;
      padding: 16px 16px;
      cursor: pointer; }
      #contact #content .login-form button:hover {
        background-color: white;
        color: #76a9cc; }
    #contact #content .login-form i.material-icons {
      display: none; }
    #contact #content .login-form .form-footer {
      border-top: 1px dashed #cccccc;
      padding-top: 10px;
      margin-top: 10px; }

#pagenotfound #djv-two-columns-layer {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px; }
  @media (max-width: 1024px) {
    #pagenotfound #djv-two-columns-layer {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #pagenotfound #djv-two-columns-layer {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #pagenotfound #djv-two-columns-layer {
      max-width: 500px;
      min-width: 300px; } }
  #pagenotfound #djv-two-columns-layer #search_widget .djv-icon-lupa {
    display: none; }
  #pagenotfound #djv-two-columns-layer #search_widget input[type="text"] {
    padding: 5px 3px 6px 3px;
    height: 33px; }
  #pagenotfound #djv-two-columns-layer #search_widget button {
    background-color: #76a9cc;
    color: white;
    border: 2px solid #76a9cc;
    border-radius: 10px;
    transition: all 500ms;
    text-align: center;
    text-transform: uppercase;
    padding: 20px 5px;
    cursor: pointer; }
    #pagenotfound #djv-two-columns-layer #search_widget button:hover {
      background-color: white;
      color: #76a9cc; }
    #pagenotfound #djv-two-columns-layer #search_widget button::after {
      content: "Buscar"; }

.headerGrid #search_widget {
  display: block;
  margin: 5px auto 5px 0;
  position: relative;
  width: calc(100% - 36px); }
  @media (max-width: 768px) {
    .headerGrid #search_widget {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      max-width: 100vw; } }
  .headerGrid #search_widget .ui-helper-hidden-accessible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .headerGrid #search_widget input {
    display: block;
    position: relative;
    width: 100%;
    height: 30px;
    margin: 0 auto;
    padding: 20px;
    background-color: #dbebf5;
    border: 0;
    border-radius: 0px;
    color: #0a3855;
    font-family: fuente("Lato", sans-serif, 300);
    font-size: 18px; }
    .headerGrid #search_widget input::placeholder {
      font-size: 16px;
      color: #84b3d1;
      font-family: fuente("Lato", sans-serif, 100);
      text-align: left; }
  .headerGrid #search_widget button {
    position: absolute;
    top: 0px;
    right: 0;
    border: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 37px;
    height: 37px;
    cursor: pointer;
    background-color: transparent; }
    .headerGrid #search_widget button::after {
      display: inline-block;
      font-family: "comunes";
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: '\0045';
      font-size: 22px;
      right: 14px;
      top: 9px;
      position: absolute;
      color: #84b3d1; }

.ui-widget {
  font-size: 12px; }

label {
  /*margin-bottom: 5px;
    position: relative;
    input{
        display:block;
        width: 100%;
        margin-top: 5px;
        padding: 10px;
        font-size: $font-xs;
    }
    &.required span{
        &::after{
            content: "*";
            color:red;
            position: absolute;
            padding-top: 0px;
            margin-left: 10px;
        }
        span{
            padding-left: 12px;
            padding-top: 10px;
        }
        input[type="checkbox"]{
            text-align: right;
        }
    }*/ }

#module-djv_navigation-nsearch #djv-two-columns-layer .textoSeo h2 {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  color: #dbebf5;
  font-size: 18px;
  text-align: left;
  margin-top: 20px; }

#module-djv_navigation-nsearch #djv-two-columns-layer .textoSeo p {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  margin-bottom: 12px;
  padding-left: 12px;
  font-size: 14px;
  text-align: left; }

#module-djv_navigation-nsearch #djv-two-columns-layer .textoSeo b {
  padding-left: 12px;
  font-size: 14px; }

@media (max-width: 576px) {
  #module-djv_navigation-nsearch #djv-two-columns-layer .textoSeo {
    width: auto;
    margin-left: auto; } }

.textoSeo section:not(.full-width), #category-description section:not(.full-width) {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px; }
  @media (max-width: 1024px) {
    .textoSeo section:not(.full-width), #category-description section:not(.full-width) {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    .textoSeo section:not(.full-width), #category-description section:not(.full-width) {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    .textoSeo section:not(.full-width), #category-description section:not(.full-width) {
      max-width: 500px;
      min-width: 300px; } }

.textoSeo section header, #category-description section header {
  text-align: center; }

.textoSeo .ver-mas, #category-description .ver-mas {
  background-color: #76a9cc;
  color: white;
  border: 2px solid #76a9cc;
  border-radius: 50px;
  transition: all 500ms;
  text-align: center;
  text-transform: uppercase;
  padding: 20px 5px;
  cursor: pointer;
  color: white;
  width: 170px;
  margin: 0px auto;
  font-size: 12px;
  display: block;
  margin-top: 30px; }
  .textoSeo .ver-mas:hover, #category-description .ver-mas:hover {
    background-color: white;
    color: #76a9cc; }

.textoSeo .djv-flex, #category-description .djv-flex {
  display: flex; }
  .textoSeo .djv-flex > *, #category-description .djv-flex > * {
    flex: 1;
    margin: 0 7px; }
  .textoSeo .djv-flex > a, #category-description .djv-flex > a {
    background-color: black; }
    .textoSeo .djv-flex > a img, #category-description .djv-flex > a img {
      transition: opacity 500ms;
      opacity: 1; }
      .textoSeo .djv-flex > a img:hover, #category-description .djv-flex > a img:hover {
        opacity: .5; }
  @media (max-width: 768px) {
    .textoSeo .djv-flex, #category-description .djv-flex {
      display: block; } }

.textoSeo .element-icon, #category-description .element-icon {
  display: grid;
  grid-template-columns: 20% 80%; }
  @media (max-width: 768px) {
    .textoSeo .element-icon, #category-description .element-icon {
      display: block;
      text-align: center;
      margin-bottom: 30px; } }
  .textoSeo .element-icon img, #category-description .element-icon img {
    float: left;
    display: inline-block;
    width: 50px;
    margin-right: 16px; }
    @media (max-width: 768px) {
      .textoSeo .element-icon img, #category-description .element-icon img {
        float: none;
        margin-right: 0; } }
  .textoSeo .element-icon div, #category-description .element-icon div {
    display: inline-block; }
    @media (max-width: 768px) {
      .textoSeo .element-icon div, #category-description .element-icon div {
        display: block; } }
    .textoSeo .element-icon div h3, #category-description .element-icon div h3 {
      color: black;
      font-size: 21px;
      font-family: "Lato", sans-serif;
      font-weight: 400;
      display: block;
      margin-bottom: 0; }
    .textoSeo .element-icon div p, #category-description .element-icon div p {
      display: block;
      font-size: 14px;
      font-family: "Lato", sans-serif;
      font-weight: 100; }

.textoSeo .tag-list, #category-description .tag-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }
  .textoSeo .tag-list a, #category-description .tag-list a {
    background-color: #999999;
    color: white;
    border: 2px solid #999999;
    border-radius: 50px;
    transition: all 500ms;
    text-align: center;
    text-transform: uppercase;
    padding: 20px 5px;
    cursor: pointer;
    min-width: 120px;
    margin: 10px; }
    .textoSeo .tag-list a:hover, #category-description .tag-list a:hover {
      background-color: white;
      color: #999999; }

#module-ph_simpleblog-list #djv-two-columns-layer,
#module-ph_simpleblog-page #djv-two-columns-layer,
#module-ph_simpleblog-category #djv-two-columns-layer,
#module-ph_simpleblog-tag #djv-two-columns-layer,
#module-ph_simpleblog-categorypage #djv-two-columns-layer {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px;
  display: grid;
  grid-template-columns: calc(20% - 20px) calc(80% - 20px);
  grid-gap: 20px; }
  @media (max-width: 1024px) {
    #module-ph_simpleblog-list #djv-two-columns-layer,
    #module-ph_simpleblog-page #djv-two-columns-layer,
    #module-ph_simpleblog-category #djv-two-columns-layer,
    #module-ph_simpleblog-tag #djv-two-columns-layer,
    #module-ph_simpleblog-categorypage #djv-two-columns-layer {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #module-ph_simpleblog-list #djv-two-columns-layer,
    #module-ph_simpleblog-page #djv-two-columns-layer,
    #module-ph_simpleblog-category #djv-two-columns-layer,
    #module-ph_simpleblog-tag #djv-two-columns-layer,
    #module-ph_simpleblog-categorypage #djv-two-columns-layer {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #module-ph_simpleblog-list #djv-two-columns-layer,
    #module-ph_simpleblog-page #djv-two-columns-layer,
    #module-ph_simpleblog-category #djv-two-columns-layer,
    #module-ph_simpleblog-tag #djv-two-columns-layer,
    #module-ph_simpleblog-categorypage #djv-two-columns-layer {
      max-width: 500px;
      min-width: 300px; } }
  @media (max-width: 1024px) {
    #module-ph_simpleblog-list #djv-two-columns-layer,
    #module-ph_simpleblog-page #djv-two-columns-layer,
    #module-ph_simpleblog-category #djv-two-columns-layer,
    #module-ph_simpleblog-tag #djv-two-columns-layer,
    #module-ph_simpleblog-categorypage #djv-two-columns-layer {
      display: block;
      margin-top: 190px !important; }
      #module-ph_simpleblog-list #djv-two-columns-layer #left-column,
      #module-ph_simpleblog-page #djv-two-columns-layer #left-column,
      #module-ph_simpleblog-category #djv-two-columns-layer #left-column,
      #module-ph_simpleblog-tag #djv-two-columns-layer #left-column,
      #module-ph_simpleblog-categorypage #djv-two-columns-layer #left-column {
        display: none; } }
  #module-ph_simpleblog-list #djv-two-columns-layer h1,
  #module-ph_simpleblog-page #djv-two-columns-layer h1,
  #module-ph_simpleblog-category #djv-two-columns-layer h1,
  #module-ph_simpleblog-tag #djv-two-columns-layer h1,
  #module-ph_simpleblog-categorypage #djv-two-columns-layer h1 {
    font-size: 28px; }
  #module-ph_simpleblog-list #djv-two-columns-layer .simpleblog-posts,
  #module-ph_simpleblog-page #djv-two-columns-layer .simpleblog-posts,
  #module-ph_simpleblog-category #djv-two-columns-layer .simpleblog-posts,
  #module-ph_simpleblog-tag #djv-two-columns-layer .simpleblog-posts,
  #module-ph_simpleblog-categorypage #djv-two-columns-layer .simpleblog-posts {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 10px));
    grid-gap: 10px; }
    #module-ph_simpleblog-list #djv-two-columns-layer .simpleblog-posts::before,
    #module-ph_simpleblog-page #djv-two-columns-layer .simpleblog-posts::before,
    #module-ph_simpleblog-category #djv-two-columns-layer .simpleblog-posts::before,
    #module-ph_simpleblog-tag #djv-two-columns-layer .simpleblog-posts::before,
    #module-ph_simpleblog-categorypage #djv-two-columns-layer .simpleblog-posts::before {
      display: none; }
  #module-ph_simpleblog-list #djv-two-columns-layer a:hover,
  #module-ph_simpleblog-page #djv-two-columns-layer a:hover,
  #module-ph_simpleblog-category #djv-two-columns-layer a:hover,
  #module-ph_simpleblog-tag #djv-two-columns-layer a:hover,
  #module-ph_simpleblog-categorypage #djv-two-columns-layer a:hover {
    color: #015f9b; }

#module-ph_simpleblog-list .pagination > li > a, #module-ph_simpleblog-list .pagination > li > span,
#module-ph_simpleblog-page .pagination > li > a,
#module-ph_simpleblog-page .pagination > li > span,
#module-ph_simpleblog-category .pagination > li > a,
#module-ph_simpleblog-category .pagination > li > span,
#module-ph_simpleblog-tag .pagination > li > a,
#module-ph_simpleblog-tag .pagination > li > span,
#module-ph_simpleblog-categorypage .pagination > li > a,
#module-ph_simpleblog-categorypage .pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
  color: inherit; }

#module-ph_simpleblog-list .pagination > li.disabled,
#module-ph_simpleblog-page .pagination > li.disabled,
#module-ph_simpleblog-category .pagination > li.disabled,
#module-ph_simpleblog-tag .pagination > li.disabled,
#module-ph_simpleblog-categorypage .pagination > li.disabled {
  cursor: not-allowed; }

#module-ph_simpleblog-category .post-additional-info {
  background-color: transparent; }

.simpleblog-recent .btn-hover-animated-green-dark {
  margin-left: auto;
  margin-right: auto; }
  .simpleblog-recent .btn-hover-animated-green-dark a {
    color: inherit; }

#module-ph_simpleblog-single #djv-two-columns-layer {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px;
  display: grid;
  grid-template-columns: calc(20% - 20px) calc(80% - 20px);
  grid-gap: 20px; }
  @media (max-width: 1024px) {
    #module-ph_simpleblog-single #djv-two-columns-layer {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #module-ph_simpleblog-single #djv-two-columns-layer {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #module-ph_simpleblog-single #djv-two-columns-layer {
      max-width: 500px;
      min-width: 300px; } }
  @media (max-width: 1024px) {
    #module-ph_simpleblog-single #djv-two-columns-layer {
      display: block;
      margin-top: 190px !important; } }
  #module-ph_simpleblog-single #djv-two-columns-layer h2 strong {
    color: #015f9b; }
  #module-ph_simpleblog-single #djv-two-columns-layer .djv_carouselh h5 {
    font-size: 17.6px;
    line-height: inherit; }
    #module-ph_simpleblog-single #djv-two-columns-layer .djv_carouselh h5 a {
      color: #333333; }
  #module-ph_simpleblog-single #djv-two-columns-layer .djv_carouselh .price {
    font-size: 20.8px; }
  #module-ph_simpleblog-single #djv-two-columns-layer .djv_carouselh .price-discounted {
    color: #EAEAEA;
    font-size: 20.8px; }
  #module-ph_simpleblog-single #djv-two-columns-layer .djv_carouselh .regular-price {
    text-decoration: line-through;
    font-size: 20.8px; }
  #module-ph_simpleblog-single #djv-two-columns-layer .djv_carouselh .discount-percentage {
    display: none; }
  #module-ph_simpleblog-single #djv-two-columns-layer .djv_carouselh .products > * {
    min-height: 370px; }
  #module-ph_simpleblog-single #djv-two-columns-layer #simpleblog-post-comments {
    font-family: "Lato", sans-serif;
    font-weight: 300; }
    #module-ph_simpleblog-single #djv-two-columns-layer #simpleblog-post-comments .post-comment:nth-child(2n) {
      margin-left: 90px;
      padding-right: 40px;
      border-bottom-left-radius: 10px; }

#ph_simpleblog_categories {
  padding-top: 7px; }
  #ph_simpleblog_categories > a {
    border-bottom: 1px solid #cccccc;
    font-size: 27.5px;
    display: block;
    color: inherit;
    margin-bottom: 20px;
    padding-bottom: 14px; }
  #ph_simpleblog_categories ul {
    margin: 0;
    padding: 0; }
    #ph_simpleblog_categories ul li {
      border-bottom: 1px dashed #cccccc;
      padding-bottom: 10px;
      margin-bottom: 10px;
      list-style: none;
      margin-bottom: 5px;
      padding-bottom: 5px; }
      #ph_simpleblog_categories ul li a {
        color: inherit; }

.ph_simpleblog .title_block a {
  color: #111111; }

.ph_simpleblog .simpleblog-posts .simpleblog-post-item .post-item {
  border-radius: 6px;
  overflow: hidden; }
  .ph_simpleblog .simpleblog-posts .simpleblog-post-item .post-item h2 {
    padding-top: 20px; }
    .ph_simpleblog .simpleblog-posts .simpleblog-post-item .post-item h2 a {
      font-size: 22px;
      text-transform: uppercase;
      text-decoration: none;
      padding: 0 5px;
      display: block;
      color: #015f9b; }
  .ph_simpleblog .simpleblog-posts .simpleblog-post-item .post-item .post-content {
    font-family: "Lato", sans-serif;
    font-weight: 300;
    font-size: 11px;
    line-height: 18px; }

.simpleblog-single h1 {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-size: 28px !important;
  line-height: 32px !important; }

.simpleblog-single .post-featured-image {
  width: 100%;
  margin-bottom: 40px !important; }
  .simpleblog-single .post-featured-image img {
    width: 100%; }

.simpleblog-single .post-content h2 {
  border-bottom: 1px solid #cccccc;
  padding-bottom: 10px;
  margin-bottom: 10px;
  margin-top: 60px;
  margin-bottom: 10px;
  padding-bottom: 5px; }

.simpleblog-single .post-content h3 {
  font-size: 22px;
  margin-top: 50px; }

.simpleblog-single .post-content p {
  font-family: "Lato", sans-serif;
  font-weight: 300; }

.simpleblog-single .post-content a:hover {
  color: #015f9b; }

.simpleblog-single .post-category a:hover {
  color: #015f9b; }

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; }

#wrapper {
  clear: both; }

#wrapper,
.header-top,
.header-topbar {
  padding-left: 0;
  padding-right: 0; }

.header-banner {
  text-align: center;
  background-color: black; }

#djv-two-columns-layer {
  min-height: 650px; }
  @media (max-width: 1024px) {
    #djv-two-columns-layer {
      min-height: 100px;
      margin-top: 50px; } }

@media (max-width: 1024px) {
  #index .carousel {
    margin-top: 90px;
    margin-bottom: 0px; } }

@media (max-width: 768px) {
  #index .carousel {
    margin-top: 40px; } }

#index.recogido #banners-area {
  margin-top: 140px;
  margin-bottom: 0px; }

#module-djv_navigation-nsearch .carousel {
  margin-bottom: 0; }
  @media (max-width: 768px) {
    #module-djv_navigation-nsearch .carousel {
      margin-top: 40px; } }

@media (max-width: 768px) {
  #module-djv_navigation-nsearch #djv-two-columns-layer {
    margin-top: 0; } }

#module-djv_navigation-nsearch #djv-two-columns-layer > div {
  padding: 0px; }
  @media (max-width: 1024px) {
    #module-djv_navigation-nsearch #djv-two-columns-layer > div {
      padding: 8px; } }

@media (max-width: 1024px) {
  #module-djv_navigation-nsearch #djv-two-columns-layer {
    display: block; } }

@media (max-width: 1024px) {
  #module-djv_navigation-nsearch .djv-navigation-nsearch-header {
    margin-top: 40px;
    padding: 10px; } }

#module-djv_navigation-nsearch.recogido .djv-navigation-nsearch-header {
  margin-top: 140px; }

#module-djv_navigation-nsearch.recogido .carousel {
  margin-bottom: 0; }

#module-djv_navigation-nsearch.recogido .carousel ~ .djv-navigation-nsearch-header {
  margin-top: 0px; }

#module-djv_navigation-nsearch.recogido #djv-two-columns-layer {
  margin-top: 30px; }

#product #djv-two-columns-layer {
  margin-top: 0px; }

#product.recogido .djv-breadcrumb {
  margin-top: 200px; }

#authentication #djv-two-columns-layer {
  margin-top: 40px; }

#authentication.recogido #djv-two-columns-layer {
  margin-top: 160px; }

#module-ph_simpleblog-list #djv-two-columns-layer {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px;
  display: grid;
  grid-template-columns: calc(25% - 15px) calc(75% - 15px);
  grid-gap: 0 30px;
  margin-top: 20px; }
  @media (max-width: 1024px) {
    #module-ph_simpleblog-list #djv-two-columns-layer {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #module-ph_simpleblog-list #djv-two-columns-layer {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #module-ph_simpleblog-list #djv-two-columns-layer {
      max-width: 500px;
      min-width: 300px; } }

.user-info {
  display: none;
  position: absolute;
  right: 41px;
  top: 42px;
  background-color: white;
  padding: 10px;
  width: 300px;
  text-align: center;
  z-index: 5;
  box-shadow: 0px 14px 12px rgba(0, 0, 0, 0.4); }
  @media (max-width: 768px) {
    .user-info {
      padding-top: 9px;
      width: 100vw;
      right: 0px; } }
  .user-info > * {
    font-size: 0.8rem; }
  .user-info a, .user-info a:visited, .user-info a:hover {
    text-decoration: none;
    color: inherit;
    display: inline-block; }
    .user-info a span, .user-info a:visited span, .user-info a:hover span {
      display: none; }
    .user-info a i, .user-info a:visited i, .user-info a:hover i {
      color: #84b3d1;
      display: inline-block; }
  .user-info .account,
  .user-info .login,
  .user-info .logout {
    display: inline-block;
    font-size: 12px;
    margin: 0 10px;
    background-color: white;
    padding: 4px 15px;
    border-radius: 2px; }
    @media (max-width: 768px) {
      .user-info .account,
      .user-info .login,
      .user-info .logout {
        margin: 0 10px; } }
  .user-info > span {
    display: inline-block;
    font-size: 12px;
    margin: 0 10px;
    padding: 4px 15px;
    border-radius: 2px;
    color: #84b3d1; }
    @media (max-width: 768px) {
      .user-info > span {
        display: none; } }
  .user-info .logout::before {
    color: #84b3d1; }

@media (min-width: 768px) {
  .user-info a, .user-info a:visited, .user-info a:hover {
    display: inline-block; }
    .user-info a span, .user-info a:visited span, .user-info a:hover span {
      display: inline-block; }
    .user-info a i, .user-info a:visited i, .user-info a:hover i {
      display: inline-block; } }

#notifications {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px;
  margin-top: 45px;
  margin-bottom: 10px;
  font-size: 12px; }
  @media (max-width: 1024px) {
    #notifications {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #notifications {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #notifications {
      max-width: 500px;
      min-width: 300px; } }
  #notifications ul {
    margin-left: 0;
    padding-left: 0; }
  #notifications li {
    list-style: none; }

.notification {
  padding: 30px; }

.notification-danger {
  background: #fbe9e8;
  border: 2px solid #D53128; }

.notification-warning {
  background: #fcf8e3;
  border: 2px solid #ff7926; }

.notification-success {
  background: #dff0d8;
  border: 2px solid #1a7e10; }

.notification-info {
  background: #d9edf7;
  border: 2px solid #0083ff; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.5);
  display: block !important;
  overflow: hidden;
  outline: 0; }
  @media (max-width: 768px) {
    .modal {
      overflow-y: scroll; } }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }
  .modal .modal-dialog {
    position: relative;
    background-color: white;
    margin: 50px auto;
    min-height: 50vh;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    width: 100%;
    max-width: 700px; }
    @media (max-width: 768px) {
      .modal .modal-dialog {
        max-width: 100vw !important;
        margin-top: 0; } }
    .modal .modal-dialog .modal-content {
      border-radius: 0; }
      @media (max-width: 768px) {
        .modal .modal-dialog .modal-content {
          display: block; } }
      .modal .modal-dialog .modal-content .modal-header {
        background-color: #84b3d1;
        padding: 16px;
        position: relative;
        border-top-left-radius: 0;
        border-top-right-radius: 0; }
        .modal .modal-dialog .modal-content .modal-header .close {
          position: absolute;
          right: 16px;
          top: 16px;
          float: none;
          font-size: 48px;
          font-weight: 700;
          line-height: 0;
          color: #000;
          text-shadow: 0 1px 0 #fff;
          opacity: .5;
          background-color: white;
          height: 100%; }
        .modal .modal-dialog .modal-content .modal-header .modal-title {
          color: white;
          font-size: 16px;
          max-width: 80%; }
          @media (max-width: 768px) {
            .modal .modal-dialog .modal-content .modal-header .modal-title {
              font-size: 12px; } }
          .modal .modal-dialog .modal-content .modal-header .modal-title i {
            margin-right: 16px; }
            @media (max-width: 768px) {
              .modal .modal-dialog .modal-content .modal-header .modal-title i {
                display: none; } }
      .modal .modal-dialog .modal-content .modal-body {
        padding: 5px;
        display: grid;
        grid-template-columns: repeat(2, calc(50% - 10px));
        grid-gap: 10px; }
        @media (max-width: 768px) {
          .modal .modal-dialog .modal-content .modal-body {
            display: block;
            padding: 10px; } }
        .modal .modal-dialog .modal-content .modal-body .modal-body-product-img {
          padding: 10px 10px 0px; }
          @media (max-width: 768px) {
            .modal .modal-dialog .modal-content .modal-body .modal-body-product-img {
              border-right: none; } }
          .modal .modal-dialog .modal-content .modal-body .modal-body-product-img img {
            display: block;
            margin: auto;
            width: 70%; }
            @media (max-width: 768px) {
              .modal .modal-dialog .modal-content .modal-body .modal-body-product-img img {
                max-width: 100%;
                margin-bottom: 20px; } }
        .modal .modal-dialog .modal-content .modal-body .modal-body-product-data {
          padding: 10px 10px 0px; }
          @media (max-width: 768px) {
            .modal .modal-dialog .modal-content .modal-body .modal-body-product-data {
              padding: 10px; }
              .modal .modal-dialog .modal-content .modal-body .modal-body-product-data p {
                margin-bottom: 5px; } }
          .modal .modal-dialog .modal-content .modal-body .modal-body-product-data .product-name {
            font-size: 22px;
            font-family: "Lato", sans-serif;
            font-weight: 700; }
          .modal .modal-dialog .modal-content .modal-body .modal-body-product-data .modal-product-price {
            font-size: 22px; }
        .modal .modal-dialog .modal-content .modal-body .modal-body-cart-content {
          padding: 40px; }
          @media (max-width: 768px) {
            .modal .modal-dialog .modal-content .modal-body .modal-body-cart-content {
              padding: 10px; } }
          .modal .modal-dialog .modal-content .modal-body .modal-body-cart-content p {
            margin-bottom: 5px; }
        .modal .modal-dialog .modal-content .modal-body .cart-content-btn {
          float: right;
          padding: 50px 30px 40px; }
          @media (max-width: 768px) {
            .modal .modal-dialog .modal-content .modal-body .cart-content-btn {
              padding: 10px; } }
          .modal .modal-dialog .modal-content .modal-body .cart-content-btn i {
            margin-right: 10px; }
          .modal .modal-dialog .modal-content .modal-body .cart-content-btn .btn {
            margin-top: 8px;
            margin-bottom: 8px;
            width: 100%;
            text-align: center; }
            @media (max-width: 768px) {
              .modal .modal-dialog .modal-content .modal-body .cart-content-btn .btn {
                margin-top: 8px;
                margin-bottom: 8px; } }
      .modal .modal-dialog .modal-content .modal-footer {
        border-top: 0; }
  .modal#moreInformationFormModal .modal-dialog {
    width: 480px; }
    .modal#moreInformationFormModal .modal-dialog .modal-content {
      display: block;
      border: 0; }
      .modal#moreInformationFormModal .modal-dialog .modal-content .modal-header .modal-title {
        font-size: 18px; }
      .modal#moreInformationFormModal .modal-dialog .modal-content .modal-body {
        display: block;
        padding: 16px; }
        .modal#moreInformationFormModal .modal-dialog .modal-content .modal-body .product {
          display: flex;
          padding: 0;
          margin: 10px 0 20px; }
          .modal#moreInformationFormModal .modal-dialog .modal-content .modal-body .product h3 {
            font-size: 22px; }

#saveStateScreener, .blockedWhileWaiting {
  background-image: url("https://www.seleccionatolon.com/themes/base/assets/img/loader.svg");
  background-position: center center;
  background-size: 120px;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100000; }
  @media (max-width: 768px) {
    #saveStateScreener, .blockedWhileWaiting {
      width: 100%;
      height: 100%; } }
  #saveStateScreener .blockedMessage, .blockedWhileWaiting .blockedMessage {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: white;
    text-transform: uppercase; }

.watchmework {
  font-size: 22.8; }
