.expandText{
    .content{
        position: relative;
        /*max-height: 100px;
        height: 100px;*/
        overflow: hidden;
        transition: all 600ms;
    }
    .expand-handler, .contract-handler{
        @include fuente($font-first,$font-semibold);
        font-size: $font-md;
        padding: 16px 0;
        margin-bottom: 10px;
        cursor: pointer;
        &::before{
            margin-right: 6px;
            font-size: $font-sm;
        }
    }
}
