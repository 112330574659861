
.djv-breadcrumb{
    @include fuente($font-first, $font-medium);
    font-size: $font-smd;
    padding: 15px;
    background-color: $primario6;
    @media(max-width: $screen-md){
    }
    ol{
        padding-left: 0;
        margin-bottom: 0;
        text-align: center;
        li{
            display: inline-block;
            color:$primario3;
            &::after {
                content:'\004c';
                display: inline-block;
                font-family: 'comunes';
                font-style: normal;
                font-weight: normal;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                margin: 0 4px;
                font-size: 8px;
            }
            a{
                color: $primario3;
            }
            &:last-of-type{
                a{
                    color: $primario4;
                }
                &::after{
                    content: "";
                    display: none;
                }
            }
        }
    }
}
