@media(min-width: $screen-md + 1) {
    .djv_carouselh {
        position: relative;
        >* {
            overflow: hidden;
            position: relative;
        }
        .djv_carouselh-left-arrow,
        .djv_carouselh-right-arrow {
            position: absolute;
            z-index: 1;
            top: 50%;
            transform: translateY(-50%);
            font-size: 36px;
            width: 50px;
            height: 100%;
            cursor: pointer;
            background-color: transparent;
            &::before {
                position: absolute;
                top: 50%;
                transform: translateY(-70%);
                height: 40px;
                width: 40px;
                padding: 13px 4px 20px 14px;
                color: $gris8;
                font-weight: normal;
                font-size: $font-xl;
                display: inline-block;
                font-family: "comunes";
                font-style: normal;
                font-weight: normal;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }
        .djv_carouselh-left-arrow {
            left: 0px;
            &::before {
                left: 0px;
                content: '\004b';
            }
        }
        .djv_carouselh-right-arrow {
            right: 0px;
            &::before {
                right: 0px;
                content: '\004c';
            }
        }
        .djv_carouselh-viewer {
            position: relative;
            width: calc(100% - 80px);
            margin: 0 auto;
            > * {
                text-align: left;
                position: absolute;
                transition: left 1s;
                > * {
                    margin: 0;
                    padding: 5px;
                    float: left !important;
                    clear: none;
                    .product-container {
                        border-bottom: 0;
                    }
                }
            }
        }
    }
}
/* ###  Carousel de marcas ### */
.djv-troncal-marcasdestacadas-carousel {
    ul {
        text-align: left;
        position: absolute;
        transition: left 1s;

        > li {
            display: inline-block;
            width: 120px;
            min-height: 100px;
            margin: 0;
            padding: 5px 16px;

            img {
                width: 100%;
            }
        }
    }
}

#carouselMarcas {

    @media(max-width: $screen-md){
        .swipe::after{

        }
    }
    .carouselh-brands {
        text-align: left;
        position: absolute;
        transition: left 1s;
        display: flex;
        align-items: baseline;
        @media(max-width: $screen-md){
            position: relative;
        }
        .brands-miniature {
            display: inline-block !important;
            width: 120px;
            min-width: 120px;
            min-height: 120px;
            margin: 0;
            padding: 5px 10px;
            float: left;
            @media(max-width: $screen-md){
                width: 80px;
                min-width: 80px;
                height: 80px;
                min-height: 80px;
            }
            img {
                width: 100%;
                vertical-align: middle;
            }
        }
    }

    .djv_carouselh-viewer {
        position: relative;
        .djv_carouselh-left-arrow,
        .djv_carouselh-right-arrow {
            position: absolute;
            z-index: 1;
            top: 50%;
            transform: translateY(-50%);
            width: 44px;
            height: 100%;
            cursor: pointer;
            background-color: white;
            @media(max-width: $screen-md) {
                background-color: $primario5;
                width: 24px;
                height: 34px;
                font-size: 32px;
                box-shadow: none;
            }

            &::before {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                font-size: $font-xxl;
                @media(max-width: $screen-md) {
                    color: white;
                    font-size: $font-lg;
                }
            }
        }

        .djv_carouselh-left-arrow {
            left: 0;
            box-shadow: none;
            @media(max-width: $screen-md) {
                border-top-right-radius: 16px;
                border-bottom-right-radius: 16px;
            }

            &::before {
                left: 12px;
                content: '\0058';
                display: inline-block;
                font-family: 'comunes';
                font-style: normal;
                font-weight: normal;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                @media(max-width: $screen-md) {
                    font-size: $font-md;
                    left: 0;
                }
            }
        }

        .djv_carouselh-right-arrow {
            right: 0;
            box-shadow: none;
            @media(max-width: $screen-md) {
                border-top-left-radius: 16px;
                border-bottom-left-radius: 16px;
            }

            &::before {
                right: 12px;
                content: '\0059';
                display: inline-block;
                font-family: 'comunes';
                font-style: normal;
                font-weight: normal;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                @media(max-width: $screen-md) {
                    font-size: $font-md;
                    right: 0;
                }
            }
        }
    }
    @media(max-width: $screen-md){
        .carouselh-brands{
            width: 100%;
            overflow-x: auto;
            overflow-y: hidden;
            white-space: nowrap;
            -webkit-overflow-scrolling: touch;
            -webkit-scroll-snap-type: mandatory;
            scroll-snap-type: x mandatory;
            -webkit-scroll-snap-points-x: repeat(100%);
            scroll-snap-points-x: repeat(100%);
            .brands-miniature{
                scroll-snap-align: start;
                position: relative;
                display: inline-block;
                margin-right: -4px;
                text-align: center;
                float: none;
            }
        }
    }
}
/* ### fin Carousel de marcas ### */
@media(max-width: $screen-md) {
    .djv_carouselh{
        &.swipe::after{
            display: inline-block;
            font-family: 'comunes';
            font-style: normal;
            font-weight: normal;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content:'\006e';
            position: relative;
            left: 50%;
            top: -15px;
            transform: translateX(-50%);
            margin: 10px 0;
            font-size: 42px;
            color: $gris5;
        }
        > * {
            width: 100%;
            overflow-x: auto;
            overflow-y: hidden;
            white-space: nowrap;
            -webkit-overflow-scrolling: touch;
            -webkit-scroll-snap-type: mandatory;
            scroll-snap-type: x mandatory;
            -webkit-scroll-snap-points-x: repeat(100%);
            scroll-snap-points-x: repeat(100%);
            > * {
                scroll-snap-align: start;
                width: 100%;
                position: relative;
                display: inline-block;
                margin-right: -4px;
                text-align: center;
                .product-list-actions{
                    margin-top: 0;
                }
            }
        }
    }
}
