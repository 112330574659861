@media(min-width: $screen-md + 1){
    body:not(.recogido){
        .header-nav-top {
            position: relative;
            height: 60px;
            z-index: 1;
            margin: auto;
            width: 100%;
            #djv-close-nav-responsive{
                display: none;
            }
            #main-menu {
                @include full-width();
                position: absolute;
                border-top: 1px solid #cccccc;
                margin-top: 0px;
                padding-top: 0px;
                background-color: white;
                .ul-menu {
                    @include djv-container();
                    display: flex;
                    position: relative;
                    margin-bottom: 0;
                    justify-content: left;
                    list-style: none;
                    > li{
                        padding: 5px 5px 3px 5px;
                        line-height: 33px;
                        white-space: nowrap;
                        font-size: $font-bmd ;
                        &:nth-of-type(1){
                            font-size: $font-md + 1;
                            margin-right: 26px;
                            margin-left: 8px;
                        }
                        &:nth-of-type(2){
                            margin-right: 26px;
                        }
                        &:nth-of-type(3){
                            margin-left: 26px;
                        }
                        strong{
                            text-transform: uppercase;
                            font-weight: 700;
                        }
                        a{
                            color: $primario3;
                        }
                    }
                }
            }
        }
    }
}
