.cart-voucher {
    text-align: center;
    border-top: 1px solid $gris3;
    margin-top: 10px;
    padding-top: 10px;
    .vouchers-title,.added-vouchers-title{
        display: block;
        font-size: $font-md;
        font-weight: 400;
        cursor: pointer;
    }
    #vouchers-form{
        height: 0;
        opacity: 0;
        display: none;
    }
    .show-vouchers{
        display: block;
        animation-name: showvouchers;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }
    @keyframes showvouchers{
        from{
            height: 0;
            opacity: 0;
        }
        to{
            height: 100%;
            opacity: 1;
        }
    }

    input[name="discount_name"]{
        width: 160px;
        padding: 9px 10px 12px;
        border: 1px solid $gris3;
        text-align: center;
        font-size: $font-sm;
    }
    button{
        @include btn-hover-animated($primario3, white, 0px, 6px, 45px);
        float: right;
        font-size: $font-md;
    }

    .vouchers-featured{
        margin-top: 16px;
        h4{
            font-size: $font-smd;
        }
        ul{
            padding-left: 0;
            li{
                padding: 5px 10px;
                list-style: none;
                font-size: $font-sm;
                &:nth-of-type(2n - 1){
                    background-color: $gris1;
                }
                .code,.name{
                    display: block;
                    margin: 5px auto;
                }
                .code{
                    color: $primario3;
                    cursor: pointer;
                }
            }
        }
    }
    #added_vouchers{
        list-style: none;
        padding-left: 0;
        .added-voucher-name{
            background-color: $primario2;
            padding: 3px 15px 5px;
            margin-right: 15px;
        }
        .djv-icon-delete{
            color: black;
            font-size: $font-smd;
            text-decoration: none;
            &::before{
                color: $complementario2;
                font-size: $font-xxs;
                margin-right: 5px;
            }
        }
    }
    .djv-icon-question{
        font-size: $font-sm;
        color: $primario3;
        margin-top: 16px;
        display: block;
        &::before{
            margin-right: 5px;
        }
    }
    .notification{
        padding: 2px;
    }
}
