body.recogido{
    .header-nav-top {
        position: relative;
        z-index: 1;
        width: 460px;
        #djv-close-nav-responsive{
            display: none;
        }
        #main-menu {
            @include fuente($font-first,$font-medium);
            font-size: $font-md;
            padding: 20px;
            margin-top: 0px;
            background-color: white;
            box-shadow: 0px 3px 3px rgba(32, 72, 96, 0.3);
            height: 100px;
            .menu-first-level-withoutlink{
                display: none;
            }
            .ver-todo-responsive{
                display: none;
            }
            .ul-menu {
                display: block;
                position: relative;
                color: black;
                margin-bottom: 0;
                padding: 4px 8px 16px;

                a {
                    text-decoration: none;
                    color: black;
                    transition: color 500ms;
                    &:hover{
                        color: $primario3;
                    }
                }

                ul {
                    padding: 0;
                }

                li {
                    list-style: none;
                    margin-bottom: 5px;
                }

            }
        }
    }
}
