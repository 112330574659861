.product-variants {
    h2{
        display: none;
    }
    label {
        text-align: left;
        display: block;
        padding: 5px 0px;
        margin-bottom: 0;
        font-size: $font-md;
        @include fuente($font-first, $font-regular);
    }
    .product-variants-radio{
        text-align: left;
        > a {
            margin-left: 15px;
            font-size: $font-sm;
            text-decoration: underline;
            @include fuente($font-first, $font-medium);
        }
        ul {
            padding-left: 30px;
            .product-variants-radio-input {
                list-style: none;
                display: flex;
                align-items: baseline;
                margin-bottom: 10px;
                cursor: pointer;
                input {
                    cursor: pointer;
                }
                .attribute-name {
                    padding: 5px 15px;
                    font-size: $font-sm;
                    .destacar{
                        @include fuente($font-first, $font-bold);
                        color: #EE2F34;
                    }
                }
                &.selected {
                    .swap-bg::before {
                        content: "✔";
                        position: absolute;
                        margin-left: 5px;
                    }

                    .attribute-name {
                        background-color: $primario3;
                        color: white;
                        padding: 5px 15px;
                        font-size: $font-sm;
                    }
                }

                &.disabled {
                    position: relative;

                    span {
                        opacity: 0.5;
                    }
                }
            }
        }
    }

    .product-variants-columns{
        display: flex;
        justify-content: space-around;
        .product-variants-select{
            select{
                font-size: $font-md;
                width: 100%;
                padding: 4px;
                border: 1px solid $gris5;
            }
        }
    }
    .product-variants-color {
        text-align: left;
        ul {
            padding: 10px;
            display: flex;
            border-left: 1px solid $primario6;
            border-right: 1px solid $primario6;
            @media(max-width: $screen-sm){
                margin-bottom: 5px;
            }
            .product-variants-color-input-container {
                list-style: none;
                display: inline-block;
                margin-bottom: 10px;
                width: 30px;
                height: 30px;
                margin-right: 5px;
                cursor: pointer;
                &.selected{
                    .swap-bg {
                        border: 2px solid white;
                        outline: 2px solid black;
                        background-size: contain;
                    }
                }
                .swap-bg {
                    width: 30px;
                    height: 30px;
                    display: inline-block;
                    border: 1px solid;
                    background-size: contain;
                    cursor: pointer;
                    input {
                        opacity: 0;
                        width: 28px;
                        height: 20px;
                        cursor: pointer;
                    }
                    span{
                        display: none;
                    }
                }
                &.disabled {
                    position: relative;
                    span {
                        opacity: 0.5;
                    }
                    &::after {
                        content: "";
                        display: block;
                        width: 46px;
                        height: 46px;
                        position: absolute;
                        z-index: 9;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        padding: 0 4px;
                        border-radius: 40px;
                        background-image: url("/themes/base/assets/img/aspa.svg");
                        color: $gris1;
                    }
                }
            }
        }
    }
}
