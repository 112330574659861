#product{
    .product-information-extended{
        position: relative;
        #leermas{
            display: block;
        }
        .product-information-extended-heading{
            padding: 5px 16px;
            @include djv-border-bottom(solid);
            @include fuente($font-first, $font-medium);
            font-size: $font-slg;
            color:$gris9;
        }
        .product-information-extended-description{
            padding: 16px;
            font-size: $font-md;
            @media(max-width: $screen-md){
                padding: 0;
            }
            > h2, h3{
                margin-top: 20px;
                margin-bottom: 20px;
                @include fuente($font-first, $font-semibold);
                text-align: left;
                @media(max-width: $screen-md){
                    font-size: $font-md;
                }
            }
            > p{
                margin-bottom: 16px;
            }
            a{
                color: $primario2;
                font-weight: bold;
            }
            img{
                width: 100%;
            }
        }
    }
}
