#product {
    .djv-product-detail-share {
        margin-top: 20px;
        text-align: center;
        color: $primario10;
        font-size: $font-lg;
        a {
            color: $primario10;
            margin-right: 1em;
            &:hover{
                color:lighten($primario10, 25%);
            }
        }
        .social-sharing {
            .facebook {
                @extend .djv-icon-facebook;
            }
            .twitter {
                @extend .djv-icon-twitter;
            }
        }
    }
}
