
.carousel{
    margin-bottom: 5px;
    @media(max-width: $screen-lg){
        margin-bottom: 0;
    }
    .carousel-item{
        overflow: hidden;
        max-width: 100%;
        img{
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            top: 0;
        }
    }

    [class*='djv-icon-arrow-']::before{
        height: 50px;
        width: 50px;
        padding: 17px 4px 20px 7px;
        background: $primario3;
        border: none;
        border-radius: 50%;
        font-size: $font-md;
        color: white;
        font-weight: 900;
        @media(max-width: $screen-lg){
            height: 30px;
            width: 30px;
            font-size: 12px;
            padding: 9px 0 9px;
        }
    }
}
.carousel-indicator-container{
    display: block;
    position: relative;
    bottom: 30px;
    height: 25px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    .carousel-indicators{
        @include djv-container();
        position: relative;
        right: 0;
        bottom: 0px;
        left: 0;
        z-index: 15;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding-left: 0;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 0;
        list-style: none;
        width: 70%;
        @media(max-width: $screen-lg){
            min-width: 100px;
        }
        >li{
            border-right: 0;
            width: 12px;
            height: 12px;
            padding: 0;
            cursor: pointer;
            border-radius: 50px;
            margin-right: 5px;
            @include fuente($font-first, $font-light);
            font-size: 0;
            background-color: rgba(200,200,255,.75);
            color: white;
            &::before{
                display: none;
            }
            &::after{
                display: none;
            }
            &.active {
                background-color: rgba(255,255,255,1);
            }
            @media(min-width: $screen-lg){
                &:last-of-type{
                    border-right: none;
                }
            }
            &::marker{
                display:none;
            }
        }
    }
}


/*.carousel-indicators li::after, .carousel-indicators li::before {
    position: relative;
    bottom:0px;
    top:0px;
    left: 0;
    display: inline-block;
    width: auto;
    height: auto;
    content: "";
}*/
@media only screen and (max-width: $screen-lg) {
    .carousel-item {
        img{
            max-height: 160px;
        }
    }
    .carousel-indicator-container{
        bottom: 0px;
        border: none;
        height: 16px;
        background-color: $gris9;
        .carousel-indicators{
            >li{
                font-size: 0px;
                border: 2px solid white;
                border-radius: 50%;
                margin: 3px;
                height: 10px;
                width: 10px;
                padding: 0px;
                overflow: hidden;
                background-color: $gris8;
                &.active{
                    height: 10px;
                    width: 10px;
                    margin: 3px;
                    overflow: hidden;
                    background-color: $gris7;
                }
            }
        }
    }
    .carousel-control-prev, .carousel-control-next{
        max-height: 250px;
    }
}
