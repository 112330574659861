/* #### Visualizaciones #### */
.xs-visible{
    @media(max-width: $screen-md){
        display: block!important;
    }
}

.etiqueta-flecha{
    -webkit-clip-path: polygon(0% 0%, 70% 0, 90% 50%, 70% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 70% 0, 90% 50%, 70% 100%, 0% 100%);
}

/* #### LAYOUT #### */

.djv-container-stickytop{
    margin-top: 200px;
}
/* ---- grid ---- */

.grid-2{
    display: grid;
    grid-template-columns: repeat(2,calc(50% - 10px));
    grid-gap:10px;
    > *{
        margin: auto;
    }
    @media(max-width: $screen-md){
        display: block;
    }
}
.grid-3{
    display: grid;
    grid-template-columns: repeat(3,calc(33.33% - 10px));
    grid-gap:10px;
    > *{
        margin: auto;
    }
    @media(max-width: $screen-md){
        display: block;
    }
}
.grid-4{
    display: grid;
    grid-template-columns: repeat(4,calc(25% - 10px));
    grid-gap:10px;
    > *{
        margin: auto;
    }
    @media(max-width: $screen-md){
        display: block;
    }
}
.grid-5{
    display: grid;
    grid-template-columns: repeat(5,calc(20% - 10px));
    grid-gap:10px;
    > *{
        margin: auto;
    }
    @media(max-width: $screen-md){
        display: block;
    }
}

.flex-center{
    display: flex;
    justify-content: space-around;
    > *{
        margin: auto 20px;
    }
}

/* ···· FIN BOTONES ···· */

/* #### TITULARES #### */
.djv-titulos-barras-laterales{
    display: grid;
    grid-template-columns: auto 30% auto;
    position: relative;
    @media(max-width: $screen-md){
        grid-template-columns: auto 80% auto;
    }
    &::before{
        content: "";
        border-bottom: 1px solid $gris3;
        display: block;
        width: 100%;
        margin-right: 20px;
        position: relative;
        transform: translateY(-50%);
    }
    &::after{
        content: "";
        border-bottom: 1px solid $gris3;
        display: block;
        width: 100%;
        margin-right: 20px;
        position: relative;
        transform: translateY(-50%);
    }
}
.djv-titulos-left-linea-discontinua{
    font-size: $font-lg;
    padding: 10px 0 15px 5px;
    border-bottom: 1px dashed $gris7;
    margin-bottom: 20px;
    text-transform: uppercase;
}
/* ···· FIN TITULARES ···· */
/* #### BLOQUES #### */
.cms-block-example{
    background-color: $gris1;
    display: block;
    padding: 30px;
    margin: 16px 0px;
    outline:1px dashed $gris3;
    outline-offset:-10px;
}
/* ···· FIN BLOQUES ···· */

/* #### BORDES #### */
.djv-border-top-dashed{
    border-top: 1px dashed $gris3;
    padding-top: 20px;
    margin-top: 20px;
}
.djv-border-top-solid{
    border-top:  1px solid $gris3;
    padding-top: 20px;
    margin-top: 20px;
}
.djv-border-bottom-dashed{
    border-bottom: 1px dashed $gris3;
    padding-bottom:20px;
    margin-bottom:20px;
}
.djv-border-bottom-solid{
    border-bottom: 1px solid $gris3;
    padding-bottom:20px;
    margin-bottom:20px;
}
/* ···· FIN BORDES ···· */
