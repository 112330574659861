.djv-product-availability-info{
    @include fuente($font-first, $font-medium);
    font-size: $font-sm;
    line-height: 16px;
    text-align: center;
    small{
        display: block;
        font-size: $font-xs;
    }
    span{
        display: block;
        text-transform: uppercase;
        font-size: $font-smd;
        &.djv-icon-clock::before{
            display: block;
        }
    }
    p{
        a{
            color:inherit;
            text-decoration: underline;
            text-align: center;
        }
    }
    &.level1{
        color:$complementario1;
        a{
            color:$complementario1;
        }
    }
    &.level2{
        color:$complementario3;
        a{
            color:$complementario3;
        }
    }
    &.level3{
        color:$complementario2;
        a{
            color:$complementario2;
        }
    }
}
