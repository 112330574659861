#authentication{
    #main{
        width: 100%;
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
        @media(max-width: $screen-lg){
            padding: 10px;
        }
        h1.h3{
            font-size: $font-lg;
        }
        .djv-register-link{
            display: block;
            @include djv-border-top(dashed);
            @include djv-border-bottom(dashed);
        }
        .form-footer{
            button{
                @include btn-hover-animated($primario8, white, 0, 10px, 30px);
            }
        }
        .page-authentication{
            #login-form{
                label{
                    max-width: 320px;
                }
            }
            #customer-form{
            }
        }
    }
}
#password{
    .form-fields{
        label{
            display: block;
            margin-top: 20px;
            >*{
                display: inline-block;
                width: 200px;
            }
        }
    }
}
