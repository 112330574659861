.carousel-marcas{
    @media(max-width: $screen-md){
        display: none;
    }
    #carouselMarcas{
        [class*='icon-caret-']{
            border:0 !important;
            &::before{
                top: 55%;
            }
        }
        .djv_carouselh-right-arrow{
            &::before{
                right: 6px;
            }
        }
        .djv_carouselh-left-arrow{
            &::before{
                left: 6px;
            }
        }
        .carouselh-brands{
            text-align: left;
            position: absolute;
            transition: left 1s;
            padding-left: 0;
            > * {
               display: inline-block !important;
               width: 195px;
               min-width: 195px;
               min-height: 195px;
               margin: 0;
               padding: 5px;
               float: left !important;
               transition: all 500ms;
               img {
                    max-width: 190px;
                    padding: 10px;
                }
            }
        }
    }
    .vertodas{
        @include btn-hover-animated($primario2, white, 30px, 15px, 15px);
        display: block;
        width: 320px;
        margin: auto;
        font-size: $font-md;
    }
}
