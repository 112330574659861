#search {
    h2 {
        @extend .djv-titulos-left-linea-discontinua;
    }
    .page-not-found {
        > p {
            @extend .djv-titulos-left-linea-discontinua;
        }
    }
}
.headerGrid{
    #search_widget {
        display: block;
        margin: 5px auto 5px 0;
        position: relative;
        width: calc(100% - 36px);
        @media(max-width: $screen-md){
            width: 90%;
            margin-left: auto;
            margin-right: auto;
            max-width: 100vw;
        }
        .ui-helper-hidden-accessible {
            border: 0;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        input {
            display: block;
            position: relative;
            width: 100%;
            height: 30px;
            margin: 0 auto;
            padding:20px;
            background-color: $primario2;
            border: 0;
            border-radius: 0px;
            color: $primario4;
            font-family: fuente($font-first,$font-regular);
            font-size: $font-bmd;

            &::placeholder {
                font-size: $font-md;
                color: $primario5;
                font-family: fuente($font-first,$font-light);
                text-align: left;
            }
        }
        button{
            position: absolute;
            top: 0px;
            right: 0;
            border: none;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            width: 37px;
            height: 37px;
            cursor: pointer;
            background-color: transparent;
            &::after {
                display: inline-block;
                font-family: "comunes";
                font-style: normal;
                font-weight: normal;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                content:'\0045';
                font-size: 22px;
                right: 14px;
                top: 9px;
                position: absolute;
                color: $primario5;
            }
        }
    }
}

.ui-widget{
    font-size: $font-sm;
}
