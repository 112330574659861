#product{
    .product-first-section{
        @include djv-container();
        display:grid;
        grid-gap: 0px 20px;
        grid-template-columns: repeat(2, calc(50% - 10px));
        margin: 30px auto;
        @media(max-width: $screen-md){
            display: block;
            padding-left: 10px;
            padding-right: 10px;
        }
        .product-first-section-left{
            .djv-product-images{
                grid-area: images;
                padding: 10px;
                @media(max-width: $screen-md){
                    padding: 0;
                }
                .product-cover{
                    img{
                        min-width: 360px;
                        @media(max-width: $screen-lg){
                            width: 250px;
                            min-width: 250px;
                            margin-left: auto;
                            margin-right: auto;
                        }
                    }
                }
                .product-thumbnails{
                    display: block;
                    width: 100%;
                    margin: auto;
                }
            }
        }
        .product-first-section-right{
            #product-information-name{
                grid-area: name;
                h1{
                    font-size: $font-lg;
                    @include fuente($font-second, $font-semibold);
                    @media(max-width: $screen-md){
                        font-size: $font-lg;
                    }
                }
            }
            #product-reference{
                font-size: $font-xs;
                color: $gris4;
                font-weight: normal;
                strong{
                    font-weight: normal;
                }
            }
            #product-description-short{
                grid-area: descriptionshort;
                @include fuente($font-first, $font-regular);
                font-size: $font-md;
                p{
                    margin-bottom: 16px;
                }
                .table-top{
                    width: auto;
                    margin: 10px 0;
                    font-size: $font-smd;
                    header{
                        width: 100%;
                        background-color: $primario2;
                        color: $primario3;
                        padding: 3px 5px;
                        margin-bottom: 5px;
                        h4{
                            font-size: $font-md;
                            margin-bottom: 0;
                        }
                    }
                    table{
                        border: 1px solid $gris2;
                        width: 100%;
                        border-collapse: collapse;
                        td{
                            border: 1px solid $gris2;
                            padding: 3px 5px;
                            width: 50%;
                        }
                    }
                }
                .table-left{
                    width: auto;
                    margin: 10px 0;
                    font-size: $font-smd;
                    table{
                        //border: 1px solid $gris4;
                        width: 100%;
                        border-collapse: collapse;
                        tr{
                            td:nth-of-type(1){
                                font-weight: 500;
                                background-color: $primario2;
                                color: $primario3;
                                border: 1px solid white;
                                width: calc(49%);
                            }
                            td:nth-of-type(2){
                                width: calc(2%);
                                border: 0px solid white;
                            }
                        }
                        td{
                            width: calc(49%);
                            border: 1px solid $gris2;
                            padding: 3px 5px;
                            }
                        }
                }
                #sigue_leyendo{
                    @include fuente($font-first, $font-semibold);
                    font-size: $font-sm;
                    color: $primario2;
                    cursor: pointer;
                    margin-top: 10px 0;
                    display: block;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
            .djv-product-detail-variants{
                grid-area: variants;
            }
            .product-buy-actions{
                grid-area: actions;
                clear: both;
                margin-bottom: 16px;
                @media(max-width: $screen-lg){
                    margin-left: auto;
                    margin-right: auto;
                }
                #add-to-cart-or-refresh{
                    display: grid;
                    grid-template-areas:
                        "quantity price availabilityinfo"
                        "variants variants variants"
                        "addtocart addtocart addtocart";
                    grid-template-columns: 175px 200px 200px ;
                    text-align: center;
                    padding: 10px 0;
                    border-top: 1px solid $gris3;
                    margin-top: 10px;
                    @media(max-width: $screen-md){
                        display: block;
                    }

                    .djv-variants-message{
                        grid-area: variantsmsg;
                    }
                    .etiqueta-oferta{
                        width: 90px;
                        padding: 5px 10px;
                        margin-left: auto;
                        margin-right: auto;
                        margin-bottom: 0px;
                        background-color: $primario6;
                        font-size: $font-sm;
                        color:white;
                        text-align: center;
                    }
                    .product-variants{
                        grid-area:variants;
                    }
                    .product-quantity{
                        grid-area: quantity;
                        align-self: center;
                        display: grid;
                        justify-content: center;
                    }
                    .product-add-to-cart{
                        grid-area: addtocart;
                        padding-top: 16px;
                        .add-to-cart{
                            @include btn-hover-animated($primario3, white, 0px, 10px, 30px);
                            display: block;
                            width: 50%;
                            margin: 0 auto;
                            font-size: $font-md;
                        }
                    }
                    .product-prices{
                        grid-area: price;
                        align-self: end;
                        padding: 0;
                    }
                    .djv-product-availability-info{
                        grid-area: availabilityinfo;
                        align-self: end;
                        padding: 0;
                    }
                    // La siguiente clase solo se utiliza en el proyecto Seleccionatolon
                    &.atributtes-with-quantity{
                        grid-template-areas:
                        "textoUnidades textoUnidades textoUnidades"
                        "variants price availabilityinfo"
                        "variants addtocart addtocart";
                        grid-template-columns: 250px 172px 146px;
                        .product-quantity{
                            display: none;
                        }
                        .texto-unidades{
                            grid-area: textoUnidades;
                            text-align: left;
                            font-size: $font-smd;
                            color: $gris5;
                            padding-bottom: 16px;
                        }
                        .product-variants{
                            .product-variants-select{
                                padding-right: 5px;
                            }
                        }
                        .product-add-to-cart{
                            padding-top: 0;
                            .add-to-cart{
                                width: 94%;
                                margin-left: 20px;
                                margin-top: 10px;
                            }
                        }
                    }
                }
            }

            #mostrarMas, #mostrarMenos{
                @include fuente($font-first, $font-semibold);
                font-size: $font-md;
                color: $primario2;
                padding: 16px 0;
                margin-bottom: 10px;
                cursor: pointer;
                &::before{
                    margin-right: 6px;
                    font-size: $font-sm;
                }
            }
            .djv-advertencia-descatalogado{
                text-align: center;
                font-size:$font-blg;
                color:$complementario2;
            }

        }
    }
    .product-second-section{
        @include djv-container();
        display: grid;
        grid-template-areas:
        "accesories accesories"
        "extended extended"
        "opiniones opiniones";
        grid-template-columns: calc(65% - 5px) calc(35% - 5px);
        grid-gap: 10px;
        @media(max-width: $screen-md) {
            display: block;
            width: auto;
            padding: 5px 10px;
        }
        .product-information-extended{
            grid-area: extended;
        }
        .djv-product-opiniones{
            grid-area: opiniones;
        }
        .product-accessories{
            grid-area:accesories;
        }
    }
    .djv-product-opiniones{
        @include djv-container();
        .djv-product-opinion{
            color: $gris9;
            .coment-header{
                .comment_image{
                    display: none;
                }
                .comment_title{
                    display: none;
                }
                .comment_author{
                    font-size: $font-xxs;
                    margin-bottom: 3px;
                }
            }
        }
    }
    .not-for-sale{
    }
}
