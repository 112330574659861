#product{
	.djv-product-detail-suport{
		margin-top: 20px;
		>div{
			display: inline-block;
			width: 48%;
			margin-left: 0.5%;
			margin-right: 0.5%;
			text-align: center;
			padding: 0.5%;
			position: relative;
			&:first-of-type{
				&::after{
					border-left: 1px solid $gris1;
					content: " ";
					display: inline-block;
					height: 150px;
					position: absolute;
					top: 0;
					right: -14px;
				}
			}
			i{
				font-size: $font-xl;
			}
			h4{
				font-size: $font-md;
				@include fuente($font-first, $font-medium);
			}
			p{
				font-size: $font-sm;
			}
		}
	}
}
