body:not(.recogido){
    @media (max-width: $screen-md) {
        #contact-icon-responsive {
            display: inline-block;
        }
        .header-nav-top {
            float: none;
            width: 100%;
            padding: 10px;
            position: fixed;
            top: 0;
            left: 0;
            background-color: rgba(white,0.95);
            height: calc(100vh);
            overflow: scroll;
            display: none;
            box-shadow: 1px 7px 5px 2px rgba(0, 0, 0, 0.3);
            z-index: 999;
            #djv-close-nav-responsive{
                color: $primario3;
                display: block;
                margin-bottom: 20px;
                cursor: pointer;
                position: relative;
                padding-left: 30px;
                padding-top: 3px;
                font-size: $font-smd;
                border-bottom: 1px solid $primario3;
                padding-bottom: 10px;
                &::before{
                    font-size: $font-lg;
                    color: $primario3;
                    position: absolute;
                    top:0px;
                    left: 0;
                }
            }
            #main-menu{
                .ul-menu {
                    display: block;
                    position: relative;
                    padding-left: 15px;
                    li{
                        list-style: none;
                        margin-bottom: 16px;
                    }
                }
            }
        }
    }
}
