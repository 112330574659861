.product-miniature{
    position: relative;
    display: grid;
    height: 346px;
    grid-template-columns:auto;
    grid-template-areas:
    "imgBlock"
    "dataBlock";
    grid-template-rows: 213px 131px;
    margin-bottom: 30px;
    padding: 0 10px 0 0;
    @media(max-width: $screen-md){
        border-bottom: none;
        display: block;
        height: auto;
    }
    @media(max-width: $screen-sm){
        padding: 5px;
        border-bottom: none;
    }

    a{
        display: block;
    }
    .product-miniature-img-block{
        grid-area: imgBlock;
        img{
            display: block;
            position: relative;
            width: 100%;
            max-width: 250px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .product-miniature-data-block{
        grid-area: dataBlock;
        display: grid;
        grid-template-areas:
        "nameProd"
        "priceProd"
        "actionsProd";
        grid-template-columns: auto;
        grid-gap: 0px;
        align-items: start;
        grid-template-rows: max-content auto 40px;
        @media(max-width: $screen-md){
            display: block;
        }
        .name-product{
            grid-area: nameProd;
            display: block;
            margin-bottom: 0px;
            padding: 8px 0;
            @include fuente($font-second, $font-medium);
            font-size: $font-bmd;
            text-align: center;
            @media(max-width: $screen-md){
                margin-bottom: 10px;
                white-space: normal;
            }
            a{
                color: $primario3;;
                @media(max-width: $screen-md){
                    white-space: normal;
                }
            }
        }
        .product-description-short{
            display: none;
        }
        .product-prices{
            grid-area:priceProd;
            position: relative;
            text-align: center;
            padding-top: 0px;
            .aeuc_from_label{
                font-weight: 300;
                font-size: $font-sm;
            }
            .with-taxes{
                font-size: $font-xl;
                @include fuente($font-first, $font-semibold);
                color: black;
                @media(max-width: $screen-md){
                    font-size: $font-blg;
                }
                .decimales{
                    font-size: $font-bmd;
                    @media(max-width: $screen-md){
                        font-size: $font-smd;
                    }
                }
                small{
                    display: none;
                    font-size: $font-xxs;
                    @include fuente($font-first, $font-regular);
                    margin-top: 0px;
                    color:$gris7;
                    line-height: 26px;
                    @media(max-width: $screen-md){
                        font-size: $font-xxxs;
                    }
                }
                &.has-discount{
                    color: $primario3;
                }
            }
            .regular-price{
                display: block;
                @include fuente($font-first, $font-light);
                font-size: $font-smd;
                text-decoration: line-through;
                text-align: center;
                color:$gris5;
            }
            .product-discount{
                display: inline-block;
                width: 49%;
                margin-bottom: 0;
                text-align: right;
                //min-height: 50px;
                .discount-percentage,.discount-amount{
                    display: inline-block;
                    min-height: auto;
                    padding: 0 5px;
                    background-color:$primario10;
                    border: 1px solid darken($gris1,15%);
                    @include fuente($font-first, $font-medium);
                    font-size: 22px;
                    color:white;
                }

                .discount-percentage{display: none;}
                .discount-amount{display: none;}
            }

            .discount-percentage, .discount-amount{
                display: none;
            }
        }
        .product-list-actions{
            grid-area: actionsProd;
            width: 100%;
            align-self: end;
            .add-to-cart, .djv-ver-mas, .djv-personalizar{
                @include btn-hover-animated($primario3, $primario1, 0px, 2px, 14px);
                display: block;
                max-width: 195px;
                margin: 0px auto;
                font-size: $font-slg;
                text-transform: lowercase;
                white-space: nowrap;
                text-decoration: none;
                padding-bottom: 5px;
                @media(max-width: $screen-md){
                }
            }
        }

    }
}
